import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ResourceDataGrid from '../../common/ResourceDataGrid';
import ResourceListPagedDecorator from '../../common/ResourceListPagedDecorator';
import Filter from '../../common/filter/Filter';
import LabelProject from '../../common/LabelProject';
import LabelCertificate from '../../common/LabelCertificate';
import TemplateNewModal from './TemplateNewModal';
import TemplateListItemActions from './TemplateListItemActions';
import TemplateIsValid from './TemplateIsValid';
import { uiSetTemplateListValidation } from '../../../actions/ui';
import FormInputCheckbox from '../../common/FormInputCheckbox';
import { Form } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';

let fields = {
  name: {
    title: 'TEMPLATE_NAME',
    input: 'text',
    readOnly: true,
  },
  certName: {
    title: 'CERT_NAME',
    input: 'text',
    convertToValue: function(id, item) {
      return <LabelCertificate id={item.certificateId} />;
    },
  },
  projectId: {
    title: 'PROJECT',
    input: 'text',
    readOnly: true,
    convertToValue: function(id, item) {
      return <LabelProject id={item.projectId} />;
    },
  },

  actions: {
    convertToValue: function(id, item) {
      return <TemplateListItemActions item={item} />;
    },
  },
};

const filterProps = [
  {
    name: 'project',
    type: 'inUuid',
    columnName: 'projectId',
  },
  {
    name: 'name',
    type: 'like',
    columnName: 'name',
  },
];

class TemplateList extends Component {
  static propTypes = {
    viewer: PropTypes.object.isRequired,
  };

  render() {
    const {
      templateListValidation,
      uiSetTemplateListValidation,
      intl,
    } = this.props;

    const canCreate =
      this.props.viewer.accessRights.TEMPLATE_CREATE ||
      this.props.viewer.isAdmin;
    const filter = <Filter project={true} name={true} />;

    const componentProps = {
      filterProps: filterProps,
      filter: filter,
      fields: fields,
      cols: ['name', 'projectId', 'certName', 'actions'],
      newButton: canCreate ? <TemplateNewModal /> : null,
    };

    if (this.props.viewer.isAdmin && templateListValidation) {
      componentProps.cols.splice(0, 0, 'validation');
      fields.validation = {
        title: 'VALID',
        convertToValue: function(id, item) {
          return <TemplateIsValid id={item.id} />;
        },
      };
    }

    return (
      <div>
        {this.props.viewer.isAdmin && (
          <Form style={{ textAlign: 'right', float: 'right' }}>
            <FormInputCheckbox
              toggle
              label={intl.formatMessage({
                id: 'TEMPLATE_LIST_ENABLE_VALIDATION',
              })}
              defaultValue={templateListValidation}
              onChange={(e, { value }) => {
                uiSetTemplateListValidation(value);
              }}
            />
          </Form>
        )}

        <ResourceListPagedDecorator
          resource={'template'}
          component={ResourceDataGrid}
          componentProps={componentProps}
        />
      </div>
    );
  }
}

export default connect(
  (state, props) => ({
    viewer: state.viewer.data,
    templateListValidation: state.ui.templateListValidation,
  }),
  { uiSetTemplateListValidation },
)(injectIntl(TemplateList));
