import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import { injectIntl } from 'react-intl';
import { Form, Input } from 'semantic-ui-react';
import { getInvertColor } from '../../services/color';
import './ColorInput.css';

class ColorInput extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    error: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
  };

  componentWillMount() {
    this.setState({ displayColorPickerBackgroundColor: false });
  }

  handleRef = c => {
    this.inputRef = c;
  };

  componentDidUpdate() {
    this.setBackgroundColor();
  }

  componentDidMount() {
    this.setBackgroundColor();
  }

  getValue = () => {
    return this.props.value;
  };

  setBackgroundColor = () => {
    const color = this.getValue();
    if (color && /^rgb\(\d+,\d+,\d+\)$/.test(color)) {
      this.inputRef.inputRef.current.style.backgroundColor = color;
      this.inputRef.inputRef.current.style.color = getInvertColor(color);
    }
  };

  onChangeInput = (event, d) => {
    this.props.onChange(event, d);
  };

  handleChange = color => {
    const { r, g, b } = color.rgb;
    this.props.onChange(null, {
      name: this.props.name,
      value: `rgb(${r},${g},${b})`,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      displayColorPickerBackgroundColor: !this.state
        .displayColorPickerBackgroundColor,
    });
  };

  handleClick = e => {
    e.preventDefault();
    this.setState({
      displayColorPickerBackgroundColor: !this.state
        .displayColorPickerBackgroundColor,
    });
  };

  handleKeyDown = e => {
    if (e.keyCode === 27) {
      this.setState({ displayColorPickerBackgroundColor: false });
    }
  };

  render() {
    const { value, name, label, error } = this.props;
    return (
      <Form.Field onKeyDown={this.handleKeyDown} error={error}>
        <label>{label}</label>
        <Input
          className={error ? 'error' : ''}
          action={{ icon: 'theme', onClick: this.handleClick }}
          actionPosition="left"
          ref={this.handleRef}
          style={{
            width: '100%',
          }}
          type="text"
          value={value}
          name={name}
          onChange={this.onChangeInput}
        />
        {this.state.displayColorPickerBackgroundColor? (
          <div
            onMouseLeave={this.handleMouseLeave}
            style={{ position: 'absolute', zIndex: 1000 }}
          >
            <SketchPicker
              disableAlpha={true}
              color={value || ''}
              onChange={this.handleChange}
            />
          </div>
        ) : null}
      </Form.Field>
    );
  }
}

export default injectIntl(ColorInput);
