/* eslint-disable */
'use strict';
var equal = require('ajv/lib/compile/equal');
var validate = (function() {
  var pattern0 = new RegExp('string');
  var pattern1 = new RegExp(
    '#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^}]{4,}\\}',
  );
  var pattern2 = new RegExp(
    '^((?!(#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^\\}]{4,}\\}))[\\s\\S])*$',
  );
  var pattern3 = new RegExp('number');
  var pattern4 = new RegExp('^#\\{.+\\}$');
  var pattern5 = new RegExp('[a-z]{2}');
  var pattern6 = new RegExp(
    '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
  );
  var pattern7 = new RegExp(
    '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
  );
  var pattern8 = new RegExp('boolean');
  var refVal = [];
  var refVal1 = {
    type: 'string',
    enum: ['array'],
  };
  refVal[1] = refVal1;
  var refVal2 = (function() {
    var pattern0 = new RegExp('string');
    var pattern1 = new RegExp(
      '#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^}]{4,}\\}',
    );
    var pattern2 = new RegExp(
      '^((?!(#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^\\}]{4,}\\}))[\\s\\S])*$',
    );
    var pattern3 = new RegExp('number');
    var pattern4 = new RegExp('^#\\{.+\\}$');
    var pattern5 = new RegExp('[a-z]{2}');
    var pattern6 = new RegExp(
      '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
    );
    var pattern7 = new RegExp(
      '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
    );
    var pattern8 = new RegExp('boolean');
    return function validate(
      data,
      dataPath,
      parentData,
      parentDataProperty,
      rootData,
    ) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if (data && typeof data === 'object' && !Array.isArray(data)) {
        var errs__0 = errors;
        var valid1 = true;
        var data1 = data.properties;
        if (data1 === undefined) {
          valid1 = true;
        } else {
          var errs_1 = errors;
          if (data1 && typeof data1 === 'object' && !Array.isArray(data1)) {
            if (true) {
              var errs__1 = errors;
              var valid2 = true;
              if (data1.proximityUUID === undefined) {
                valid2 = false;
                validate.errors = [
                  {
                    keyword: 'required',
                    dataPath: (dataPath || '') + '.properties',
                    schemaPath: '#/properties/properties/required',
                    params: {
                      missingProperty: 'proximityUUID',
                    },
                    message: "should have required property 'proximityUUID'",
                  },
                ];
                return false;
              } else {
                var errs_2 = errors;
                if (
                  !refVal3(
                    data1.proximityUUID,
                    (dataPath || '') + '.properties.proximityUUID',
                    data1,
                    'proximityUUID',
                    rootData,
                  )
                ) {
                  if (vErrors === null) vErrors = refVal3.errors;
                  else vErrors = vErrors.concat(refVal3.errors);
                  errors = vErrors.length;
                } else {
                }
                if (errors === errs_2) {
                }
                var valid2 = errors === errs_2;
              }
              if (valid2) {
                var data2 = data1.major;
                if (data2 === undefined) {
                  valid2 = true;
                } else {
                  var errs_2 = errors;
                  var errs_3 = errors;
                  if (
                    !data2 ||
                    typeof data2 !== 'object' ||
                    Array.isArray(data2)
                  ) {
                    validate.errors = [
                      {
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.properties.major',
                        schemaPath: '#/definitions/integerProperty/type',
                        params: {
                          type: 'object',
                        },
                        message: 'should be object',
                      },
                    ];
                    return false;
                  }
                  var errs__3 = errors,
                    prevValid3 = false,
                    valid3 = false,
                    passingSchemas3 = null;
                  var errs_4 = errors;
                  if (
                    data2 &&
                    typeof data2 === 'object' &&
                    !Array.isArray(data2)
                  ) {
                    if (true) {
                      var errs__4 = errors;
                      var valid5 = true;
                      var data3 = data2.type;
                      if (data3 === undefined) {
                        valid5 = false;
                        var err = {
                          keyword: 'required',
                          dataPath: (dataPath || '') + '.properties.major',
                          schemaPath:
                            '#/definitions/integerProperty/oneOf/0/required',
                          params: {
                            missingProperty: 'type',
                          },
                          message: "should have required property 'type'",
                        };
                        if (vErrors === null) vErrors = [err];
                        else vErrors.push(err);
                        errors++;
                      } else {
                        var errs_5 = errors;
                        if (typeof data3 === 'string') {
                          if (!pattern3.test(data3)) {
                            var err = {
                              keyword: 'pattern',
                              dataPath:
                                (dataPath || '') + '.properties.major.type',
                              schemaPath:
                                '#/definitions/integerProperty/oneOf/0/properties/type/pattern',
                              params: {
                                pattern: 'number',
                              },
                              message: 'should match pattern "number"',
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          } else {
                          }
                        } else {
                          var err = {
                            keyword: 'type',
                            dataPath:
                              (dataPath || '') + '.properties.major.type',
                            schemaPath:
                              '#/definitions/integerProperty/oneOf/0/properties/type/type',
                            params: {
                              type: 'string',
                            },
                            message: 'should be string',
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                        }
                        if (errors === errs_5) {
                        }
                        var valid5 = errors === errs_5;
                      }
                      if (valid5) {
                        var data3 = data2.value;
                        if (data3 === undefined) {
                          valid5 = false;
                          var err = {
                            keyword: 'required',
                            dataPath: (dataPath || '') + '.properties.major',
                            schemaPath:
                              '#/definitions/integerProperty/oneOf/0/required',
                            params: {
                              missingProperty: 'value',
                            },
                            message: "should have required property 'value'",
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                        } else {
                          var errs_5 = errors;
                          if (
                            typeof data3 !== 'number' ||
                            data3 % 1 ||
                            data3 !== data3
                          ) {
                            var err = {
                              keyword: 'type',
                              dataPath:
                                (dataPath || '') + '.properties.major.value',
                              schemaPath:
                                '#/definitions/integerProperty/oneOf/0/properties/value/type',
                              params: {
                                type: 'integer',
                              },
                              message: 'should be integer',
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          }
                          var valid5 = errors === errs_5;
                        }
                        if (valid5) {
                        }
                      }
                      if (errs__4 == errors) {
                      }
                    }
                  }
                  if (errors === errs_4) {
                  }
                  var valid4 = errors === errs_4;
                  if (valid4) {
                    valid3 = prevValid3 = true;
                    passingSchemas3 = 0;
                  }
                  var errs_4 = errors;
                  if (
                    data2 &&
                    typeof data2 === 'object' &&
                    !Array.isArray(data2)
                  ) {
                    if (true) {
                      var errs__4 = errors;
                      var valid5 = true;
                      var data3 = data2.type;
                      if (data3 === undefined) {
                        valid5 = false;
                        var err = {
                          keyword: 'required',
                          dataPath: (dataPath || '') + '.properties.major',
                          schemaPath:
                            '#/definitions/integerProperty/oneOf/1/required',
                          params: {
                            missingProperty: 'type',
                          },
                          message: "should have required property 'type'",
                        };
                        if (vErrors === null) vErrors = [err];
                        else vErrors.push(err);
                        errors++;
                      } else {
                        var errs_5 = errors;
                        if (typeof data3 === 'string') {
                          if (!pattern3.test(data3)) {
                            var err = {
                              keyword: 'pattern',
                              dataPath:
                                (dataPath || '') + '.properties.major.type',
                              schemaPath:
                                '#/definitions/integerProperty/oneOf/1/properties/type/pattern',
                              params: {
                                pattern: 'number',
                              },
                              message: 'should match pattern "number"',
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          } else {
                          }
                        } else {
                          var err = {
                            keyword: 'type',
                            dataPath:
                              (dataPath || '') + '.properties.major.type',
                            schemaPath:
                              '#/definitions/integerProperty/oneOf/1/properties/type/type',
                            params: {
                              type: 'string',
                            },
                            message: 'should be string',
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                        }
                        if (errors === errs_5) {
                        }
                        var valid5 = errors === errs_5;
                      }
                      if (valid5) {
                        var data3 = data2.value;
                        if (data3 === undefined) {
                          valid5 = false;
                          var err = {
                            keyword: 'required',
                            dataPath: (dataPath || '') + '.properties.major',
                            schemaPath:
                              '#/definitions/integerProperty/oneOf/1/required',
                            params: {
                              missingProperty: 'value',
                            },
                            message: "should have required property 'value'",
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                        } else {
                          var errs_5 = errors;
                          if (typeof data3 === 'string') {
                            if (!pattern4.test(data3)) {
                              var err = {
                                keyword: 'pattern',
                                dataPath:
                                  (dataPath || '') + '.properties.major.value',
                                schemaPath:
                                  '#/definitions/integerProperty/oneOf/1/properties/value/pattern',
                                params: {
                                  pattern: '^#\\{.+\\}$',
                                },
                                message: 'should match pattern "^#\\{.+\\}$"',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            } else {
                            }
                          } else {
                            var err = {
                              keyword: 'type',
                              dataPath:
                                (dataPath || '') + '.properties.major.value',
                              schemaPath:
                                '#/definitions/integerProperty/oneOf/1/properties/value/type',
                              params: {
                                type: 'string',
                              },
                              message: 'should be string',
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          }
                          if (errors === errs_5) {
                          }
                          var valid5 = errors === errs_5;
                        }
                        if (valid5) {
                          var data3 = data2.fallback;
                          if (data3 === undefined) {
                            valid5 = false;
                            var err = {
                              keyword: 'required',
                              dataPath: (dataPath || '') + '.properties.major',
                              schemaPath:
                                '#/definitions/integerProperty/oneOf/1/required',
                              params: {
                                missingProperty: 'fallback',
                              },
                              message:
                                "should have required property 'fallback'",
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          } else {
                            var errs_5 = errors;
                            if (
                              typeof data3 !== 'number' ||
                              data3 % 1 ||
                              data3 !== data3
                            ) {
                              var err = {
                                keyword: 'type',
                                dataPath:
                                  (dataPath || '') +
                                  '.properties.major.fallback',
                                schemaPath:
                                  '#/definitions/integerProperty/oneOf/1/properties/fallback/type',
                                params: {
                                  type: 'integer',
                                },
                                message: 'should be integer',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            }
                            var valid5 = errors === errs_5;
                          }
                          if (valid5) {
                          }
                        }
                      }
                      if (errs__4 == errors) {
                      }
                    }
                  }
                  if (errors === errs_4) {
                  }
                  var valid4 = errors === errs_4;
                  if (valid4 && prevValid3) {
                    valid3 = false;
                    passingSchemas3 = [passingSchemas3, 1];
                  } else {
                    if (valid4) {
                      valid3 = prevValid3 = true;
                      passingSchemas3 = 1;
                    }
                  }
                  if (!valid3) {
                    var err = {
                      keyword: 'oneOf',
                      dataPath: (dataPath || '') + '.properties.major',
                      schemaPath: '#/definitions/integerProperty/oneOf',
                      params: {
                        passingSchemas: passingSchemas3,
                      },
                      message: 'should match exactly one schema in oneOf',
                    };
                    if (vErrors === null) vErrors = [err];
                    else vErrors.push(err);
                    errors++;
                    validate.errors = vErrors;
                    return false;
                  } else {
                    errors = errs__3;
                    if (vErrors !== null) {
                      if (errs__3) vErrors.length = errs__3;
                      else vErrors = null;
                    }
                  }
                  if (errors === errs_3) {
                  }
                  var valid3 = errors === errs_3;
                  if (valid3) {
                  }
                  if (errors === errs_2) {
                  }
                  var valid2 = errors === errs_2;
                }
                if (valid2) {
                  var data2 = data1.minor;
                  if (data2 === undefined) {
                    valid2 = true;
                  } else {
                    var errs_2 = errors;
                    var errs_3 = errors;
                    if (
                      !data2 ||
                      typeof data2 !== 'object' ||
                      Array.isArray(data2)
                    ) {
                      validate.errors = [
                        {
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.properties.minor',
                          schemaPath: '#/definitions/integerProperty/type',
                          params: {
                            type: 'object',
                          },
                          message: 'should be object',
                        },
                      ];
                      return false;
                    }
                    var errs__3 = errors,
                      prevValid3 = false,
                      valid3 = false,
                      passingSchemas3 = null;
                    var errs_4 = errors;
                    if (
                      data2 &&
                      typeof data2 === 'object' &&
                      !Array.isArray(data2)
                    ) {
                      if (true) {
                        var errs__4 = errors;
                        var valid5 = true;
                        var data3 = data2.type;
                        if (data3 === undefined) {
                          valid5 = false;
                          var err = {
                            keyword: 'required',
                            dataPath: (dataPath || '') + '.properties.minor',
                            schemaPath:
                              '#/definitions/integerProperty/oneOf/0/required',
                            params: {
                              missingProperty: 'type',
                            },
                            message: "should have required property 'type'",
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                        } else {
                          var errs_5 = errors;
                          if (typeof data3 === 'string') {
                            if (!pattern3.test(data3)) {
                              var err = {
                                keyword: 'pattern',
                                dataPath:
                                  (dataPath || '') + '.properties.minor.type',
                                schemaPath:
                                  '#/definitions/integerProperty/oneOf/0/properties/type/pattern',
                                params: {
                                  pattern: 'number',
                                },
                                message: 'should match pattern "number"',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            } else {
                            }
                          } else {
                            var err = {
                              keyword: 'type',
                              dataPath:
                                (dataPath || '') + '.properties.minor.type',
                              schemaPath:
                                '#/definitions/integerProperty/oneOf/0/properties/type/type',
                              params: {
                                type: 'string',
                              },
                              message: 'should be string',
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          }
                          if (errors === errs_5) {
                          }
                          var valid5 = errors === errs_5;
                        }
                        if (valid5) {
                          var data3 = data2.value;
                          if (data3 === undefined) {
                            valid5 = false;
                            var err = {
                              keyword: 'required',
                              dataPath: (dataPath || '') + '.properties.minor',
                              schemaPath:
                                '#/definitions/integerProperty/oneOf/0/required',
                              params: {
                                missingProperty: 'value',
                              },
                              message: "should have required property 'value'",
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          } else {
                            var errs_5 = errors;
                            if (
                              typeof data3 !== 'number' ||
                              data3 % 1 ||
                              data3 !== data3
                            ) {
                              var err = {
                                keyword: 'type',
                                dataPath:
                                  (dataPath || '') + '.properties.minor.value',
                                schemaPath:
                                  '#/definitions/integerProperty/oneOf/0/properties/value/type',
                                params: {
                                  type: 'integer',
                                },
                                message: 'should be integer',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            }
                            var valid5 = errors === errs_5;
                          }
                          if (valid5) {
                          }
                        }
                        if (errs__4 == errors) {
                        }
                      }
                    }
                    if (errors === errs_4) {
                    }
                    var valid4 = errors === errs_4;
                    if (valid4) {
                      valid3 = prevValid3 = true;
                      passingSchemas3 = 0;
                    }
                    var errs_4 = errors;
                    if (
                      data2 &&
                      typeof data2 === 'object' &&
                      !Array.isArray(data2)
                    ) {
                      if (true) {
                        var errs__4 = errors;
                        var valid5 = true;
                        var data3 = data2.type;
                        if (data3 === undefined) {
                          valid5 = false;
                          var err = {
                            keyword: 'required',
                            dataPath: (dataPath || '') + '.properties.minor',
                            schemaPath:
                              '#/definitions/integerProperty/oneOf/1/required',
                            params: {
                              missingProperty: 'type',
                            },
                            message: "should have required property 'type'",
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                        } else {
                          var errs_5 = errors;
                          if (typeof data3 === 'string') {
                            if (!pattern3.test(data3)) {
                              var err = {
                                keyword: 'pattern',
                                dataPath:
                                  (dataPath || '') + '.properties.minor.type',
                                schemaPath:
                                  '#/definitions/integerProperty/oneOf/1/properties/type/pattern',
                                params: {
                                  pattern: 'number',
                                },
                                message: 'should match pattern "number"',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            } else {
                            }
                          } else {
                            var err = {
                              keyword: 'type',
                              dataPath:
                                (dataPath || '') + '.properties.minor.type',
                              schemaPath:
                                '#/definitions/integerProperty/oneOf/1/properties/type/type',
                              params: {
                                type: 'string',
                              },
                              message: 'should be string',
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          }
                          if (errors === errs_5) {
                          }
                          var valid5 = errors === errs_5;
                        }
                        if (valid5) {
                          var data3 = data2.value;
                          if (data3 === undefined) {
                            valid5 = false;
                            var err = {
                              keyword: 'required',
                              dataPath: (dataPath || '') + '.properties.minor',
                              schemaPath:
                                '#/definitions/integerProperty/oneOf/1/required',
                              params: {
                                missingProperty: 'value',
                              },
                              message: "should have required property 'value'",
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          } else {
                            var errs_5 = errors;
                            if (typeof data3 === 'string') {
                              if (!pattern4.test(data3)) {
                                var err = {
                                  keyword: 'pattern',
                                  dataPath:
                                    (dataPath || '') +
                                    '.properties.minor.value',
                                  schemaPath:
                                    '#/definitions/integerProperty/oneOf/1/properties/value/pattern',
                                  params: {
                                    pattern: '^#\\{.+\\}$',
                                  },
                                  message: 'should match pattern "^#\\{.+\\}$"',
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                              } else {
                              }
                            } else {
                              var err = {
                                keyword: 'type',
                                dataPath:
                                  (dataPath || '') + '.properties.minor.value',
                                schemaPath:
                                  '#/definitions/integerProperty/oneOf/1/properties/value/type',
                                params: {
                                  type: 'string',
                                },
                                message: 'should be string',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            }
                            if (errors === errs_5) {
                            }
                            var valid5 = errors === errs_5;
                          }
                          if (valid5) {
                            var data3 = data2.fallback;
                            if (data3 === undefined) {
                              valid5 = false;
                              var err = {
                                keyword: 'required',
                                dataPath:
                                  (dataPath || '') + '.properties.minor',
                                schemaPath:
                                  '#/definitions/integerProperty/oneOf/1/required',
                                params: {
                                  missingProperty: 'fallback',
                                },
                                message:
                                  "should have required property 'fallback'",
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            } else {
                              var errs_5 = errors;
                              if (
                                typeof data3 !== 'number' ||
                                data3 % 1 ||
                                data3 !== data3
                              ) {
                                var err = {
                                  keyword: 'type',
                                  dataPath:
                                    (dataPath || '') +
                                    '.properties.minor.fallback',
                                  schemaPath:
                                    '#/definitions/integerProperty/oneOf/1/properties/fallback/type',
                                  params: {
                                    type: 'integer',
                                  },
                                  message: 'should be integer',
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                              }
                              var valid5 = errors === errs_5;
                            }
                            if (valid5) {
                            }
                          }
                        }
                        if (errs__4 == errors) {
                        }
                      }
                    }
                    if (errors === errs_4) {
                    }
                    var valid4 = errors === errs_4;
                    if (valid4 && prevValid3) {
                      valid3 = false;
                      passingSchemas3 = [passingSchemas3, 1];
                    } else {
                      if (valid4) {
                        valid3 = prevValid3 = true;
                        passingSchemas3 = 1;
                      }
                    }
                    if (!valid3) {
                      var err = {
                        keyword: 'oneOf',
                        dataPath: (dataPath || '') + '.properties.minor',
                        schemaPath: '#/definitions/integerProperty/oneOf',
                        params: {
                          passingSchemas: passingSchemas3,
                        },
                        message: 'should match exactly one schema in oneOf',
                      };
                      if (vErrors === null) vErrors = [err];
                      else vErrors.push(err);
                      errors++;
                      validate.errors = vErrors;
                      return false;
                    } else {
                      errors = errs__3;
                      if (vErrors !== null) {
                        if (errs__3) vErrors.length = errs__3;
                        else vErrors = null;
                      }
                    }
                    if (errors === errs_3) {
                    }
                    var valid3 = errors === errs_3;
                    if (valid3) {
                    }
                    if (errors === errs_2) {
                    }
                    var valid2 = errors === errs_2;
                  }
                  if (valid2) {
                    if (data1.relevantText === undefined) {
                      valid2 = true;
                    } else {
                      var errs_2 = errors;
                      if (
                        !refVal[3](
                          data1.relevantText,
                          (dataPath || '') + '.properties.relevantText',
                          data1,
                          'relevantText',
                          rootData,
                        )
                      ) {
                        if (vErrors === null) vErrors = refVal[3].errors;
                        else vErrors = vErrors.concat(refVal[3].errors);
                        errors = vErrors.length;
                      } else {
                      }
                      if (errors === errs_2) {
                      }
                      var valid2 = errors === errs_2;
                    }
                    if (valid2) {
                    }
                  }
                }
              }
              if (errs__1 == errors) {
              }
            }
          } else {
            validate.errors = [
              {
                keyword: 'type',
                dataPath: (dataPath || '') + '.properties',
                schemaPath: '#/properties/properties/type',
                params: {
                  type: 'object',
                },
                message: 'should be object',
              },
            ];
            return false;
          }
          if (errors === errs_1) {
          }
          var valid1 = errors === errs_1;
        }
        if (valid1) {
        }
        if (errs__0 == errors) {
        }
      } else {
        validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/type',
            params: {
              type: 'object',
            },
            message: 'should be object',
          },
        ];
        return false;
      }
      if (errors === 0) {
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal2.schema = {
    type: 'object',
    properties: {
      properties: {
        type: 'object',
        properties: {
          proximityUUID: {
            $ref: '#/definitions/stringProperty',
          },
          major: {
            $ref: '#/definitions/integerProperty',
          },
          minor: {
            $ref: '#/definitions/integerProperty',
          },
          relevantText: {
            $ref: '#/definitions/stringProperty',
          },
        },
        required: ['proximityUUID'],
      },
    },
  };
  refVal2.errors = null;
  refVal[2] = refVal2;
  var refVal3 = (function() {
    var pattern0 = new RegExp('string');
    var pattern1 = new RegExp(
      '#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^}]{4,}\\}',
    );
    var pattern2 = new RegExp(
      '^((?!(#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^\\}]{4,}\\}))[\\s\\S])*$',
    );
    var pattern3 = new RegExp('number');
    var pattern4 = new RegExp('^#\\{.+\\}$');
    var pattern5 = new RegExp('[a-z]{2}');
    var pattern6 = new RegExp(
      '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
    );
    var pattern7 = new RegExp(
      '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
    );
    var pattern8 = new RegExp('boolean');
    return function validate(
      data,
      dataPath,
      parentData,
      parentDataProperty,
      rootData,
    ) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if (!data || typeof data !== 'object' || Array.isArray(data)) {
        validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/type',
            params: {
              type: 'object',
            },
            message: 'should be object',
          },
        ];
        return false;
      }
      var errs__0 = errors,
        prevValid0 = false,
        valid0 = false,
        passingSchemas0 = null;
      var errs_1 = errors;
      var errs_2 = errors;
      if (data && typeof data === 'object' && !Array.isArray(data)) {
        if (true) {
          var errs__2 = errors;
          var valid3 = true;
          var data1 = data.type;
          if (data1 === undefined) {
            valid3 = false;
            var err = {
              keyword: 'required',
              dataPath: (dataPath || '') + '',
              schemaPath: '#/definitions/stringWithPlaceholder/required',
              params: {
                missingProperty: 'type',
              },
              message: "should have required property 'type'",
            };
            if (vErrors === null) vErrors = [err];
            else vErrors.push(err);
            errors++;
          } else {
            var errs_3 = errors;
            if (typeof data1 === 'string') {
              if (!pattern0.test(data1)) {
                var err = {
                  keyword: 'pattern',
                  dataPath: (dataPath || '') + '.type',
                  schemaPath:
                    '#/definitions/stringWithPlaceholder/properties/type/pattern',
                  params: {
                    pattern: 'string',
                  },
                  message: 'should match pattern "string"',
                };
                if (vErrors === null) vErrors = [err];
                else vErrors.push(err);
                errors++;
              } else {
              }
            } else {
              var err = {
                keyword: 'type',
                dataPath: (dataPath || '') + '.type',
                schemaPath:
                  '#/definitions/stringWithPlaceholder/properties/type/type',
                params: {
                  type: 'string',
                },
                message: 'should be string',
              };
              if (vErrors === null) vErrors = [err];
              else vErrors.push(err);
              errors++;
            }
            if (errors === errs_3) {
            }
            var valid3 = errors === errs_3;
          }
          if (valid3) {
            var data1 = data.value;
            if (data1 === undefined) {
              valid3 = false;
              var err = {
                keyword: 'required',
                dataPath: (dataPath || '') + '',
                schemaPath: '#/definitions/stringWithPlaceholder/required',
                params: {
                  missingProperty: 'value',
                },
                message: "should have required property 'value'",
              };
              if (vErrors === null) vErrors = [err];
              else vErrors.push(err);
              errors++;
            } else {
              var errs_3 = errors;
              if (typeof data1 === 'string') {
                if (!pattern1.test(data1)) {
                  var err = {
                    keyword: 'pattern',
                    dataPath: (dataPath || '') + '.value',
                    schemaPath:
                      '#/definitions/stringWithPlaceholder/properties/value/pattern',
                    params: {
                      pattern:
                        '#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^}]{4,}\\}',
                    },
                    message:
                      'should match pattern "#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^}]{4,}\\}"',
                  };
                  if (vErrors === null) vErrors = [err];
                  else vErrors.push(err);
                  errors++;
                } else {
                }
              } else {
                var err = {
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.value',
                  schemaPath:
                    '#/definitions/stringWithPlaceholder/properties/value/type',
                  params: {
                    type: 'string',
                  },
                  message: 'should be string',
                };
                if (vErrors === null) vErrors = [err];
                else vErrors.push(err);
                errors++;
              }
              if (errors === errs_3) {
              }
              var valid3 = errors === errs_3;
            }
            if (valid3) {
              if (data.fallback === undefined) {
                valid3 = false;
                var err = {
                  keyword: 'required',
                  dataPath: (dataPath || '') + '',
                  schemaPath: '#/definitions/stringWithPlaceholder/required',
                  params: {
                    missingProperty: 'fallback',
                  },
                  message: "should have required property 'fallback'",
                };
                if (vErrors === null) vErrors = [err];
                else vErrors.push(err);
                errors++;
              } else {
                var errs_3 = errors;
                if (typeof data.fallback !== 'string') {
                  var err = {
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.fallback',
                    schemaPath:
                      '#/definitions/stringWithPlaceholder/properties/fallback/type',
                    params: {
                      type: 'string',
                    },
                    message: 'should be string',
                  };
                  if (vErrors === null) vErrors = [err];
                  else vErrors.push(err);
                  errors++;
                }
                var valid3 = errors === errs_3;
              }
              if (valid3) {
              }
            }
          }
          if (errs__2 == errors) {
          }
        }
      }
      if (errors === errs_2) {
      }
      var valid2 = errors === errs_2;
      if (valid2) {
      }
      if (errors === errs_1) {
      }
      var valid1 = errors === errs_1;
      if (valid1) {
        valid0 = prevValid0 = true;
        passingSchemas0 = 0;
      }
      var errs_1 = errors;
      var errs_2 = errors;
      if (data && typeof data === 'object' && !Array.isArray(data)) {
        if (true) {
          var errs__2 = errors;
          var valid3 = true;
          var data1 = data.type;
          if (data1 === undefined) {
            valid3 = false;
            var err = {
              keyword: 'required',
              dataPath: (dataPath || '') + '',
              schemaPath: '#/definitions/stringWithoutPlaceholder/required',
              params: {
                missingProperty: 'type',
              },
              message: "should have required property 'type'",
            };
            if (vErrors === null) vErrors = [err];
            else vErrors.push(err);
            errors++;
          } else {
            var errs_3 = errors;
            if (typeof data1 === 'string') {
              if (!pattern0.test(data1)) {
                var err = {
                  keyword: 'pattern',
                  dataPath: (dataPath || '') + '.type',
                  schemaPath:
                    '#/definitions/stringWithoutPlaceholder/properties/type/pattern',
                  params: {
                    pattern: 'string',
                  },
                  message: 'should match pattern "string"',
                };
                if (vErrors === null) vErrors = [err];
                else vErrors.push(err);
                errors++;
              } else {
              }
            } else {
              var err = {
                keyword: 'type',
                dataPath: (dataPath || '') + '.type',
                schemaPath:
                  '#/definitions/stringWithoutPlaceholder/properties/type/type',
                params: {
                  type: 'string',
                },
                message: 'should be string',
              };
              if (vErrors === null) vErrors = [err];
              else vErrors.push(err);
              errors++;
            }
            if (errors === errs_3) {
            }
            var valid3 = errors === errs_3;
          }
          if (valid3) {
            var data1 = data.value;
            if (data1 === undefined) {
              valid3 = false;
              var err = {
                keyword: 'required',
                dataPath: (dataPath || '') + '',
                schemaPath: '#/definitions/stringWithoutPlaceholder/required',
                params: {
                  missingProperty: 'value',
                },
                message: "should have required property 'value'",
              };
              if (vErrors === null) vErrors = [err];
              else vErrors.push(err);
              errors++;
            } else {
              var errs_3 = errors;
              if (typeof data1 === 'string') {
                if (!pattern2.test(data1)) {
                  var err = {
                    keyword: 'pattern',
                    dataPath: (dataPath || '') + '.value',
                    schemaPath:
                      '#/definitions/stringWithoutPlaceholder/properties/value/pattern',
                    params: {
                      pattern:
                        '^((?!(#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^\\}]{4,}\\}))[\\s\\S])*$',
                    },
                    message:
                      'should match pattern "^((?!(#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^\\}]{4,}\\}))[\\s\\S])*$"',
                  };
                  if (vErrors === null) vErrors = [err];
                  else vErrors.push(err);
                  errors++;
                } else {
                }
              } else {
                var err = {
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.value',
                  schemaPath:
                    '#/definitions/stringWithoutPlaceholder/properties/value/type',
                  params: {
                    type: 'string',
                  },
                  message: 'should be string',
                };
                if (vErrors === null) vErrors = [err];
                else vErrors.push(err);
                errors++;
              }
              if (errors === errs_3) {
              }
              var valid3 = errors === errs_3;
            }
            if (valid3) {
            }
          }
          if (errs__2 == errors) {
          }
        }
      }
      if (errors === errs_2) {
      }
      var valid2 = errors === errs_2;
      if (valid2) {
      }
      if (errors === errs_1) {
      }
      var valid1 = errors === errs_1;
      if (valid1 && prevValid0) {
        valid0 = false;
        passingSchemas0 = [passingSchemas0, 1];
      } else {
        if (valid1) {
          valid0 = prevValid0 = true;
          passingSchemas0 = 1;
        }
      }
      if (!valid0) {
        var err = {
          keyword: 'oneOf',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/oneOf',
          params: {
            passingSchemas: passingSchemas0,
          },
          message: 'should match exactly one schema in oneOf',
        };
        if (vErrors === null) vErrors = [err];
        else vErrors.push(err);
        errors++;
        validate.errors = vErrors;
        return false;
      } else {
        errors = errs__0;
        if (vErrors !== null) {
          if (errs__0) vErrors.length = errs__0;
          else vErrors = null;
        }
      }
      if (errors === 0) {
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal3.schema = {
    type: 'object',
    oneOf: [
      {
        $ref: '#/definitions/stringWithPlaceholder',
      },
      {
        $ref: '#/definitions/stringWithoutPlaceholder',
      },
    ],
  };
  refVal3.errors = null;
  refVal[3] = refVal3;
  var refVal4 = {
    properties: {
      type: {
        type: 'string',
        pattern: 'string',
      },
      value: {
        type: 'string',
        pattern:
          '#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^}]{4,}\\}',
      },
      fallback: {
        type: 'string',
      },
    },
    required: ['type', 'value', 'fallback'],
  };
  refVal[4] = refVal4;
  var refVal5 = {
    properties: {
      type: {
        type: 'string',
        pattern: 'string',
      },
      value: {
        type: 'string',
        pattern:
          '^((?!(#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^\\}]{4,}\\}))[\\s\\S])*$',
      },
    },
    required: ['type', 'value'],
  };
  refVal[5] = refVal5;
  var refVal6 = {
    type: 'object',
    oneOf: [
      {
        properties: {
          type: {
            type: 'string',
            pattern: 'number',
          },
          value: {
            type: 'integer',
          },
        },
        required: ['type', 'value'],
      },
      {
        properties: {
          type: {
            type: 'string',
            pattern: 'number',
          },
          value: {
            type: 'string',
            pattern: '^#\\{.+\\}$',
          },
          fallback: {
            type: 'integer',
          },
        },
        required: ['type', 'value', 'fallback'],
      },
    ],
  };
  refVal[6] = refVal6;
  var refVal7 = (function() {
    var pattern0 = new RegExp('string');
    var pattern1 = new RegExp(
      '#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^}]{4,}\\}',
    );
    var pattern2 = new RegExp(
      '^((?!(#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^\\}]{4,}\\}))[\\s\\S])*$',
    );
    var pattern3 = new RegExp('number');
    var pattern4 = new RegExp('^#\\{.+\\}$');
    var pattern5 = new RegExp('[a-z]{2}');
    var pattern6 = new RegExp(
      '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
    );
    var pattern7 = new RegExp(
      '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
    );
    var pattern8 = new RegExp('boolean');
    return function validate(
      data,
      dataPath,
      parentData,
      parentDataProperty,
      rootData,
    ) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if (data && typeof data === 'object' && !Array.isArray(data)) {
        var errs__0 = errors;
        var valid1 = true;
        var data1 = data.properties;
        if (data1 === undefined) {
          valid1 = true;
        } else {
          var errs_1 = errors;
          if (data1 && typeof data1 === 'object' && !Array.isArray(data1)) {
            if (true) {
              var errs__1 = errors;
              var valid2 = true;
              if (data1.altText === undefined) {
                valid2 = true;
              } else {
                var errs_2 = errors;
                if (
                  !refVal[3](
                    data1.altText,
                    (dataPath || '') + '.properties.altText',
                    data1,
                    'altText',
                    rootData,
                  )
                ) {
                  if (vErrors === null) vErrors = refVal[3].errors;
                  else vErrors = vErrors.concat(refVal[3].errors);
                  errors = vErrors.length;
                } else {
                }
                if (errors === errs_2) {
                }
                var valid2 = errors === errs_2;
              }
              if (valid2) {
                if (data1.format === undefined) {
                  valid2 = false;
                  validate.errors = [
                    {
                      keyword: 'required',
                      dataPath: (dataPath || '') + '.properties',
                      schemaPath: '#/properties/properties/required',
                      params: {
                        missingProperty: 'format',
                      },
                      message: "should have required property 'format'",
                    },
                  ];
                  return false;
                } else {
                  var errs_2 = errors;
                  if (
                    !refVal[3](
                      data1.format,
                      (dataPath || '') + '.properties.format',
                      data1,
                      'format',
                      rootData,
                    )
                  ) {
                    if (vErrors === null) vErrors = refVal[3].errors;
                    else vErrors = vErrors.concat(refVal[3].errors);
                    errors = vErrors.length;
                  } else {
                  }
                  if (errors === errs_2) {
                  }
                  var valid2 = errors === errs_2;
                }
                if (valid2) {
                  if (data1.message === undefined) {
                    valid2 = false;
                    validate.errors = [
                      {
                        keyword: 'required',
                        dataPath: (dataPath || '') + '.properties',
                        schemaPath: '#/properties/properties/required',
                        params: {
                          missingProperty: 'message',
                        },
                        message: "should have required property 'message'",
                      },
                    ];
                    return false;
                  } else {
                    var errs_2 = errors;
                    if (
                      !refVal[3](
                        data1.message,
                        (dataPath || '') + '.properties.message',
                        data1,
                        'message',
                        rootData,
                      )
                    ) {
                      if (vErrors === null) vErrors = refVal[3].errors;
                      else vErrors = vErrors.concat(refVal[3].errors);
                      errors = vErrors.length;
                    } else {
                    }
                    if (errors === errs_2) {
                    }
                    var valid2 = errors === errs_2;
                  }
                  if (valid2) {
                    if (data1.messageEncoding === undefined) {
                      valid2 = false;
                      validate.errors = [
                        {
                          keyword: 'required',
                          dataPath: (dataPath || '') + '.properties',
                          schemaPath: '#/properties/properties/required',
                          params: {
                            missingProperty: 'messageEncoding',
                          },
                          message:
                            "should have required property 'messageEncoding'",
                        },
                      ];
                      return false;
                    } else {
                      var errs_2 = errors;
                      if (
                        !refVal[3](
                          data1.messageEncoding,
                          (dataPath || '') + '.properties.messageEncoding',
                          data1,
                          'messageEncoding',
                          rootData,
                        )
                      ) {
                        if (vErrors === null) vErrors = refVal[3].errors;
                        else vErrors = vErrors.concat(refVal[3].errors);
                        errors = vErrors.length;
                      } else {
                      }
                      if (errors === errs_2) {
                      }
                      var valid2 = errors === errs_2;
                    }
                    if (valid2) {
                    }
                  }
                }
              }
              if (errs__1 == errors) {
              }
            }
          } else {
            validate.errors = [
              {
                keyword: 'type',
                dataPath: (dataPath || '') + '.properties',
                schemaPath: '#/properties/properties/type',
                params: {
                  type: 'object',
                },
                message: 'should be object',
              },
            ];
            return false;
          }
          if (errors === errs_1) {
          }
          var valid1 = errors === errs_1;
        }
        if (valid1) {
        }
        if (errs__0 == errors) {
        }
      } else {
        validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/type',
            params: {
              type: 'object',
            },
            message: 'should be object',
          },
        ];
        return false;
      }
      if (errors === 0) {
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal7.schema = {
    type: 'object',
    properties: {
      properties: {
        type: 'object',
        properties: {
          altText: {
            $ref: '#/definitions/stringProperty',
          },
          format: {
            $ref: '#/definitions/stringProperty',
          },
          message: {
            $ref: '#/definitions/stringProperty',
          },
          messageEncoding: {
            $ref: '#/definitions/stringProperty',
          },
        },
        required: ['messageEncoding', 'format', 'message'],
      },
    },
  };
  refVal7.errors = null;
  refVal[7] = refVal7;
  var refVal8 = (function() {
    var pattern0 = new RegExp('string');
    var pattern1 = new RegExp(
      '#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^}]{4,}\\}',
    );
    var pattern2 = new RegExp(
      '^((?!(#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^\\}]{4,}\\}))[\\s\\S])*$',
    );
    var pattern3 = new RegExp('number');
    var pattern4 = new RegExp('^#\\{.+\\}$');
    var pattern5 = new RegExp('[a-z]{2}');
    var pattern6 = new RegExp(
      '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
    );
    var pattern7 = new RegExp(
      '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
    );
    var pattern8 = new RegExp('boolean');
    return function validate(
      data,
      dataPath,
      parentData,
      parentDataProperty,
      rootData,
    ) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if (data && typeof data === 'object' && !Array.isArray(data)) {
        var errs__0 = errors;
        var valid1 = true;
        var data1 = data.properties;
        if (data1 === undefined) {
          valid1 = true;
        } else {
          var errs_1 = errors;
          if (data1 && typeof data1 === 'object' && !Array.isArray(data1)) {
            if (true) {
              var errs__1 = errors;
              var valid2 = true;
              var data2 = data1.latitude;
              if (data2 === undefined) {
                valid2 = false;
                validate.errors = [
                  {
                    keyword: 'required',
                    dataPath: (dataPath || '') + '.properties',
                    schemaPath: '#/properties/properties/required',
                    params: {
                      missingProperty: 'latitude',
                    },
                    message: "should have required property 'latitude'",
                  },
                ];
                return false;
              } else {
                var errs_2 = errors;
                var errs_3 = errors;
                if (
                  !data2 ||
                  typeof data2 !== 'object' ||
                  Array.isArray(data2)
                ) {
                  validate.errors = [
                    {
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.properties.latitude',
                      schemaPath: '#/definitions/numberProperty/type',
                      params: {
                        type: 'object',
                      },
                      message: 'should be object',
                    },
                  ];
                  return false;
                }
                var errs__3 = errors,
                  prevValid3 = false,
                  valid3 = false,
                  passingSchemas3 = null;
                var errs_4 = errors;
                if (
                  data2 &&
                  typeof data2 === 'object' &&
                  !Array.isArray(data2)
                ) {
                  if (true) {
                    var errs__4 = errors;
                    var valid5 = true;
                    var data3 = data2.type;
                    if (data3 === undefined) {
                      valid5 = false;
                      var err = {
                        keyword: 'required',
                        dataPath: (dataPath || '') + '.properties.latitude',
                        schemaPath:
                          '#/definitions/numberProperty/oneOf/0/required',
                        params: {
                          missingProperty: 'type',
                        },
                        message: "should have required property 'type'",
                      };
                      if (vErrors === null) vErrors = [err];
                      else vErrors.push(err);
                      errors++;
                    } else {
                      var errs_5 = errors;
                      if (typeof data3 === 'string') {
                        if (!pattern3.test(data3)) {
                          var err = {
                            keyword: 'pattern',
                            dataPath:
                              (dataPath || '') + '.properties.latitude.type',
                            schemaPath:
                              '#/definitions/numberProperty/oneOf/0/properties/type/pattern',
                            params: {
                              pattern: 'number',
                            },
                            message: 'should match pattern "number"',
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                        } else {
                        }
                      } else {
                        var err = {
                          keyword: 'type',
                          dataPath:
                            (dataPath || '') + '.properties.latitude.type',
                          schemaPath:
                            '#/definitions/numberProperty/oneOf/0/properties/type/type',
                          params: {
                            type: 'string',
                          },
                          message: 'should be string',
                        };
                        if (vErrors === null) vErrors = [err];
                        else vErrors.push(err);
                        errors++;
                      }
                      if (errors === errs_5) {
                      }
                      var valid5 = errors === errs_5;
                    }
                    if (valid5) {
                      if (data2.value === undefined) {
                        valid5 = false;
                        var err = {
                          keyword: 'required',
                          dataPath: (dataPath || '') + '.properties.latitude',
                          schemaPath:
                            '#/definitions/numberProperty/oneOf/0/required',
                          params: {
                            missingProperty: 'value',
                          },
                          message: "should have required property 'value'",
                        };
                        if (vErrors === null) vErrors = [err];
                        else vErrors.push(err);
                        errors++;
                      } else {
                        var errs_5 = errors;
                        if (typeof data2.value !== 'number') {
                          var err = {
                            keyword: 'type',
                            dataPath:
                              (dataPath || '') + '.properties.latitude.value',
                            schemaPath:
                              '#/definitions/numberProperty/oneOf/0/properties/value/type',
                            params: {
                              type: 'number',
                            },
                            message: 'should be number',
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                        }
                        var valid5 = errors === errs_5;
                      }
                      if (valid5) {
                      }
                    }
                    if (errs__4 == errors) {
                    }
                  }
                }
                if (errors === errs_4) {
                }
                var valid4 = errors === errs_4;
                if (valid4) {
                  valid3 = prevValid3 = true;
                  passingSchemas3 = 0;
                }
                var errs_4 = errors;
                if (
                  data2 &&
                  typeof data2 === 'object' &&
                  !Array.isArray(data2)
                ) {
                  if (true) {
                    var errs__4 = errors;
                    var valid5 = true;
                    var data3 = data2.type;
                    if (data3 === undefined) {
                      valid5 = false;
                      var err = {
                        keyword: 'required',
                        dataPath: (dataPath || '') + '.properties.latitude',
                        schemaPath:
                          '#/definitions/numberProperty/oneOf/1/required',
                        params: {
                          missingProperty: 'type',
                        },
                        message: "should have required property 'type'",
                      };
                      if (vErrors === null) vErrors = [err];
                      else vErrors.push(err);
                      errors++;
                    } else {
                      var errs_5 = errors;
                      if (typeof data3 === 'string') {
                        if (!pattern3.test(data3)) {
                          var err = {
                            keyword: 'pattern',
                            dataPath:
                              (dataPath || '') + '.properties.latitude.type',
                            schemaPath:
                              '#/definitions/numberProperty/oneOf/1/properties/type/pattern',
                            params: {
                              pattern: 'number',
                            },
                            message: 'should match pattern "number"',
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                        } else {
                        }
                      } else {
                        var err = {
                          keyword: 'type',
                          dataPath:
                            (dataPath || '') + '.properties.latitude.type',
                          schemaPath:
                            '#/definitions/numberProperty/oneOf/1/properties/type/type',
                          params: {
                            type: 'string',
                          },
                          message: 'should be string',
                        };
                        if (vErrors === null) vErrors = [err];
                        else vErrors.push(err);
                        errors++;
                      }
                      if (errors === errs_5) {
                      }
                      var valid5 = errors === errs_5;
                    }
                    if (valid5) {
                      var data3 = data2.value;
                      if (data3 === undefined) {
                        valid5 = false;
                        var err = {
                          keyword: 'required',
                          dataPath: (dataPath || '') + '.properties.latitude',
                          schemaPath:
                            '#/definitions/numberProperty/oneOf/1/required',
                          params: {
                            missingProperty: 'value',
                          },
                          message: "should have required property 'value'",
                        };
                        if (vErrors === null) vErrors = [err];
                        else vErrors.push(err);
                        errors++;
                      } else {
                        var errs_5 = errors;
                        if (typeof data3 === 'string') {
                          if (!pattern4.test(data3)) {
                            var err = {
                              keyword: 'pattern',
                              dataPath:
                                (dataPath || '') + '.properties.latitude.value',
                              schemaPath:
                                '#/definitions/numberProperty/oneOf/1/properties/value/pattern',
                              params: {
                                pattern: '^#\\{.+\\}$',
                              },
                              message: 'should match pattern "^#\\{.+\\}$"',
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          } else {
                          }
                        } else {
                          var err = {
                            keyword: 'type',
                            dataPath:
                              (dataPath || '') + '.properties.latitude.value',
                            schemaPath:
                              '#/definitions/numberProperty/oneOf/1/properties/value/type',
                            params: {
                              type: 'string',
                            },
                            message: 'should be string',
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                        }
                        if (errors === errs_5) {
                        }
                        var valid5 = errors === errs_5;
                      }
                      if (valid5) {
                        if (data2.fallback === undefined) {
                          valid5 = false;
                          var err = {
                            keyword: 'required',
                            dataPath: (dataPath || '') + '.properties.latitude',
                            schemaPath:
                              '#/definitions/numberProperty/oneOf/1/required',
                            params: {
                              missingProperty: 'fallback',
                            },
                            message: "should have required property 'fallback'",
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                        } else {
                          var errs_5 = errors;
                          if (typeof data2.fallback !== 'number') {
                            var err = {
                              keyword: 'type',
                              dataPath:
                                (dataPath || '') +
                                '.properties.latitude.fallback',
                              schemaPath:
                                '#/definitions/numberProperty/oneOf/1/properties/fallback/type',
                              params: {
                                type: 'number',
                              },
                              message: 'should be number',
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          }
                          var valid5 = errors === errs_5;
                        }
                        if (valid5) {
                        }
                      }
                    }
                    if (errs__4 == errors) {
                    }
                  }
                }
                if (errors === errs_4) {
                }
                var valid4 = errors === errs_4;
                if (valid4 && prevValid3) {
                  valid3 = false;
                  passingSchemas3 = [passingSchemas3, 1];
                } else {
                  if (valid4) {
                    valid3 = prevValid3 = true;
                    passingSchemas3 = 1;
                  }
                }
                if (!valid3) {
                  var err = {
                    keyword: 'oneOf',
                    dataPath: (dataPath || '') + '.properties.latitude',
                    schemaPath: '#/definitions/numberProperty/oneOf',
                    params: {
                      passingSchemas: passingSchemas3,
                    },
                    message: 'should match exactly one schema in oneOf',
                  };
                  if (vErrors === null) vErrors = [err];
                  else vErrors.push(err);
                  errors++;
                  validate.errors = vErrors;
                  return false;
                } else {
                  errors = errs__3;
                  if (vErrors !== null) {
                    if (errs__3) vErrors.length = errs__3;
                    else vErrors = null;
                  }
                }
                if (errors === errs_3) {
                }
                var valid3 = errors === errs_3;
                if (valid3) {
                }
                if (errors === errs_2) {
                }
                var valid2 = errors === errs_2;
              }
              if (valid2) {
                var data2 = data1.longitude;
                if (data2 === undefined) {
                  valid2 = false;
                  validate.errors = [
                    {
                      keyword: 'required',
                      dataPath: (dataPath || '') + '.properties',
                      schemaPath: '#/properties/properties/required',
                      params: {
                        missingProperty: 'longitude',
                      },
                      message: "should have required property 'longitude'",
                    },
                  ];
                  return false;
                } else {
                  var errs_2 = errors;
                  var errs_3 = errors;
                  if (
                    !data2 ||
                    typeof data2 !== 'object' ||
                    Array.isArray(data2)
                  ) {
                    validate.errors = [
                      {
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.properties.longitude',
                        schemaPath: '#/definitions/numberProperty/type',
                        params: {
                          type: 'object',
                        },
                        message: 'should be object',
                      },
                    ];
                    return false;
                  }
                  var errs__3 = errors,
                    prevValid3 = false,
                    valid3 = false,
                    passingSchemas3 = null;
                  var errs_4 = errors;
                  if (
                    data2 &&
                    typeof data2 === 'object' &&
                    !Array.isArray(data2)
                  ) {
                    if (true) {
                      var errs__4 = errors;
                      var valid5 = true;
                      var data3 = data2.type;
                      if (data3 === undefined) {
                        valid5 = false;
                        var err = {
                          keyword: 'required',
                          dataPath: (dataPath || '') + '.properties.longitude',
                          schemaPath:
                            '#/definitions/numberProperty/oneOf/0/required',
                          params: {
                            missingProperty: 'type',
                          },
                          message: "should have required property 'type'",
                        };
                        if (vErrors === null) vErrors = [err];
                        else vErrors.push(err);
                        errors++;
                      } else {
                        var errs_5 = errors;
                        if (typeof data3 === 'string') {
                          if (!pattern3.test(data3)) {
                            var err = {
                              keyword: 'pattern',
                              dataPath:
                                (dataPath || '') + '.properties.longitude.type',
                              schemaPath:
                                '#/definitions/numberProperty/oneOf/0/properties/type/pattern',
                              params: {
                                pattern: 'number',
                              },
                              message: 'should match pattern "number"',
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          } else {
                          }
                        } else {
                          var err = {
                            keyword: 'type',
                            dataPath:
                              (dataPath || '') + '.properties.longitude.type',
                            schemaPath:
                              '#/definitions/numberProperty/oneOf/0/properties/type/type',
                            params: {
                              type: 'string',
                            },
                            message: 'should be string',
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                        }
                        if (errors === errs_5) {
                        }
                        var valid5 = errors === errs_5;
                      }
                      if (valid5) {
                        if (data2.value === undefined) {
                          valid5 = false;
                          var err = {
                            keyword: 'required',
                            dataPath:
                              (dataPath || '') + '.properties.longitude',
                            schemaPath:
                              '#/definitions/numberProperty/oneOf/0/required',
                            params: {
                              missingProperty: 'value',
                            },
                            message: "should have required property 'value'",
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                        } else {
                          var errs_5 = errors;
                          if (typeof data2.value !== 'number') {
                            var err = {
                              keyword: 'type',
                              dataPath:
                                (dataPath || '') +
                                '.properties.longitude.value',
                              schemaPath:
                                '#/definitions/numberProperty/oneOf/0/properties/value/type',
                              params: {
                                type: 'number',
                              },
                              message: 'should be number',
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          }
                          var valid5 = errors === errs_5;
                        }
                        if (valid5) {
                        }
                      }
                      if (errs__4 == errors) {
                      }
                    }
                  }
                  if (errors === errs_4) {
                  }
                  var valid4 = errors === errs_4;
                  if (valid4) {
                    valid3 = prevValid3 = true;
                    passingSchemas3 = 0;
                  }
                  var errs_4 = errors;
                  if (
                    data2 &&
                    typeof data2 === 'object' &&
                    !Array.isArray(data2)
                  ) {
                    if (true) {
                      var errs__4 = errors;
                      var valid5 = true;
                      var data3 = data2.type;
                      if (data3 === undefined) {
                        valid5 = false;
                        var err = {
                          keyword: 'required',
                          dataPath: (dataPath || '') + '.properties.longitude',
                          schemaPath:
                            '#/definitions/numberProperty/oneOf/1/required',
                          params: {
                            missingProperty: 'type',
                          },
                          message: "should have required property 'type'",
                        };
                        if (vErrors === null) vErrors = [err];
                        else vErrors.push(err);
                        errors++;
                      } else {
                        var errs_5 = errors;
                        if (typeof data3 === 'string') {
                          if (!pattern3.test(data3)) {
                            var err = {
                              keyword: 'pattern',
                              dataPath:
                                (dataPath || '') + '.properties.longitude.type',
                              schemaPath:
                                '#/definitions/numberProperty/oneOf/1/properties/type/pattern',
                              params: {
                                pattern: 'number',
                              },
                              message: 'should match pattern "number"',
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          } else {
                          }
                        } else {
                          var err = {
                            keyword: 'type',
                            dataPath:
                              (dataPath || '') + '.properties.longitude.type',
                            schemaPath:
                              '#/definitions/numberProperty/oneOf/1/properties/type/type',
                            params: {
                              type: 'string',
                            },
                            message: 'should be string',
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                        }
                        if (errors === errs_5) {
                        }
                        var valid5 = errors === errs_5;
                      }
                      if (valid5) {
                        var data3 = data2.value;
                        if (data3 === undefined) {
                          valid5 = false;
                          var err = {
                            keyword: 'required',
                            dataPath:
                              (dataPath || '') + '.properties.longitude',
                            schemaPath:
                              '#/definitions/numberProperty/oneOf/1/required',
                            params: {
                              missingProperty: 'value',
                            },
                            message: "should have required property 'value'",
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                        } else {
                          var errs_5 = errors;
                          if (typeof data3 === 'string') {
                            if (!pattern4.test(data3)) {
                              var err = {
                                keyword: 'pattern',
                                dataPath:
                                  (dataPath || '') +
                                  '.properties.longitude.value',
                                schemaPath:
                                  '#/definitions/numberProperty/oneOf/1/properties/value/pattern',
                                params: {
                                  pattern: '^#\\{.+\\}$',
                                },
                                message: 'should match pattern "^#\\{.+\\}$"',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            } else {
                            }
                          } else {
                            var err = {
                              keyword: 'type',
                              dataPath:
                                (dataPath || '') +
                                '.properties.longitude.value',
                              schemaPath:
                                '#/definitions/numberProperty/oneOf/1/properties/value/type',
                              params: {
                                type: 'string',
                              },
                              message: 'should be string',
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          }
                          if (errors === errs_5) {
                          }
                          var valid5 = errors === errs_5;
                        }
                        if (valid5) {
                          if (data2.fallback === undefined) {
                            valid5 = false;
                            var err = {
                              keyword: 'required',
                              dataPath:
                                (dataPath || '') + '.properties.longitude',
                              schemaPath:
                                '#/definitions/numberProperty/oneOf/1/required',
                              params: {
                                missingProperty: 'fallback',
                              },
                              message:
                                "should have required property 'fallback'",
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          } else {
                            var errs_5 = errors;
                            if (typeof data2.fallback !== 'number') {
                              var err = {
                                keyword: 'type',
                                dataPath:
                                  (dataPath || '') +
                                  '.properties.longitude.fallback',
                                schemaPath:
                                  '#/definitions/numberProperty/oneOf/1/properties/fallback/type',
                                params: {
                                  type: 'number',
                                },
                                message: 'should be number',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            }
                            var valid5 = errors === errs_5;
                          }
                          if (valid5) {
                          }
                        }
                      }
                      if (errs__4 == errors) {
                      }
                    }
                  }
                  if (errors === errs_4) {
                  }
                  var valid4 = errors === errs_4;
                  if (valid4 && prevValid3) {
                    valid3 = false;
                    passingSchemas3 = [passingSchemas3, 1];
                  } else {
                    if (valid4) {
                      valid3 = prevValid3 = true;
                      passingSchemas3 = 1;
                    }
                  }
                  if (!valid3) {
                    var err = {
                      keyword: 'oneOf',
                      dataPath: (dataPath || '') + '.properties.longitude',
                      schemaPath: '#/definitions/numberProperty/oneOf',
                      params: {
                        passingSchemas: passingSchemas3,
                      },
                      message: 'should match exactly one schema in oneOf',
                    };
                    if (vErrors === null) vErrors = [err];
                    else vErrors.push(err);
                    errors++;
                    validate.errors = vErrors;
                    return false;
                  } else {
                    errors = errs__3;
                    if (vErrors !== null) {
                      if (errs__3) vErrors.length = errs__3;
                      else vErrors = null;
                    }
                  }
                  if (errors === errs_3) {
                  }
                  var valid3 = errors === errs_3;
                  if (valid3) {
                  }
                  if (errors === errs_2) {
                  }
                  var valid2 = errors === errs_2;
                }
                if (valid2) {
                  var data2 = data1.altitude;
                  if (data2 === undefined) {
                    valid2 = true;
                  } else {
                    var errs_2 = errors;
                    var errs_3 = errors;
                    if (
                      !data2 ||
                      typeof data2 !== 'object' ||
                      Array.isArray(data2)
                    ) {
                      validate.errors = [
                        {
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.properties.altitude',
                          schemaPath: '#/definitions/numberProperty/type',
                          params: {
                            type: 'object',
                          },
                          message: 'should be object',
                        },
                      ];
                      return false;
                    }
                    var errs__3 = errors,
                      prevValid3 = false,
                      valid3 = false,
                      passingSchemas3 = null;
                    var errs_4 = errors;
                    if (
                      data2 &&
                      typeof data2 === 'object' &&
                      !Array.isArray(data2)
                    ) {
                      if (true) {
                        var errs__4 = errors;
                        var valid5 = true;
                        var data3 = data2.type;
                        if (data3 === undefined) {
                          valid5 = false;
                          var err = {
                            keyword: 'required',
                            dataPath: (dataPath || '') + '.properties.altitude',
                            schemaPath:
                              '#/definitions/numberProperty/oneOf/0/required',
                            params: {
                              missingProperty: 'type',
                            },
                            message: "should have required property 'type'",
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                        } else {
                          var errs_5 = errors;
                          if (typeof data3 === 'string') {
                            if (!pattern3.test(data3)) {
                              var err = {
                                keyword: 'pattern',
                                dataPath:
                                  (dataPath || '') +
                                  '.properties.altitude.type',
                                schemaPath:
                                  '#/definitions/numberProperty/oneOf/0/properties/type/pattern',
                                params: {
                                  pattern: 'number',
                                },
                                message: 'should match pattern "number"',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            } else {
                            }
                          } else {
                            var err = {
                              keyword: 'type',
                              dataPath:
                                (dataPath || '') + '.properties.altitude.type',
                              schemaPath:
                                '#/definitions/numberProperty/oneOf/0/properties/type/type',
                              params: {
                                type: 'string',
                              },
                              message: 'should be string',
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          }
                          if (errors === errs_5) {
                          }
                          var valid5 = errors === errs_5;
                        }
                        if (valid5) {
                          if (data2.value === undefined) {
                            valid5 = false;
                            var err = {
                              keyword: 'required',
                              dataPath:
                                (dataPath || '') + '.properties.altitude',
                              schemaPath:
                                '#/definitions/numberProperty/oneOf/0/required',
                              params: {
                                missingProperty: 'value',
                              },
                              message: "should have required property 'value'",
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          } else {
                            var errs_5 = errors;
                            if (typeof data2.value !== 'number') {
                              var err = {
                                keyword: 'type',
                                dataPath:
                                  (dataPath || '') +
                                  '.properties.altitude.value',
                                schemaPath:
                                  '#/definitions/numberProperty/oneOf/0/properties/value/type',
                                params: {
                                  type: 'number',
                                },
                                message: 'should be number',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            }
                            var valid5 = errors === errs_5;
                          }
                          if (valid5) {
                          }
                        }
                        if (errs__4 == errors) {
                        }
                      }
                    }
                    if (errors === errs_4) {
                    }
                    var valid4 = errors === errs_4;
                    if (valid4) {
                      valid3 = prevValid3 = true;
                      passingSchemas3 = 0;
                    }
                    var errs_4 = errors;
                    if (
                      data2 &&
                      typeof data2 === 'object' &&
                      !Array.isArray(data2)
                    ) {
                      if (true) {
                        var errs__4 = errors;
                        var valid5 = true;
                        var data3 = data2.type;
                        if (data3 === undefined) {
                          valid5 = false;
                          var err = {
                            keyword: 'required',
                            dataPath: (dataPath || '') + '.properties.altitude',
                            schemaPath:
                              '#/definitions/numberProperty/oneOf/1/required',
                            params: {
                              missingProperty: 'type',
                            },
                            message: "should have required property 'type'",
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                        } else {
                          var errs_5 = errors;
                          if (typeof data3 === 'string') {
                            if (!pattern3.test(data3)) {
                              var err = {
                                keyword: 'pattern',
                                dataPath:
                                  (dataPath || '') +
                                  '.properties.altitude.type',
                                schemaPath:
                                  '#/definitions/numberProperty/oneOf/1/properties/type/pattern',
                                params: {
                                  pattern: 'number',
                                },
                                message: 'should match pattern "number"',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            } else {
                            }
                          } else {
                            var err = {
                              keyword: 'type',
                              dataPath:
                                (dataPath || '') + '.properties.altitude.type',
                              schemaPath:
                                '#/definitions/numberProperty/oneOf/1/properties/type/type',
                              params: {
                                type: 'string',
                              },
                              message: 'should be string',
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          }
                          if (errors === errs_5) {
                          }
                          var valid5 = errors === errs_5;
                        }
                        if (valid5) {
                          var data3 = data2.value;
                          if (data3 === undefined) {
                            valid5 = false;
                            var err = {
                              keyword: 'required',
                              dataPath:
                                (dataPath || '') + '.properties.altitude',
                              schemaPath:
                                '#/definitions/numberProperty/oneOf/1/required',
                              params: {
                                missingProperty: 'value',
                              },
                              message: "should have required property 'value'",
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          } else {
                            var errs_5 = errors;
                            if (typeof data3 === 'string') {
                              if (!pattern4.test(data3)) {
                                var err = {
                                  keyword: 'pattern',
                                  dataPath:
                                    (dataPath || '') +
                                    '.properties.altitude.value',
                                  schemaPath:
                                    '#/definitions/numberProperty/oneOf/1/properties/value/pattern',
                                  params: {
                                    pattern: '^#\\{.+\\}$',
                                  },
                                  message: 'should match pattern "^#\\{.+\\}$"',
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                              } else {
                              }
                            } else {
                              var err = {
                                keyword: 'type',
                                dataPath:
                                  (dataPath || '') +
                                  '.properties.altitude.value',
                                schemaPath:
                                  '#/definitions/numberProperty/oneOf/1/properties/value/type',
                                params: {
                                  type: 'string',
                                },
                                message: 'should be string',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            }
                            if (errors === errs_5) {
                            }
                            var valid5 = errors === errs_5;
                          }
                          if (valid5) {
                            if (data2.fallback === undefined) {
                              valid5 = false;
                              var err = {
                                keyword: 'required',
                                dataPath:
                                  (dataPath || '') + '.properties.altitude',
                                schemaPath:
                                  '#/definitions/numberProperty/oneOf/1/required',
                                params: {
                                  missingProperty: 'fallback',
                                },
                                message:
                                  "should have required property 'fallback'",
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            } else {
                              var errs_5 = errors;
                              if (typeof data2.fallback !== 'number') {
                                var err = {
                                  keyword: 'type',
                                  dataPath:
                                    (dataPath || '') +
                                    '.properties.altitude.fallback',
                                  schemaPath:
                                    '#/definitions/numberProperty/oneOf/1/properties/fallback/type',
                                  params: {
                                    type: 'number',
                                  },
                                  message: 'should be number',
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                              }
                              var valid5 = errors === errs_5;
                            }
                            if (valid5) {
                            }
                          }
                        }
                        if (errs__4 == errors) {
                        }
                      }
                    }
                    if (errors === errs_4) {
                    }
                    var valid4 = errors === errs_4;
                    if (valid4 && prevValid3) {
                      valid3 = false;
                      passingSchemas3 = [passingSchemas3, 1];
                    } else {
                      if (valid4) {
                        valid3 = prevValid3 = true;
                        passingSchemas3 = 1;
                      }
                    }
                    if (!valid3) {
                      var err = {
                        keyword: 'oneOf',
                        dataPath: (dataPath || '') + '.properties.altitude',
                        schemaPath: '#/definitions/numberProperty/oneOf',
                        params: {
                          passingSchemas: passingSchemas3,
                        },
                        message: 'should match exactly one schema in oneOf',
                      };
                      if (vErrors === null) vErrors = [err];
                      else vErrors.push(err);
                      errors++;
                      validate.errors = vErrors;
                      return false;
                    } else {
                      errors = errs__3;
                      if (vErrors !== null) {
                        if (errs__3) vErrors.length = errs__3;
                        else vErrors = null;
                      }
                    }
                    if (errors === errs_3) {
                    }
                    var valid3 = errors === errs_3;
                    if (valid3) {
                    }
                    if (errors === errs_2) {
                    }
                    var valid2 = errors === errs_2;
                  }
                  if (valid2) {
                    if (data1.relevantText === undefined) {
                      valid2 = true;
                    } else {
                      var errs_2 = errors;
                      if (
                        !refVal[3](
                          data1.relevantText,
                          (dataPath || '') + '.properties.relevantText',
                          data1,
                          'relevantText',
                          rootData,
                        )
                      ) {
                        if (vErrors === null) vErrors = refVal[3].errors;
                        else vErrors = vErrors.concat(refVal[3].errors);
                        errors = vErrors.length;
                      } else {
                      }
                      if (errors === errs_2) {
                      }
                      var valid2 = errors === errs_2;
                    }
                    if (valid2) {
                    }
                  }
                }
              }
              if (errs__1 == errors) {
              }
            }
          } else {
            validate.errors = [
              {
                keyword: 'type',
                dataPath: (dataPath || '') + '.properties',
                schemaPath: '#/properties/properties/type',
                params: {
                  type: 'object',
                },
                message: 'should be object',
              },
            ];
            return false;
          }
          if (errors === errs_1) {
          }
          var valid1 = errors === errs_1;
        }
        if (valid1) {
        }
        if (errs__0 == errors) {
        }
      } else {
        validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/type',
            params: {
              type: 'object',
            },
            message: 'should be object',
          },
        ];
        return false;
      }
      if (errors === 0) {
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal8.schema = {
    type: 'object',
    properties: {
      properties: {
        type: 'object',
        properties: {
          latitude: {
            $ref: '#/definitions/numberProperty',
          },
          longitude: {
            $ref: '#/definitions/numberProperty',
          },
          altitude: {
            $ref: '#/definitions/numberProperty',
          },
          relevantText: {
            $ref: '#/definitions/stringProperty',
          },
        },
        required: ['latitude', 'longitude'],
      },
    },
  };
  refVal8.errors = null;
  refVal[8] = refVal8;
  var refVal9 = {
    type: 'object',
    oneOf: [
      {
        properties: {
          type: {
            type: 'string',
            pattern: 'number',
          },
          value: {
            type: 'number',
          },
        },
        required: ['type', 'value'],
      },
      {
        properties: {
          type: {
            type: 'string',
            pattern: 'number',
          },
          value: {
            type: 'string',
            pattern: '^#\\{.+\\}$',
          },
          fallback: {
            type: 'number',
          },
        },
        required: ['type', 'value', 'fallback'],
      },
    ],
  };
  refVal[9] = refVal9;
  var refVal10 = (function() {
    var pattern0 = new RegExp('string');
    var pattern1 = new RegExp(
      '#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^}]{4,}\\}',
    );
    var pattern2 = new RegExp(
      '^((?!(#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^\\}]{4,}\\}))[\\s\\S])*$',
    );
    var pattern3 = new RegExp('number');
    var pattern4 = new RegExp('^#\\{.+\\}$');
    var pattern5 = new RegExp('[a-z]{2}');
    var pattern6 = new RegExp(
      '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
    );
    var pattern7 = new RegExp(
      '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
    );
    var pattern8 = new RegExp('boolean');
    return function validate(
      data,
      dataPath,
      parentData,
      parentDataProperty,
      rootData,
    ) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if (!data || typeof data !== 'object' || Array.isArray(data)) {
        validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/type',
            params: {
              type: 'object',
            },
            message: 'should be object',
          },
        ];
        return false;
      }
      var errs__0 = errors,
        prevValid0 = false,
        valid0 = false,
        passingSchemas0 = null;
      var errs_1 = errors;
      var errs_2 = errors;
      if (data && typeof data === 'object' && !Array.isArray(data)) {
        if (true) {
          var errs__2 = errors;
          var valid3 = true;
          var data1 = data.type;
          if (data1 === undefined) {
            valid3 = false;
            var err = {
              keyword: 'required',
              dataPath: (dataPath || '') + '',
              schemaPath: '#/definitions/stringWithPlaceholder/required',
              params: {
                missingProperty: 'type',
              },
              message: "should have required property 'type'",
            };
            if (vErrors === null) vErrors = [err];
            else vErrors.push(err);
            errors++;
          } else {
            var errs_3 = errors;
            if (typeof data1 === 'string') {
              if (!pattern0.test(data1)) {
                var err = {
                  keyword: 'pattern',
                  dataPath: (dataPath || '') + '.type',
                  schemaPath:
                    '#/definitions/stringWithPlaceholder/properties/type/pattern',
                  params: {
                    pattern: 'string',
                  },
                  message: 'should match pattern "string"',
                };
                if (vErrors === null) vErrors = [err];
                else vErrors.push(err);
                errors++;
              } else {
              }
            } else {
              var err = {
                keyword: 'type',
                dataPath: (dataPath || '') + '.type',
                schemaPath:
                  '#/definitions/stringWithPlaceholder/properties/type/type',
                params: {
                  type: 'string',
                },
                message: 'should be string',
              };
              if (vErrors === null) vErrors = [err];
              else vErrors.push(err);
              errors++;
            }
            if (errors === errs_3) {
            }
            var valid3 = errors === errs_3;
          }
          if (valid3) {
            var data1 = data.value;
            if (data1 === undefined) {
              valid3 = false;
              var err = {
                keyword: 'required',
                dataPath: (dataPath || '') + '',
                schemaPath: '#/definitions/stringWithPlaceholder/required',
                params: {
                  missingProperty: 'value',
                },
                message: "should have required property 'value'",
              };
              if (vErrors === null) vErrors = [err];
              else vErrors.push(err);
              errors++;
            } else {
              var errs_3 = errors;
              if (typeof data1 === 'string') {
                if (!pattern1.test(data1)) {
                  var err = {
                    keyword: 'pattern',
                    dataPath: (dataPath || '') + '.value',
                    schemaPath:
                      '#/definitions/stringWithPlaceholder/properties/value/pattern',
                    params: {
                      pattern:
                        '#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^}]{4,}\\}',
                    },
                    message:
                      'should match pattern "#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^}]{4,}\\}"',
                  };
                  if (vErrors === null) vErrors = [err];
                  else vErrors.push(err);
                  errors++;
                } else {
                }
              } else {
                var err = {
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.value',
                  schemaPath:
                    '#/definitions/stringWithPlaceholder/properties/value/type',
                  params: {
                    type: 'string',
                  },
                  message: 'should be string',
                };
                if (vErrors === null) vErrors = [err];
                else vErrors.push(err);
                errors++;
              }
              if (errors === errs_3) {
              }
              var valid3 = errors === errs_3;
            }
            if (valid3) {
              if (data.fallback === undefined) {
                valid3 = false;
                var err = {
                  keyword: 'required',
                  dataPath: (dataPath || '') + '',
                  schemaPath: '#/definitions/stringWithPlaceholder/required',
                  params: {
                    missingProperty: 'fallback',
                  },
                  message: "should have required property 'fallback'",
                };
                if (vErrors === null) vErrors = [err];
                else vErrors.push(err);
                errors++;
              } else {
                var errs_3 = errors;
                if (typeof data.fallback !== 'string') {
                  var err = {
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.fallback',
                    schemaPath:
                      '#/definitions/stringWithPlaceholder/properties/fallback/type',
                    params: {
                      type: 'string',
                    },
                    message: 'should be string',
                  };
                  if (vErrors === null) vErrors = [err];
                  else vErrors.push(err);
                  errors++;
                }
                var valid3 = errors === errs_3;
              }
              if (valid3) {
              }
            }
          }
          if (errs__2 == errors) {
          }
        }
      }
      if (errors === errs_2) {
      }
      var valid2 = errors === errs_2;
      if (valid2) {
      }
      if (errors === errs_1) {
      }
      var valid1 = errors === errs_1;
      if (valid1) {
        valid0 = prevValid0 = true;
        passingSchemas0 = 0;
      }
      var errs_1 = errors;
      var errs_2 = errors;
      if (data && typeof data === 'object' && !Array.isArray(data)) {
        if (true) {
          var errs__2 = errors;
          var valid3 = true;
          var data1 = data.type;
          if (data1 === undefined) {
            valid3 = false;
            var err = {
              keyword: 'required',
              dataPath: (dataPath || '') + '',
              schemaPath: '#/definitions/stringWithoutPlaceholder/required',
              params: {
                missingProperty: 'type',
              },
              message: "should have required property 'type'",
            };
            if (vErrors === null) vErrors = [err];
            else vErrors.push(err);
            errors++;
          } else {
            var errs_3 = errors;
            if (typeof data1 === 'string') {
              if (!pattern0.test(data1)) {
                var err = {
                  keyword: 'pattern',
                  dataPath: (dataPath || '') + '.type',
                  schemaPath:
                    '#/definitions/stringWithoutPlaceholder/properties/type/pattern',
                  params: {
                    pattern: 'string',
                  },
                  message: 'should match pattern "string"',
                };
                if (vErrors === null) vErrors = [err];
                else vErrors.push(err);
                errors++;
              } else {
              }
            } else {
              var err = {
                keyword: 'type',
                dataPath: (dataPath || '') + '.type',
                schemaPath:
                  '#/definitions/stringWithoutPlaceholder/properties/type/type',
                params: {
                  type: 'string',
                },
                message: 'should be string',
              };
              if (vErrors === null) vErrors = [err];
              else vErrors.push(err);
              errors++;
            }
            if (errors === errs_3) {
            }
            var valid3 = errors === errs_3;
          }
          if (valid3) {
            var data1 = data.value;
            if (data1 === undefined) {
              valid3 = false;
              var err = {
                keyword: 'required',
                dataPath: (dataPath || '') + '',
                schemaPath: '#/definitions/stringWithoutPlaceholder/required',
                params: {
                  missingProperty: 'value',
                },
                message: "should have required property 'value'",
              };
              if (vErrors === null) vErrors = [err];
              else vErrors.push(err);
              errors++;
            } else {
              var errs_3 = errors;
              if (typeof data1 === 'string') {
                if (!pattern2.test(data1)) {
                  var err = {
                    keyword: 'pattern',
                    dataPath: (dataPath || '') + '.value',
                    schemaPath:
                      '#/definitions/stringWithoutPlaceholder/properties/value/pattern',
                    params: {
                      pattern:
                        '^((?!(#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^\\}]{4,}\\}))[\\s\\S])*$',
                    },
                    message:
                      'should match pattern "^((?!(#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^\\}]{4,}\\}))[\\s\\S])*$"',
                  };
                  if (vErrors === null) vErrors = [err];
                  else vErrors.push(err);
                  errors++;
                } else {
                }
              } else {
                var err = {
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.value',
                  schemaPath:
                    '#/definitions/stringWithoutPlaceholder/properties/value/type',
                  params: {
                    type: 'string',
                  },
                  message: 'should be string',
                };
                if (vErrors === null) vErrors = [err];
                else vErrors.push(err);
                errors++;
              }
              if (errors === errs_3) {
              }
              var valid3 = errors === errs_3;
            }
            if (valid3) {
            }
          }
          if (errs__2 == errors) {
          }
        }
      }
      if (errors === errs_2) {
      }
      var valid2 = errors === errs_2;
      if (valid2) {
      }
      if (errors === errs_1) {
      }
      var valid1 = errors === errs_1;
      if (valid1 && prevValid0) {
        valid0 = false;
        passingSchemas0 = [passingSchemas0, 1];
      } else {
        if (valid1) {
          valid0 = prevValid0 = true;
          passingSchemas0 = 1;
        }
        var errs_1 = errors;
        if (data && typeof data === 'object' && !Array.isArray(data)) {
          if (true) {
            var errs__1 = errors;
            var valid2 = true;
            var data1 = data.type;
            if (data1 === undefined) {
              valid2 = false;
              var err = {
                keyword: 'required',
                dataPath: (dataPath || '') + '',
                schemaPath: '#/oneOf/2/required',
                params: {
                  missingProperty: 'type',
                },
                message: "should have required property 'type'",
              };
              if (vErrors === null) vErrors = [err];
              else vErrors.push(err);
              errors++;
            } else {
              var errs_2 = errors;
              if (typeof data1 === 'string') {
                if (!pattern0.test(data1)) {
                  var err = {
                    keyword: 'pattern',
                    dataPath: (dataPath || '') + '.type',
                    schemaPath: '#/oneOf/2/properties/type/pattern',
                    params: {
                      pattern: 'string',
                    },
                    message: 'should match pattern "string"',
                  };
                  if (vErrors === null) vErrors = [err];
                  else vErrors.push(err);
                  errors++;
                } else {
                }
              } else {
                var err = {
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.type',
                  schemaPath: '#/oneOf/2/properties/type/type',
                  params: {
                    type: 'string',
                  },
                  message: 'should be string',
                };
                if (vErrors === null) vErrors = [err];
                else vErrors.push(err);
                errors++;
              }
              if (errors === errs_2) {
              }
              var valid2 = errors === errs_2;
            }
            if (valid2) {
              var data1 = data.value;
              if (data1 === undefined) {
                valid2 = false;
                var err = {
                  keyword: 'required',
                  dataPath: (dataPath || '') + '',
                  schemaPath: '#/oneOf/2/required',
                  params: {
                    missingProperty: 'value',
                  },
                  message: "should have required property 'value'",
                };
                if (vErrors === null) vErrors = [err];
                else vErrors.push(err);
                errors++;
              } else {
                var errs_2 = errors;
                if (
                  data1 &&
                  typeof data1 === 'object' &&
                  !Array.isArray(data1)
                ) {
                  var errs__2 = errors;
                  var valid3 = true;
                  for (var key2 in data1) {
                    var isAdditional2 = !(false || pattern5.test(key2));
                    if (isAdditional2) {
                      valid3 = false;
                      var err = {
                        keyword: 'additionalProperties',
                        dataPath: (dataPath || '') + '.value',
                        schemaPath:
                          '#/oneOf/2/properties/value/additionalProperties',
                        params: {
                          additionalProperty: '' + key2 + '',
                        },
                        message: 'should NOT have additional properties',
                      };
                      if (vErrors === null) vErrors = [err];
                      else vErrors.push(err);
                      errors++;
                      break;
                    }
                  }
                  if (valid3) {
                    for (var key2 in data1) {
                      if (pattern5.test(key2)) {
                        var errs_3 = errors;
                        if (typeof data1[key2] !== 'string') {
                          var err = {
                            keyword: 'type',
                            dataPath:
                              (dataPath || '') + ".value['" + key2 + "']",
                            schemaPath:
                              '#/oneOf/2/properties/value/patternProperties/%5Ba-z%5D%7B2%7D/type',
                            params: {
                              type: 'string',
                            },
                            message: 'should be string',
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                        }
                        var valid3 = errors === errs_3;
                        if (!valid3) break;
                      } else valid3 = true;
                    }
                    if (valid3) {
                    }
                  }
                  if (errs__2 == errors) {
                  }
                } else {
                  var err = {
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.value',
                    schemaPath: '#/oneOf/2/properties/value/type',
                    params: {
                      type: 'object',
                    },
                    message: 'should be object',
                  };
                  if (vErrors === null) vErrors = [err];
                  else vErrors.push(err);
                  errors++;
                }
                if (errors === errs_2) {
                }
                var valid2 = errors === errs_2;
              }
              if (valid2) {
              }
            }
            if (errs__1 == errors) {
            }
          }
        }
        if (errors === errs_1) {
        }
        var valid1 = errors === errs_1;
        if (valid1 && prevValid0) {
          valid0 = false;
          passingSchemas0 = [passingSchemas0, 2];
        } else {
          if (valid1) {
            valid0 = prevValid0 = true;
            passingSchemas0 = 2;
          }
        }
      }
      if (!valid0) {
        var err = {
          keyword: 'oneOf',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/oneOf',
          params: {
            passingSchemas: passingSchemas0,
          },
          message: 'should match exactly one schema in oneOf',
        };
        if (vErrors === null) vErrors = [err];
        else vErrors.push(err);
        errors++;
        validate.errors = vErrors;
        return false;
      } else {
        errors = errs__0;
        if (vErrors !== null) {
          if (errs__0) vErrors.length = errs__0;
          else vErrors = null;
        }
      }
      if (errors === 0) {
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal10.schema = {
    type: 'object',
    oneOf: [
      {
        $ref: '#/definitions/stringWithPlaceholder',
      },
      {
        $ref: '#/definitions/stringWithoutPlaceholder',
      },
      {
        properties: {
          type: {
            type: 'string',
            pattern: 'string',
          },
          value: {
            type: 'object',
            patternProperties: {
              '[a-z]{2}': {
                type: 'string',
              },
            },
            additionalProperties: false,
          },
        },
        required: ['type', 'value'],
      },
    ],
  };
  refVal10.errors = null;
  refVal[10] = refVal10;
  var refVal11 = {
    type: 'object',
    oneOf: [
      {
        properties: {
          type: {
            type: 'string',
            pattern: 'string',
          },
          value: {
            type: 'string',
            pattern:
              '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
          },
        },
        required: ['type', 'value'],
      },
      {
        properties: {
          type: {
            type: 'string',
            pattern: 'string',
          },
          value: {
            type: 'string',
            pattern: '^#\\{.+\\}$',
          },
          fallback: {
            type: 'string',
            pattern:
              '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
          },
        },
        required: ['type', 'value', 'fallback'],
      },
    ],
  };
  refVal[11] = refVal11;
  var refVal12 = {
    type: 'object',
    oneOf: [
      {
        properties: {
          type: {
            type: 'string',
            pattern: 'string',
          },
          value: {
            type: 'string',
            pattern:
              '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
          },
        },
        required: ['type', 'value'],
      },
      {
        properties: {
          type: {
            type: 'string',
            pattern: 'string',
          },
          value: {
            type: 'string',
            pattern: '^#\\{.+\\}$',
          },
          fallback: {
            type: 'string',
            pattern:
              '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
          },
        },
        required: ['type', 'value', 'fallback'],
      },
    ],
  };
  refVal[12] = refVal12;
  var refVal13 = {
    type: 'object',
    oneOf: [
      {
        properties: {
          type: {
            type: 'string',
            pattern: 'boolean',
          },
          value: {
            type: 'boolean',
          },
        },
        required: ['type', 'value'],
      },
      {
        properties: {
          type: {
            type: 'string',
            pattern: 'boolean',
          },
          value: {
            type: 'string',
            pattern: '^#\\{.+\\}$',
          },
          fallback: {
            type: 'boolean',
          },
        },
        required: ['type', 'value', 'fallback'],
      },
    ],
  };
  refVal[13] = refVal13;
  var refVal14 = (function() {
    var pattern0 = new RegExp('string');
    var pattern1 = new RegExp(
      '#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^}]{4,}\\}',
    );
    var pattern2 = new RegExp(
      '^((?!(#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^\\}]{4,}\\}))[\\s\\S])*$',
    );
    var pattern3 = new RegExp('number');
    var pattern4 = new RegExp('^#\\{.+\\}$');
    var pattern5 = new RegExp('[a-z]{2}');
    var pattern6 = new RegExp(
      '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
    );
    var pattern7 = new RegExp(
      '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
    );
    var pattern8 = new RegExp('boolean');
    return function validate(
      data,
      dataPath,
      parentData,
      parentDataProperty,
      rootData,
    ) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if (data && typeof data === 'object' && !Array.isArray(data)) {
        var errs__0 = errors;
        var valid1 = true;
        var data1 = data.type;
        if (data1 === undefined) {
          valid1 = true;
        } else {
          var errs_1 = errors;
          var errs_2 = errors;
          if (typeof data1 !== 'string') {
            validate.errors = [
              {
                keyword: 'type',
                dataPath: (dataPath || '') + '.type',
                schemaPath: '#/definitions/objectType/type',
                params: {
                  type: 'string',
                },
                message: 'should be string',
              },
            ];
            return false;
          }
          var schema2 = refVal15.enum;
          var valid2;
          valid2 = false;
          for (var i2 = 0; i2 < schema2.length; i2++)
            if (equal(data1, schema2[i2])) {
              valid2 = true;
              break;
            }
          if (!valid2) {
            validate.errors = [
              {
                keyword: 'enum',
                dataPath: (dataPath || '') + '.type',
                schemaPath: '#/definitions/objectType/enum',
                params: {
                  allowedValues: schema2,
                },
                message: 'should be equal to one of the allowed values',
              },
            ];
            return false;
          } else {
          }
          if (errors === errs_2) {
          }
          var valid2 = errors === errs_2;
          if (valid2) {
          }
          if (errors === errs_1) {
          }
          var valid1 = errors === errs_1;
        }
        if (valid1) {
          var data1 = data.properties;
          if (data1 === undefined) {
            valid1 = true;
          } else {
            var errs_1 = errors;
            if (data1 && typeof data1 === 'object' && !Array.isArray(data1)) {
              if (true) {
                var errs__1 = errors;
                var valid2 = true;
                if (data1.auxiliaryFields === undefined) {
                  valid2 = true;
                } else {
                  var errs_2 = errors;
                  if (
                    !refVal16(
                      data1.auxiliaryFields,
                      (dataPath || '') + '.properties.auxiliaryFields',
                      data1,
                      'auxiliaryFields',
                      rootData,
                    )
                  ) {
                    if (vErrors === null) vErrors = refVal16.errors;
                    else vErrors = vErrors.concat(refVal16.errors);
                    errors = vErrors.length;
                  } else {
                  }
                  if (errors === errs_2) {
                  }
                  var valid2 = errors === errs_2;
                }
                if (valid2) {
                  if (data1.backFields === undefined) {
                    valid2 = true;
                  } else {
                    var errs_2 = errors;
                    if (
                      !refVal[16](
                        data1.backFields,
                        (dataPath || '') + '.properties.backFields',
                        data1,
                        'backFields',
                        rootData,
                      )
                    ) {
                      if (vErrors === null) vErrors = refVal[16].errors;
                      else vErrors = vErrors.concat(refVal[16].errors);
                      errors = vErrors.length;
                    } else {
                    }
                    if (errors === errs_2) {
                    }
                    var valid2 = errors === errs_2;
                  }
                  if (valid2) {
                    if (data1.headerFields === undefined) {
                      valid2 = true;
                    } else {
                      var errs_2 = errors;
                      if (
                        !refVal[16](
                          data1.headerFields,
                          (dataPath || '') + '.properties.headerFields',
                          data1,
                          'headerFields',
                          rootData,
                        )
                      ) {
                        if (vErrors === null) vErrors = refVal[16].errors;
                        else vErrors = vErrors.concat(refVal[16].errors);
                        errors = vErrors.length;
                      } else {
                      }
                      if (errors === errs_2) {
                      }
                      var valid2 = errors === errs_2;
                    }
                    if (valid2) {
                      if (data1.primaryFields === undefined) {
                        valid2 = true;
                      } else {
                        var errs_2 = errors;
                        if (
                          !refVal[16](
                            data1.primaryFields,
                            (dataPath || '') + '.properties.primaryFields',
                            data1,
                            'primaryFields',
                            rootData,
                          )
                        ) {
                          if (vErrors === null) vErrors = refVal[16].errors;
                          else vErrors = vErrors.concat(refVal[16].errors);
                          errors = vErrors.length;
                        } else {
                        }
                        if (errors === errs_2) {
                        }
                        var valid2 = errors === errs_2;
                      }
                      if (valid2) {
                        if (data1.secondaryFields === undefined) {
                          valid2 = true;
                        } else {
                          var errs_2 = errors;
                          if (
                            !refVal[16](
                              data1.secondaryFields,
                              (dataPath || '') + '.properties.secondaryFields',
                              data1,
                              'secondaryFields',
                              rootData,
                            )
                          ) {
                            if (vErrors === null) vErrors = refVal[16].errors;
                            else vErrors = vErrors.concat(refVal[16].errors);
                            errors = vErrors.length;
                          } else {
                          }
                          if (errors === errs_2) {
                          }
                          var valid2 = errors === errs_2;
                        }
                        if (valid2) {
                          if (data1.transitType === undefined) {
                            valid2 = false;
                            validate.errors = [
                              {
                                keyword: 'required',
                                dataPath: (dataPath || '') + '.properties',
                                schemaPath: '#/properties/properties/required',
                                params: {
                                  missingProperty: 'transitType',
                                },
                                message:
                                  "should have required property 'transitType'",
                              },
                            ];
                            return false;
                          } else {
                            var errs_2 = errors;
                            if (
                              !refVal[3](
                                data1.transitType,
                                (dataPath || '') + '.properties.transitType',
                                data1,
                                'transitType',
                                rootData,
                              )
                            ) {
                              if (vErrors === null) vErrors = refVal[3].errors;
                              else vErrors = vErrors.concat(refVal[3].errors);
                              errors = vErrors.length;
                            } else {
                            }
                            if (errors === errs_2) {
                            }
                            var valid2 = errors === errs_2;
                          }
                          if (valid2) {
                          }
                        }
                      }
                    }
                  }
                }
                if (errs__1 == errors) {
                }
              }
            } else {
              validate.errors = [
                {
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.properties',
                  schemaPath: '#/properties/properties/type',
                  params: {
                    type: 'object',
                  },
                  message: 'should be object',
                },
              ];
              return false;
            }
            if (errors === errs_1) {
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
          }
        }
        if (errs__0 == errors) {
        }
      } else {
        validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/type',
            params: {
              type: 'object',
            },
            message: 'should be object',
          },
        ];
        return false;
      }
      if (errors === 0) {
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal14.schema = {
    type: 'object',
    properties: {
      type: {
        $ref: '#/definitions/objectType',
      },
      properties: {
        type: 'object',
        properties: {
          auxiliaryFields: {
            $ref: '#/definitions/fields',
          },
          backFields: {
            $ref: '#/definitions/fields',
          },
          headerFields: {
            $ref: '#/definitions/fields',
          },
          primaryFields: {
            $ref: '#/definitions/fields',
          },
          secondaryFields: {
            $ref: '#/definitions/fields',
          },
          transitType: {
            $ref: '#/definitions/stringProperty',
          },
        },
        required: ['transitType'],
      },
    },
  };
  refVal14.errors = null;
  refVal[14] = refVal14;
  var refVal15 = {
    type: 'string',
    enum: ['object'],
  };
  refVal[15] = refVal15;
  var refVal16 = (function() {
    var pattern0 = new RegExp('string');
    var pattern1 = new RegExp(
      '#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^}]{4,}\\}',
    );
    var pattern2 = new RegExp(
      '^((?!(#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^\\}]{4,}\\}))[\\s\\S])*$',
    );
    var pattern3 = new RegExp('number');
    var pattern4 = new RegExp('^#\\{.+\\}$');
    var pattern5 = new RegExp('[a-z]{2}');
    var pattern6 = new RegExp(
      '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
    );
    var pattern7 = new RegExp(
      '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
    );
    var pattern8 = new RegExp('boolean');
    return function validate(
      data,
      dataPath,
      parentData,
      parentDataProperty,
      rootData,
    ) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if (data && typeof data === 'object' && !Array.isArray(data)) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          var data1 = data.type;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [
              {
                keyword: 'required',
                dataPath: (dataPath || '') + '',
                schemaPath: '#/required',
                params: {
                  missingProperty: 'type',
                },
                message: "should have required property 'type'",
              },
            ];
            return false;
          } else {
            var errs_1 = errors;
            var errs_2 = errors;
            if (typeof data1 !== 'string') {
              validate.errors = [
                {
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.type',
                  schemaPath: '#/definitions/arrayType/type',
                  params: {
                    type: 'string',
                  },
                  message: 'should be string',
                },
              ];
              return false;
            }
            var schema2 = refVal[1].enum;
            var valid2;
            valid2 = false;
            for (var i2 = 0; i2 < schema2.length; i2++)
              if (equal(data1, schema2[i2])) {
                valid2 = true;
                break;
              }
            if (!valid2) {
              validate.errors = [
                {
                  keyword: 'enum',
                  dataPath: (dataPath || '') + '.type',
                  schemaPath: '#/definitions/arrayType/enum',
                  params: {
                    allowedValues: schema2,
                  },
                  message: 'should be equal to one of the allowed values',
                },
              ];
              return false;
            } else {
            }
            if (errors === errs_2) {
            }
            var valid2 = errors === errs_2;
            if (valid2) {
            }
            if (errors === errs_1) {
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            var data1 = data.items;
            if (data1 === undefined) {
              valid1 = false;
              validate.errors = [
                {
                  keyword: 'required',
                  dataPath: (dataPath || '') + '',
                  schemaPath: '#/required',
                  params: {
                    missingProperty: 'items',
                  },
                  message: "should have required property 'items'",
                },
              ];
              return false;
            } else {
              var errs_1 = errors;
              if (Array.isArray(data1)) {
                var errs__1 = errors;
                var valid1;
                for (var i1 = 0; i1 < data1.length; i1++) {
                  var errs_2 = errors;
                  if (
                    !refVal17(
                      data1[i1],
                      (dataPath || '') + '.items[' + i1 + ']',
                      data1,
                      i1,
                      rootData,
                    )
                  ) {
                    if (vErrors === null) vErrors = refVal17.errors;
                    else vErrors = vErrors.concat(refVal17.errors);
                    errors = vErrors.length;
                  } else {
                  }
                  if (errors === errs_2) {
                  }
                  var valid2 = errors === errs_2;
                  if (!valid2) break;
                }
                if (errs__1 == errors) {
                }
              } else {
                validate.errors = [
                  {
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.items',
                    schemaPath: '#/properties/items/type',
                    params: {
                      type: 'array',
                    },
                    message: 'should be array',
                  },
                ];
                return false;
              }
              if (errors === errs_1) {
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
            }
          }
          if (errs__0 == errors) {
          }
        }
      } else {
        validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/type',
            params: {
              type: 'object',
            },
            message: 'should be object',
          },
        ];
        return false;
      }
      if (errors === 0) {
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal16.schema = {
    type: 'object',
    properties: {
      type: {
        $ref: '#/definitions/arrayType',
      },
      items: {
        type: 'array',
        items: {
          $ref: '#/definitions/field',
        },
      },
    },
    required: ['type', 'items'],
  };
  refVal16.errors = null;
  refVal[16] = refVal16;
  var refVal17 = (function() {
    var pattern0 = new RegExp('string');
    var pattern1 = new RegExp(
      '#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^}]{4,}\\}',
    );
    var pattern2 = new RegExp(
      '^((?!(#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^\\}]{4,}\\}))[\\s\\S])*$',
    );
    var pattern3 = new RegExp('number');
    var pattern4 = new RegExp('^#\\{.+\\}$');
    var pattern5 = new RegExp('[a-z]{2}');
    var pattern6 = new RegExp(
      '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
    );
    var pattern7 = new RegExp(
      '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
    );
    var pattern8 = new RegExp('boolean');
    return function validate(
      data,
      dataPath,
      parentData,
      parentDataProperty,
      rootData,
    ) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if (data && typeof data === 'object' && !Array.isArray(data)) {
        var errs__0 = errors;
        var valid1 = true;
        var data1 = data.type;
        if (data1 === undefined) {
          valid1 = true;
        } else {
          var errs_1 = errors;
          var errs_2 = errors;
          if (typeof data1 !== 'string') {
            validate.errors = [
              {
                keyword: 'type',
                dataPath: (dataPath || '') + '.type',
                schemaPath: '#/definitions/objectType/type',
                params: {
                  type: 'string',
                },
                message: 'should be string',
              },
            ];
            return false;
          }
          var schema2 = refVal[15].enum;
          var valid2;
          valid2 = false;
          for (var i2 = 0; i2 < schema2.length; i2++)
            if (equal(data1, schema2[i2])) {
              valid2 = true;
              break;
            }
          if (!valid2) {
            validate.errors = [
              {
                keyword: 'enum',
                dataPath: (dataPath || '') + '.type',
                schemaPath: '#/definitions/objectType/enum',
                params: {
                  allowedValues: schema2,
                },
                message: 'should be equal to one of the allowed values',
              },
            ];
            return false;
          } else {
          }
          if (errors === errs_2) {
          }
          var valid2 = errors === errs_2;
          if (valid2) {
          }
          if (errors === errs_1) {
          }
          var valid1 = errors === errs_1;
        }
        if (valid1) {
          var data1 = data.properties;
          if (data1 === undefined) {
            valid1 = true;
          } else {
            var errs_1 = errors;
            var errs__1 = errors,
              prevValid1 = false,
              valid1 = false,
              passingSchemas1 = null;
            var errs_2 = errors;
            if (
              !refVal18(
                data1,
                (dataPath || '') + '.properties',
                data,
                'properties',
                rootData,
              )
            ) {
              if (vErrors === null) vErrors = refVal18.errors;
              else vErrors = vErrors.concat(refVal18.errors);
              errors = vErrors.length;
            } else {
            }
            if (errors === errs_2) {
            }
            var valid2 = errors === errs_2;
            if (valid2) {
              valid1 = prevValid1 = true;
              passingSchemas1 = 0;
            }
            var errs_2 = errors;
            if (
              !refVal22(
                data1,
                (dataPath || '') + '.properties',
                data,
                'properties',
                rootData,
              )
            ) {
              if (vErrors === null) vErrors = refVal22.errors;
              else vErrors = vErrors.concat(refVal22.errors);
              errors = vErrors.length;
            } else {
            }
            if (errors === errs_2) {
            }
            var valid2 = errors === errs_2;
            if (valid2 && prevValid1) {
              valid1 = false;
              passingSchemas1 = [passingSchemas1, 1];
            } else {
              if (valid2) {
                valid1 = prevValid1 = true;
                passingSchemas1 = 1;
              }
              var errs_2 = errors;
              if (
                !refVal24(
                  data1,
                  (dataPath || '') + '.properties',
                  data,
                  'properties',
                  rootData,
                )
              ) {
                if (vErrors === null) vErrors = refVal24.errors;
                else vErrors = vErrors.concat(refVal24.errors);
                errors = vErrors.length;
              } else {
              }
              if (errors === errs_2) {
              }
              var valid2 = errors === errs_2;
              if (valid2 && prevValid1) {
                valid1 = false;
                passingSchemas1 = [passingSchemas1, 2];
              } else {
                if (valid2) {
                  valid1 = prevValid1 = true;
                  passingSchemas1 = 2;
                }
                var errs_2 = errors;
                if (
                  !refVal25(
                    data1,
                    (dataPath || '') + '.properties',
                    data,
                    'properties',
                    rootData,
                  )
                ) {
                  if (vErrors === null) vErrors = refVal25.errors;
                  else vErrors = vErrors.concat(refVal25.errors);
                  errors = vErrors.length;
                } else {
                }
                if (errors === errs_2) {
                }
                var valid2 = errors === errs_2;
                if (valid2 && prevValid1) {
                  valid1 = false;
                  passingSchemas1 = [passingSchemas1, 3];
                } else {
                  if (valid2) {
                    valid1 = prevValid1 = true;
                    passingSchemas1 = 3;
                  }
                }
              }
            }
            if (!valid1) {
              var err = {
                keyword: 'oneOf',
                dataPath: (dataPath || '') + '.properties',
                schemaPath: '#/properties/properties/oneOf',
                params: {
                  passingSchemas: passingSchemas1,
                },
                message: 'should match exactly one schema in oneOf',
              };
              if (vErrors === null) vErrors = [err];
              else vErrors.push(err);
              errors++;
              validate.errors = vErrors;
              return false;
            } else {
              errors = errs__1;
              if (vErrors !== null) {
                if (errs__1) vErrors.length = errs__1;
                else vErrors = null;
              }
            }
            if (errors === errs_1) {
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
          }
        }
        if (errs__0 == errors) {
        }
      } else {
        validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/type',
            params: {
              type: 'object',
            },
            message: 'should be object',
          },
        ];
        return false;
      }
      if (errors === 0) {
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal17.schema = {
    type: 'object',
    properties: {
      type: {
        $ref: '#/definitions/objectType',
      },
      properties: {
        oneOf: [
          {
            $ref: '#/definitions/dateFieldType',
          },
          {
            $ref: '#/definitions/numberFieldType',
          },
          {
            $ref: '#/definitions/currencyFieldType',
          },
          {
            $ref: '#/definitions/stringFieldType',
          },
        ],
      },
    },
  };
  refVal17.errors = null;
  refVal[17] = refVal17;
  var refVal18 = (function() {
    var pattern0 = new RegExp('string');
    var pattern1 = new RegExp(
      '#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^}]{4,}\\}',
    );
    var pattern2 = new RegExp(
      '^((?!(#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^\\}]{4,}\\}))[\\s\\S])*$',
    );
    var pattern3 = new RegExp('number');
    var pattern4 = new RegExp('^#\\{.+\\}$');
    var pattern5 = new RegExp('[a-z]{2}');
    var pattern6 = new RegExp(
      '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
    );
    var pattern7 = new RegExp(
      '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
    );
    var pattern8 = new RegExp('boolean');
    return function validate(
      data,
      dataPath,
      parentData,
      parentDataProperty,
      rootData,
    ) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if (data && typeof data === 'object' && !Array.isArray(data)) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          for (var key0 in data) {
            var isAdditional0 = !(
              false || validate.schema.properties.hasOwnProperty(key0)
            );
            if (isAdditional0) {
              valid1 = false;
              validate.errors = [
                {
                  keyword: 'additionalProperties',
                  dataPath: (dataPath || '') + '',
                  schemaPath: '#/additionalProperties',
                  params: {
                    additionalProperty: '' + key0 + '',
                  },
                  message: 'should NOT have additional properties',
                },
              ];
              return false;
              break;
            }
          }
          if (valid1) {
            if (data.changeMessage === undefined) {
              valid1 = true;
            } else {
              var errs_1 = errors;
              if (
                !refVal[10](
                  data.changeMessage,
                  (dataPath || '') + '.changeMessage',
                  data,
                  'changeMessage',
                  rootData,
                )
              ) {
                if (vErrors === null) vErrors = refVal[10].errors;
                else vErrors = vErrors.concat(refVal[10].errors);
                errors = vErrors.length;
              } else {
              }
              if (errors === errs_1) {
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              if (data.key === undefined) {
                valid1 = false;
                validate.errors = [
                  {
                    keyword: 'required',
                    dataPath: (dataPath || '') + '',
                    schemaPath: '#/required',
                    params: {
                      missingProperty: 'key',
                    },
                    message: "should have required property 'key'",
                  },
                ];
                return false;
              } else {
                var errs_1 = errors;
                if (
                  !refVal[3](
                    data.key,
                    (dataPath || '') + '.key',
                    data,
                    'key',
                    rootData,
                  )
                ) {
                  if (vErrors === null) vErrors = refVal[3].errors;
                  else vErrors = vErrors.concat(refVal[3].errors);
                  errors = vErrors.length;
                } else {
                }
                if (errors === errs_1) {
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                if (data.label === undefined) {
                  valid1 = true;
                } else {
                  var errs_1 = errors;
                  if (
                    !refVal[10](
                      data.label,
                      (dataPath || '') + '.label',
                      data,
                      'label',
                      rootData,
                    )
                  ) {
                    if (vErrors === null) vErrors = refVal[10].errors;
                    else vErrors = vErrors.concat(refVal[10].errors);
                    errors = vErrors.length;
                  } else {
                  }
                  if (errors === errs_1) {
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  var data1 = data.textAlignment;
                  if (data1 === undefined) {
                    valid1 = true;
                  } else {
                    var errs_1 = errors;
                    var errs_2 = errors;
                    if (
                      data1 &&
                      typeof data1 === 'object' &&
                      !Array.isArray(data1)
                    ) {
                      if (true) {
                        var errs__2 = errors;
                        var valid3 = true;
                        var data2 = data1.type;
                        if (data2 === undefined) {
                          valid3 = false;
                          validate.errors = [
                            {
                              keyword: 'required',
                              dataPath: (dataPath || '') + '.textAlignment',
                              schemaPath:
                                '#/definitions/textAlignmentProperty/required',
                              params: {
                                missingProperty: 'type',
                              },
                              message: "should have required property 'type'",
                            },
                          ];
                          return false;
                        } else {
                          var errs_3 = errors;
                          if (typeof data2 === 'string') {
                            if (!pattern0.test(data2)) {
                              validate.errors = [
                                {
                                  keyword: 'pattern',
                                  dataPath:
                                    (dataPath || '') + '.textAlignment.type',
                                  schemaPath:
                                    '#/definitions/textAlignmentProperty/properties/type/pattern',
                                  params: {
                                    pattern: 'string',
                                  },
                                  message: 'should match pattern "string"',
                                },
                              ];
                              return false;
                            } else {
                            }
                          } else {
                            validate.errors = [
                              {
                                keyword: 'type',
                                dataPath:
                                  (dataPath || '') + '.textAlignment.type',
                                schemaPath:
                                  '#/definitions/textAlignmentProperty/properties/type/type',
                                params: {
                                  type: 'string',
                                },
                                message: 'should be string',
                              },
                            ];
                            return false;
                          }
                          if (errors === errs_3) {
                          }
                          var valid3 = errors === errs_3;
                        }
                        if (valid3) {
                          var data2 = data1.value;
                          if (data2 === undefined) {
                            valid3 = false;
                            validate.errors = [
                              {
                                keyword: 'required',
                                dataPath: (dataPath || '') + '.textAlignment',
                                schemaPath:
                                  '#/definitions/textAlignmentProperty/required',
                                params: {
                                  missingProperty: 'value',
                                },
                                message:
                                  "should have required property 'value'",
                              },
                            ];
                            return false;
                          } else {
                            var errs_3 = errors;
                            if (typeof data2 !== 'string') {
                              validate.errors = [
                                {
                                  keyword: 'type',
                                  dataPath:
                                    (dataPath || '') + '.textAlignment.value',
                                  schemaPath:
                                    '#/definitions/textAlignmentProperty/properties/value/type',
                                  params: {
                                    type: 'string',
                                  },
                                  message: 'should be string',
                                },
                              ];
                              return false;
                            }
                            var schema3 = refVal19.properties.value.enum;
                            var valid3;
                            valid3 = false;
                            for (var i3 = 0; i3 < schema3.length; i3++)
                              if (equal(data2, schema3[i3])) {
                                valid3 = true;
                                break;
                              }
                            if (!valid3) {
                              validate.errors = [
                                {
                                  keyword: 'enum',
                                  dataPath:
                                    (dataPath || '') + '.textAlignment.value',
                                  schemaPath:
                                    '#/definitions/textAlignmentProperty/properties/value/enum',
                                  params: {
                                    allowedValues: schema3,
                                  },
                                  message:
                                    'should be equal to one of the allowed values',
                                },
                              ];
                              return false;
                            } else {
                            }
                            if (errors === errs_3) {
                            }
                            var valid3 = errors === errs_3;
                          }
                          if (valid3) {
                          }
                        }
                        if (errs__2 == errors) {
                        }
                      }
                    } else {
                      validate.errors = [
                        {
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.textAlignment',
                          schemaPath:
                            '#/definitions/textAlignmentProperty/type',
                          params: {
                            type: 'object',
                          },
                          message: 'should be object',
                        },
                      ];
                      return false;
                    }
                    if (errors === errs_2) {
                    }
                    var valid2 = errors === errs_2;
                    if (valid2) {
                    }
                    if (errors === errs_1) {
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    var data1 = data.value;
                    if (data1 === undefined) {
                      valid1 = false;
                      validate.errors = [
                        {
                          keyword: 'required',
                          dataPath: (dataPath || '') + '',
                          schemaPath: '#/required',
                          params: {
                            missingProperty: 'value',
                          },
                          message: "should have required property 'value'",
                        },
                      ];
                      return false;
                    } else {
                      var errs_1 = errors;
                      var errs_2 = errors;
                      if (
                        !data1 ||
                        typeof data1 !== 'object' ||
                        Array.isArray(data1)
                      ) {
                        validate.errors = [
                          {
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.value',
                            schemaPath: '#/definitions/dateProperty/type',
                            params: {
                              type: 'object',
                            },
                            message: 'should be object',
                          },
                        ];
                        return false;
                      }
                      var errs__2 = errors,
                        prevValid2 = false,
                        valid2 = false,
                        passingSchemas2 = null;
                      var errs_3 = errors;
                      if (
                        data1 &&
                        typeof data1 === 'object' &&
                        !Array.isArray(data1)
                      ) {
                        if (true) {
                          var errs__3 = errors;
                          var valid4 = true;
                          var data2 = data1.type;
                          if (data2 === undefined) {
                            valid4 = false;
                            var err = {
                              keyword: 'required',
                              dataPath: (dataPath || '') + '.value',
                              schemaPath:
                                '#/definitions/dateProperty/oneOf/0/required',
                              params: {
                                missingProperty: 'type',
                              },
                              message: "should have required property 'type'",
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          } else {
                            var errs_4 = errors;
                            if (typeof data2 === 'string') {
                              if (!pattern0.test(data2)) {
                                var err = {
                                  keyword: 'pattern',
                                  dataPath: (dataPath || '') + '.value.type',
                                  schemaPath:
                                    '#/definitions/dateProperty/oneOf/0/properties/type/pattern',
                                  params: {
                                    pattern: 'string',
                                  },
                                  message: 'should match pattern "string"',
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                              } else {
                              }
                            } else {
                              var err = {
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.value.type',
                                schemaPath:
                                  '#/definitions/dateProperty/oneOf/0/properties/type/type',
                                params: {
                                  type: 'string',
                                },
                                message: 'should be string',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            }
                            if (errors === errs_4) {
                            }
                            var valid4 = errors === errs_4;
                          }
                          if (valid4) {
                            var data2 = data1.value;
                            if (data2 === undefined) {
                              valid4 = false;
                              var err = {
                                keyword: 'required',
                                dataPath: (dataPath || '') + '.value',
                                schemaPath:
                                  '#/definitions/dateProperty/oneOf/0/required',
                                params: {
                                  missingProperty: 'value',
                                },
                                message:
                                  "should have required property 'value'",
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            } else {
                              var errs_4 = errors;
                              if (typeof data2 === 'string') {
                                if (!pattern7.test(data2)) {
                                  var err = {
                                    keyword: 'pattern',
                                    dataPath: (dataPath || '') + '.value.value',
                                    schemaPath:
                                      '#/definitions/dateProperty/oneOf/0/properties/value/pattern',
                                    params: {
                                      pattern:
                                        '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
                                    },
                                    message:
                                      'should match pattern "^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$"',
                                  };
                                  if (vErrors === null) vErrors = [err];
                                  else vErrors.push(err);
                                  errors++;
                                } else {
                                }
                              } else {
                                var err = {
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.value.value',
                                  schemaPath:
                                    '#/definitions/dateProperty/oneOf/0/properties/value/type',
                                  params: {
                                    type: 'string',
                                  },
                                  message: 'should be string',
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                              }
                              if (errors === errs_4) {
                              }
                              var valid4 = errors === errs_4;
                            }
                            if (valid4) {
                            }
                          }
                          if (errs__3 == errors) {
                          }
                        }
                      }
                      if (errors === errs_3) {
                      }
                      var valid3 = errors === errs_3;
                      if (valid3) {
                        valid2 = prevValid2 = true;
                        passingSchemas2 = 0;
                      }
                      var errs_3 = errors;
                      if (
                        data1 &&
                        typeof data1 === 'object' &&
                        !Array.isArray(data1)
                      ) {
                        if (true) {
                          var errs__3 = errors;
                          var valid4 = true;
                          var data2 = data1.type;
                          if (data2 === undefined) {
                            valid4 = false;
                            var err = {
                              keyword: 'required',
                              dataPath: (dataPath || '') + '.value',
                              schemaPath:
                                '#/definitions/dateProperty/oneOf/1/required',
                              params: {
                                missingProperty: 'type',
                              },
                              message: "should have required property 'type'",
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          } else {
                            var errs_4 = errors;
                            if (typeof data2 === 'string') {
                              if (!pattern0.test(data2)) {
                                var err = {
                                  keyword: 'pattern',
                                  dataPath: (dataPath || '') + '.value.type',
                                  schemaPath:
                                    '#/definitions/dateProperty/oneOf/1/properties/type/pattern',
                                  params: {
                                    pattern: 'string',
                                  },
                                  message: 'should match pattern "string"',
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                              } else {
                              }
                            } else {
                              var err = {
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.value.type',
                                schemaPath:
                                  '#/definitions/dateProperty/oneOf/1/properties/type/type',
                                params: {
                                  type: 'string',
                                },
                                message: 'should be string',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            }
                            if (errors === errs_4) {
                            }
                            var valid4 = errors === errs_4;
                          }
                          if (valid4) {
                            var data2 = data1.value;
                            if (data2 === undefined) {
                              valid4 = false;
                              var err = {
                                keyword: 'required',
                                dataPath: (dataPath || '') + '.value',
                                schemaPath:
                                  '#/definitions/dateProperty/oneOf/1/required',
                                params: {
                                  missingProperty: 'value',
                                },
                                message:
                                  "should have required property 'value'",
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            } else {
                              var errs_4 = errors;
                              if (typeof data2 === 'string') {
                                if (!pattern4.test(data2)) {
                                  var err = {
                                    keyword: 'pattern',
                                    dataPath: (dataPath || '') + '.value.value',
                                    schemaPath:
                                      '#/definitions/dateProperty/oneOf/1/properties/value/pattern',
                                    params: {
                                      pattern: '^#\\{.+\\}$',
                                    },
                                    message:
                                      'should match pattern "^#\\{.+\\}$"',
                                  };
                                  if (vErrors === null) vErrors = [err];
                                  else vErrors.push(err);
                                  errors++;
                                } else {
                                }
                              } else {
                                var err = {
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.value.value',
                                  schemaPath:
                                    '#/definitions/dateProperty/oneOf/1/properties/value/type',
                                  params: {
                                    type: 'string',
                                  },
                                  message: 'should be string',
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                              }
                              if (errors === errs_4) {
                              }
                              var valid4 = errors === errs_4;
                            }
                            if (valid4) {
                              var data2 = data1.fallback;
                              if (data2 === undefined) {
                                valid4 = false;
                                var err = {
                                  keyword: 'required',
                                  dataPath: (dataPath || '') + '.value',
                                  schemaPath:
                                    '#/definitions/dateProperty/oneOf/1/required',
                                  params: {
                                    missingProperty: 'fallback',
                                  },
                                  message:
                                    "should have required property 'fallback'",
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                              } else {
                                var errs_4 = errors;
                                if (typeof data2 === 'string') {
                                  if (!pattern7.test(data2)) {
                                    var err = {
                                      keyword: 'pattern',
                                      dataPath:
                                        (dataPath || '') + '.value.fallback',
                                      schemaPath:
                                        '#/definitions/dateProperty/oneOf/1/properties/fallback/pattern',
                                      params: {
                                        pattern:
                                          '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
                                      },
                                      message:
                                        'should match pattern "^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$"',
                                    };
                                    if (vErrors === null) vErrors = [err];
                                    else vErrors.push(err);
                                    errors++;
                                  } else {
                                  }
                                } else {
                                  var err = {
                                    keyword: 'type',
                                    dataPath:
                                      (dataPath || '') + '.value.fallback',
                                    schemaPath:
                                      '#/definitions/dateProperty/oneOf/1/properties/fallback/type',
                                    params: {
                                      type: 'string',
                                    },
                                    message: 'should be string',
                                  };
                                  if (vErrors === null) vErrors = [err];
                                  else vErrors.push(err);
                                  errors++;
                                }
                                if (errors === errs_4) {
                                }
                                var valid4 = errors === errs_4;
                              }
                              if (valid4) {
                              }
                            }
                          }
                          if (errs__3 == errors) {
                          }
                        }
                      }
                      if (errors === errs_3) {
                      }
                      var valid3 = errors === errs_3;
                      if (valid3 && prevValid2) {
                        valid2 = false;
                        passingSchemas2 = [passingSchemas2, 1];
                      } else {
                        if (valid3) {
                          valid2 = prevValid2 = true;
                          passingSchemas2 = 1;
                        }
                      }
                      if (!valid2) {
                        var err = {
                          keyword: 'oneOf',
                          dataPath: (dataPath || '') + '.value',
                          schemaPath: '#/definitions/dateProperty/oneOf',
                          params: {
                            passingSchemas: passingSchemas2,
                          },
                          message: 'should match exactly one schema in oneOf',
                        };
                        if (vErrors === null) vErrors = [err];
                        else vErrors.push(err);
                        errors++;
                        validate.errors = vErrors;
                        return false;
                      } else {
                        errors = errs__2;
                        if (vErrors !== null) {
                          if (errs__2) vErrors.length = errs__2;
                          else vErrors = null;
                        }
                      }
                      if (errors === errs_2) {
                      }
                      var valid2 = errors === errs_2;
                      if (valid2) {
                      }
                      if (errors === errs_1) {
                      }
                      var valid1 = errors === errs_1;
                    }
                    if (valid1) {
                      var data1 = data.dateStyle;
                      if (data1 === undefined) {
                        valid1 = false;
                        validate.errors = [
                          {
                            keyword: 'required',
                            dataPath: (dataPath || '') + '',
                            schemaPath: '#/required',
                            params: {
                              missingProperty: 'dateStyle',
                            },
                            message:
                              "should have required property 'dateStyle'",
                          },
                        ];
                        return false;
                      } else {
                        var errs_1 = errors;
                        var errs_2 = errors;
                        if (
                          data1 &&
                          typeof data1 === 'object' &&
                          !Array.isArray(data1)
                        ) {
                          if (true) {
                            var errs__2 = errors;
                            var valid3 = true;
                            var data2 = data1.type;
                            if (data2 === undefined) {
                              valid3 = false;
                              validate.errors = [
                                {
                                  keyword: 'required',
                                  dataPath: (dataPath || '') + '.dateStyle',
                                  schemaPath:
                                    '#/definitions/dateStyleProperty/required',
                                  params: {
                                    missingProperty: 'type',
                                  },
                                  message:
                                    "should have required property 'type'",
                                },
                              ];
                              return false;
                            } else {
                              var errs_3 = errors;
                              if (typeof data2 === 'string') {
                                if (!pattern0.test(data2)) {
                                  validate.errors = [
                                    {
                                      keyword: 'pattern',
                                      dataPath:
                                        (dataPath || '') + '.dateStyle.type',
                                      schemaPath:
                                        '#/definitions/dateStyleProperty/properties/type/pattern',
                                      params: {
                                        pattern: 'string',
                                      },
                                      message: 'should match pattern "string"',
                                    },
                                  ];
                                  return false;
                                } else {
                                }
                              } else {
                                validate.errors = [
                                  {
                                    keyword: 'type',
                                    dataPath:
                                      (dataPath || '') + '.dateStyle.type',
                                    schemaPath:
                                      '#/definitions/dateStyleProperty/properties/type/type',
                                    params: {
                                      type: 'string',
                                    },
                                    message: 'should be string',
                                  },
                                ];
                                return false;
                              }
                              if (errors === errs_3) {
                              }
                              var valid3 = errors === errs_3;
                            }
                            if (valid3) {
                              var data2 = data1.value;
                              if (data2 === undefined) {
                                valid3 = false;
                                validate.errors = [
                                  {
                                    keyword: 'required',
                                    dataPath: (dataPath || '') + '.dateStyle',
                                    schemaPath:
                                      '#/definitions/dateStyleProperty/required',
                                    params: {
                                      missingProperty: 'value',
                                    },
                                    message:
                                      "should have required property 'value'",
                                  },
                                ];
                                return false;
                              } else {
                                var errs_3 = errors;
                                if (typeof data2 !== 'string') {
                                  validate.errors = [
                                    {
                                      keyword: 'type',
                                      dataPath:
                                        (dataPath || '') + '.dateStyle.value',
                                      schemaPath:
                                        '#/definitions/dateStyleProperty/properties/value/type',
                                      params: {
                                        type: 'string',
                                      },
                                      message: 'should be string',
                                    },
                                  ];
                                  return false;
                                }
                                var schema3 = refVal20.properties.value.enum;
                                var valid3;
                                valid3 = false;
                                for (var i3 = 0; i3 < schema3.length; i3++)
                                  if (equal(data2, schema3[i3])) {
                                    valid3 = true;
                                    break;
                                  }
                                if (!valid3) {
                                  validate.errors = [
                                    {
                                      keyword: 'enum',
                                      dataPath:
                                        (dataPath || '') + '.dateStyle.value',
                                      schemaPath:
                                        '#/definitions/dateStyleProperty/properties/value/enum',
                                      params: {
                                        allowedValues: schema3,
                                      },
                                      message:
                                        'should be equal to one of the allowed values',
                                    },
                                  ];
                                  return false;
                                } else {
                                }
                                if (errors === errs_3) {
                                }
                                var valid3 = errors === errs_3;
                              }
                              if (valid3) {
                              }
                            }
                            if (errs__2 == errors) {
                            }
                          }
                        } else {
                          validate.errors = [
                            {
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.dateStyle',
                              schemaPath:
                                '#/definitions/dateStyleProperty/type',
                              params: {
                                type: 'object',
                              },
                              message: 'should be object',
                            },
                          ];
                          return false;
                        }
                        if (errors === errs_2) {
                        }
                        var valid2 = errors === errs_2;
                        if (valid2) {
                        }
                        if (errors === errs_1) {
                        }
                        var valid1 = errors === errs_1;
                      }
                      if (valid1) {
                        var data1 = data.ignoresTimeZone;
                        if (data1 === undefined) {
                          valid1 = true;
                        } else {
                          var errs_1 = errors;
                          var errs_2 = errors;
                          if (
                            !data1 ||
                            typeof data1 !== 'object' ||
                            Array.isArray(data1)
                          ) {
                            validate.errors = [
                              {
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.ignoresTimeZone',
                                schemaPath:
                                  '#/definitions/booleanProperty/type',
                                params: {
                                  type: 'object',
                                },
                                message: 'should be object',
                              },
                            ];
                            return false;
                          }
                          var errs__2 = errors,
                            prevValid2 = false,
                            valid2 = false,
                            passingSchemas2 = null;
                          var errs_3 = errors;
                          if (
                            data1 &&
                            typeof data1 === 'object' &&
                            !Array.isArray(data1)
                          ) {
                            if (true) {
                              var errs__3 = errors;
                              var valid4 = true;
                              var data2 = data1.type;
                              if (data2 === undefined) {
                                valid4 = false;
                                var err = {
                                  keyword: 'required',
                                  dataPath:
                                    (dataPath || '') + '.ignoresTimeZone',
                                  schemaPath:
                                    '#/definitions/booleanProperty/oneOf/0/required',
                                  params: {
                                    missingProperty: 'type',
                                  },
                                  message:
                                    "should have required property 'type'",
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                              } else {
                                var errs_4 = errors;
                                if (typeof data2 === 'string') {
                                  if (!pattern8.test(data2)) {
                                    var err = {
                                      keyword: 'pattern',
                                      dataPath:
                                        (dataPath || '') +
                                        '.ignoresTimeZone.type',
                                      schemaPath:
                                        '#/definitions/booleanProperty/oneOf/0/properties/type/pattern',
                                      params: {
                                        pattern: 'boolean',
                                      },
                                      message: 'should match pattern "boolean"',
                                    };
                                    if (vErrors === null) vErrors = [err];
                                    else vErrors.push(err);
                                    errors++;
                                  } else {
                                  }
                                } else {
                                  var err = {
                                    keyword: 'type',
                                    dataPath:
                                      (dataPath || '') +
                                      '.ignoresTimeZone.type',
                                    schemaPath:
                                      '#/definitions/booleanProperty/oneOf/0/properties/type/type',
                                    params: {
                                      type: 'string',
                                    },
                                    message: 'should be string',
                                  };
                                  if (vErrors === null) vErrors = [err];
                                  else vErrors.push(err);
                                  errors++;
                                }
                                if (errors === errs_4) {
                                }
                                var valid4 = errors === errs_4;
                              }
                              if (valid4) {
                                if (data1.value === undefined) {
                                  valid4 = false;
                                  var err = {
                                    keyword: 'required',
                                    dataPath:
                                      (dataPath || '') + '.ignoresTimeZone',
                                    schemaPath:
                                      '#/definitions/booleanProperty/oneOf/0/required',
                                    params: {
                                      missingProperty: 'value',
                                    },
                                    message:
                                      "should have required property 'value'",
                                  };
                                  if (vErrors === null) vErrors = [err];
                                  else vErrors.push(err);
                                  errors++;
                                } else {
                                  var errs_4 = errors;
                                  if (typeof data1.value !== 'boolean') {
                                    var err = {
                                      keyword: 'type',
                                      dataPath:
                                        (dataPath || '') +
                                        '.ignoresTimeZone.value',
                                      schemaPath:
                                        '#/definitions/booleanProperty/oneOf/0/properties/value/type',
                                      params: {
                                        type: 'boolean',
                                      },
                                      message: 'should be boolean',
                                    };
                                    if (vErrors === null) vErrors = [err];
                                    else vErrors.push(err);
                                    errors++;
                                  }
                                  var valid4 = errors === errs_4;
                                }
                                if (valid4) {
                                }
                              }
                              if (errs__3 == errors) {
                              }
                            }
                          }
                          if (errors === errs_3) {
                          }
                          var valid3 = errors === errs_3;
                          if (valid3) {
                            valid2 = prevValid2 = true;
                            passingSchemas2 = 0;
                          }
                          var errs_3 = errors;
                          if (
                            data1 &&
                            typeof data1 === 'object' &&
                            !Array.isArray(data1)
                          ) {
                            if (true) {
                              var errs__3 = errors;
                              var valid4 = true;
                              var data2 = data1.type;
                              if (data2 === undefined) {
                                valid4 = false;
                                var err = {
                                  keyword: 'required',
                                  dataPath:
                                    (dataPath || '') + '.ignoresTimeZone',
                                  schemaPath:
                                    '#/definitions/booleanProperty/oneOf/1/required',
                                  params: {
                                    missingProperty: 'type',
                                  },
                                  message:
                                    "should have required property 'type'",
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                              } else {
                                var errs_4 = errors;
                                if (typeof data2 === 'string') {
                                  if (!pattern8.test(data2)) {
                                    var err = {
                                      keyword: 'pattern',
                                      dataPath:
                                        (dataPath || '') +
                                        '.ignoresTimeZone.type',
                                      schemaPath:
                                        '#/definitions/booleanProperty/oneOf/1/properties/type/pattern',
                                      params: {
                                        pattern: 'boolean',
                                      },
                                      message: 'should match pattern "boolean"',
                                    };
                                    if (vErrors === null) vErrors = [err];
                                    else vErrors.push(err);
                                    errors++;
                                  } else {
                                  }
                                } else {
                                  var err = {
                                    keyword: 'type',
                                    dataPath:
                                      (dataPath || '') +
                                      '.ignoresTimeZone.type',
                                    schemaPath:
                                      '#/definitions/booleanProperty/oneOf/1/properties/type/type',
                                    params: {
                                      type: 'string',
                                    },
                                    message: 'should be string',
                                  };
                                  if (vErrors === null) vErrors = [err];
                                  else vErrors.push(err);
                                  errors++;
                                }
                                if (errors === errs_4) {
                                }
                                var valid4 = errors === errs_4;
                              }
                              if (valid4) {
                                var data2 = data1.value;
                                if (data2 === undefined) {
                                  valid4 = false;
                                  var err = {
                                    keyword: 'required',
                                    dataPath:
                                      (dataPath || '') + '.ignoresTimeZone',
                                    schemaPath:
                                      '#/definitions/booleanProperty/oneOf/1/required',
                                    params: {
                                      missingProperty: 'value',
                                    },
                                    message:
                                      "should have required property 'value'",
                                  };
                                  if (vErrors === null) vErrors = [err];
                                  else vErrors.push(err);
                                  errors++;
                                } else {
                                  var errs_4 = errors;
                                  if (typeof data2 === 'string') {
                                    if (!pattern4.test(data2)) {
                                      var err = {
                                        keyword: 'pattern',
                                        dataPath:
                                          (dataPath || '') +
                                          '.ignoresTimeZone.value',
                                        schemaPath:
                                          '#/definitions/booleanProperty/oneOf/1/properties/value/pattern',
                                        params: {
                                          pattern: '^#\\{.+\\}$',
                                        },
                                        message:
                                          'should match pattern "^#\\{.+\\}$"',
                                      };
                                      if (vErrors === null) vErrors = [err];
                                      else vErrors.push(err);
                                      errors++;
                                    } else {
                                    }
                                  } else {
                                    var err = {
                                      keyword: 'type',
                                      dataPath:
                                        (dataPath || '') +
                                        '.ignoresTimeZone.value',
                                      schemaPath:
                                        '#/definitions/booleanProperty/oneOf/1/properties/value/type',
                                      params: {
                                        type: 'string',
                                      },
                                      message: 'should be string',
                                    };
                                    if (vErrors === null) vErrors = [err];
                                    else vErrors.push(err);
                                    errors++;
                                  }
                                  if (errors === errs_4) {
                                  }
                                  var valid4 = errors === errs_4;
                                }
                                if (valid4) {
                                  if (data1.fallback === undefined) {
                                    valid4 = false;
                                    var err = {
                                      keyword: 'required',
                                      dataPath:
                                        (dataPath || '') + '.ignoresTimeZone',
                                      schemaPath:
                                        '#/definitions/booleanProperty/oneOf/1/required',
                                      params: {
                                        missingProperty: 'fallback',
                                      },
                                      message:
                                        "should have required property 'fallback'",
                                    };
                                    if (vErrors === null) vErrors = [err];
                                    else vErrors.push(err);
                                    errors++;
                                  } else {
                                    var errs_4 = errors;
                                    if (typeof data1.fallback !== 'boolean') {
                                      var err = {
                                        keyword: 'type',
                                        dataPath:
                                          (dataPath || '') +
                                          '.ignoresTimeZone.fallback',
                                        schemaPath:
                                          '#/definitions/booleanProperty/oneOf/1/properties/fallback/type',
                                        params: {
                                          type: 'boolean',
                                        },
                                        message: 'should be boolean',
                                      };
                                      if (vErrors === null) vErrors = [err];
                                      else vErrors.push(err);
                                      errors++;
                                    }
                                    var valid4 = errors === errs_4;
                                  }
                                  if (valid4) {
                                  }
                                }
                              }
                              if (errs__3 == errors) {
                              }
                            }
                          }
                          if (errors === errs_3) {
                          }
                          var valid3 = errors === errs_3;
                          if (valid3 && prevValid2) {
                            valid2 = false;
                            passingSchemas2 = [passingSchemas2, 1];
                          } else {
                            if (valid3) {
                              valid2 = prevValid2 = true;
                              passingSchemas2 = 1;
                            }
                          }
                          if (!valid2) {
                            var err = {
                              keyword: 'oneOf',
                              dataPath: (dataPath || '') + '.ignoresTimeZone',
                              schemaPath: '#/definitions/booleanProperty/oneOf',
                              params: {
                                passingSchemas: passingSchemas2,
                              },
                              message:
                                'should match exactly one schema in oneOf',
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                            validate.errors = vErrors;
                            return false;
                          } else {
                            errors = errs__2;
                            if (vErrors !== null) {
                              if (errs__2) vErrors.length = errs__2;
                              else vErrors = null;
                            }
                          }
                          if (errors === errs_2) {
                          }
                          var valid2 = errors === errs_2;
                          if (valid2) {
                          }
                          if (errors === errs_1) {
                          }
                          var valid1 = errors === errs_1;
                        }
                        if (valid1) {
                          var data1 = data.isRelative;
                          if (data1 === undefined) {
                            valid1 = true;
                          } else {
                            var errs_1 = errors;
                            var errs_2 = errors;
                            if (
                              !data1 ||
                              typeof data1 !== 'object' ||
                              Array.isArray(data1)
                            ) {
                              validate.errors = [
                                {
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.isRelative',
                                  schemaPath:
                                    '#/definitions/booleanProperty/type',
                                  params: {
                                    type: 'object',
                                  },
                                  message: 'should be object',
                                },
                              ];
                              return false;
                            }
                            var errs__2 = errors,
                              prevValid2 = false,
                              valid2 = false,
                              passingSchemas2 = null;
                            var errs_3 = errors;
                            if (
                              data1 &&
                              typeof data1 === 'object' &&
                              !Array.isArray(data1)
                            ) {
                              if (true) {
                                var errs__3 = errors;
                                var valid4 = true;
                                var data2 = data1.type;
                                if (data2 === undefined) {
                                  valid4 = false;
                                  var err = {
                                    keyword: 'required',
                                    dataPath: (dataPath || '') + '.isRelative',
                                    schemaPath:
                                      '#/definitions/booleanProperty/oneOf/0/required',
                                    params: {
                                      missingProperty: 'type',
                                    },
                                    message:
                                      "should have required property 'type'",
                                  };
                                  if (vErrors === null) vErrors = [err];
                                  else vErrors.push(err);
                                  errors++;
                                } else {
                                  var errs_4 = errors;
                                  if (typeof data2 === 'string') {
                                    if (!pattern8.test(data2)) {
                                      var err = {
                                        keyword: 'pattern',
                                        dataPath:
                                          (dataPath || '') + '.isRelative.type',
                                        schemaPath:
                                          '#/definitions/booleanProperty/oneOf/0/properties/type/pattern',
                                        params: {
                                          pattern: 'boolean',
                                        },
                                        message:
                                          'should match pattern "boolean"',
                                      };
                                      if (vErrors === null) vErrors = [err];
                                      else vErrors.push(err);
                                      errors++;
                                    } else {
                                    }
                                  } else {
                                    var err = {
                                      keyword: 'type',
                                      dataPath:
                                        (dataPath || '') + '.isRelative.type',
                                      schemaPath:
                                        '#/definitions/booleanProperty/oneOf/0/properties/type/type',
                                      params: {
                                        type: 'string',
                                      },
                                      message: 'should be string',
                                    };
                                    if (vErrors === null) vErrors = [err];
                                    else vErrors.push(err);
                                    errors++;
                                  }
                                  if (errors === errs_4) {
                                  }
                                  var valid4 = errors === errs_4;
                                }
                                if (valid4) {
                                  if (data1.value === undefined) {
                                    valid4 = false;
                                    var err = {
                                      keyword: 'required',
                                      dataPath:
                                        (dataPath || '') + '.isRelative',
                                      schemaPath:
                                        '#/definitions/booleanProperty/oneOf/0/required',
                                      params: {
                                        missingProperty: 'value',
                                      },
                                      message:
                                        "should have required property 'value'",
                                    };
                                    if (vErrors === null) vErrors = [err];
                                    else vErrors.push(err);
                                    errors++;
                                  } else {
                                    var errs_4 = errors;
                                    if (typeof data1.value !== 'boolean') {
                                      var err = {
                                        keyword: 'type',
                                        dataPath:
                                          (dataPath || '') +
                                          '.isRelative.value',
                                        schemaPath:
                                          '#/definitions/booleanProperty/oneOf/0/properties/value/type',
                                        params: {
                                          type: 'boolean',
                                        },
                                        message: 'should be boolean',
                                      };
                                      if (vErrors === null) vErrors = [err];
                                      else vErrors.push(err);
                                      errors++;
                                    }
                                    var valid4 = errors === errs_4;
                                  }
                                  if (valid4) {
                                  }
                                }
                                if (errs__3 == errors) {
                                }
                              }
                            }
                            if (errors === errs_3) {
                            }
                            var valid3 = errors === errs_3;
                            if (valid3) {
                              valid2 = prevValid2 = true;
                              passingSchemas2 = 0;
                            }
                            var errs_3 = errors;
                            if (
                              data1 &&
                              typeof data1 === 'object' &&
                              !Array.isArray(data1)
                            ) {
                              if (true) {
                                var errs__3 = errors;
                                var valid4 = true;
                                var data2 = data1.type;
                                if (data2 === undefined) {
                                  valid4 = false;
                                  var err = {
                                    keyword: 'required',
                                    dataPath: (dataPath || '') + '.isRelative',
                                    schemaPath:
                                      '#/definitions/booleanProperty/oneOf/1/required',
                                    params: {
                                      missingProperty: 'type',
                                    },
                                    message:
                                      "should have required property 'type'",
                                  };
                                  if (vErrors === null) vErrors = [err];
                                  else vErrors.push(err);
                                  errors++;
                                } else {
                                  var errs_4 = errors;
                                  if (typeof data2 === 'string') {
                                    if (!pattern8.test(data2)) {
                                      var err = {
                                        keyword: 'pattern',
                                        dataPath:
                                          (dataPath || '') + '.isRelative.type',
                                        schemaPath:
                                          '#/definitions/booleanProperty/oneOf/1/properties/type/pattern',
                                        params: {
                                          pattern: 'boolean',
                                        },
                                        message:
                                          'should match pattern "boolean"',
                                      };
                                      if (vErrors === null) vErrors = [err];
                                      else vErrors.push(err);
                                      errors++;
                                    } else {
                                    }
                                  } else {
                                    var err = {
                                      keyword: 'type',
                                      dataPath:
                                        (dataPath || '') + '.isRelative.type',
                                      schemaPath:
                                        '#/definitions/booleanProperty/oneOf/1/properties/type/type',
                                      params: {
                                        type: 'string',
                                      },
                                      message: 'should be string',
                                    };
                                    if (vErrors === null) vErrors = [err];
                                    else vErrors.push(err);
                                    errors++;
                                  }
                                  if (errors === errs_4) {
                                  }
                                  var valid4 = errors === errs_4;
                                }
                                if (valid4) {
                                  var data2 = data1.value;
                                  if (data2 === undefined) {
                                    valid4 = false;
                                    var err = {
                                      keyword: 'required',
                                      dataPath:
                                        (dataPath || '') + '.isRelative',
                                      schemaPath:
                                        '#/definitions/booleanProperty/oneOf/1/required',
                                      params: {
                                        missingProperty: 'value',
                                      },
                                      message:
                                        "should have required property 'value'",
                                    };
                                    if (vErrors === null) vErrors = [err];
                                    else vErrors.push(err);
                                    errors++;
                                  } else {
                                    var errs_4 = errors;
                                    if (typeof data2 === 'string') {
                                      if (!pattern4.test(data2)) {
                                        var err = {
                                          keyword: 'pattern',
                                          dataPath:
                                            (dataPath || '') +
                                            '.isRelative.value',
                                          schemaPath:
                                            '#/definitions/booleanProperty/oneOf/1/properties/value/pattern',
                                          params: {
                                            pattern: '^#\\{.+\\}$',
                                          },
                                          message:
                                            'should match pattern "^#\\{.+\\}$"',
                                        };
                                        if (vErrors === null) vErrors = [err];
                                        else vErrors.push(err);
                                        errors++;
                                      } else {
                                      }
                                    } else {
                                      var err = {
                                        keyword: 'type',
                                        dataPath:
                                          (dataPath || '') +
                                          '.isRelative.value',
                                        schemaPath:
                                          '#/definitions/booleanProperty/oneOf/1/properties/value/type',
                                        params: {
                                          type: 'string',
                                        },
                                        message: 'should be string',
                                      };
                                      if (vErrors === null) vErrors = [err];
                                      else vErrors.push(err);
                                      errors++;
                                    }
                                    if (errors === errs_4) {
                                    }
                                    var valid4 = errors === errs_4;
                                  }
                                  if (valid4) {
                                    if (data1.fallback === undefined) {
                                      valid4 = false;
                                      var err = {
                                        keyword: 'required',
                                        dataPath:
                                          (dataPath || '') + '.isRelative',
                                        schemaPath:
                                          '#/definitions/booleanProperty/oneOf/1/required',
                                        params: {
                                          missingProperty: 'fallback',
                                        },
                                        message:
                                          "should have required property 'fallback'",
                                      };
                                      if (vErrors === null) vErrors = [err];
                                      else vErrors.push(err);
                                      errors++;
                                    } else {
                                      var errs_4 = errors;
                                      if (typeof data1.fallback !== 'boolean') {
                                        var err = {
                                          keyword: 'type',
                                          dataPath:
                                            (dataPath || '') +
                                            '.isRelative.fallback',
                                          schemaPath:
                                            '#/definitions/booleanProperty/oneOf/1/properties/fallback/type',
                                          params: {
                                            type: 'boolean',
                                          },
                                          message: 'should be boolean',
                                        };
                                        if (vErrors === null) vErrors = [err];
                                        else vErrors.push(err);
                                        errors++;
                                      }
                                      var valid4 = errors === errs_4;
                                    }
                                    if (valid4) {
                                    }
                                  }
                                }
                                if (errs__3 == errors) {
                                }
                              }
                            }
                            if (errors === errs_3) {
                            }
                            var valid3 = errors === errs_3;
                            if (valid3 && prevValid2) {
                              valid2 = false;
                              passingSchemas2 = [passingSchemas2, 1];
                            } else {
                              if (valid3) {
                                valid2 = prevValid2 = true;
                                passingSchemas2 = 1;
                              }
                            }
                            if (!valid2) {
                              var err = {
                                keyword: 'oneOf',
                                dataPath: (dataPath || '') + '.isRelative',
                                schemaPath:
                                  '#/definitions/booleanProperty/oneOf',
                                params: {
                                  passingSchemas: passingSchemas2,
                                },
                                message:
                                  'should match exactly one schema in oneOf',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                              validate.errors = vErrors;
                              return false;
                            } else {
                              errors = errs__2;
                              if (vErrors !== null) {
                                if (errs__2) vErrors.length = errs__2;
                                else vErrors = null;
                              }
                            }
                            if (errors === errs_2) {
                            }
                            var valid2 = errors === errs_2;
                            if (valid2) {
                            }
                            if (errors === errs_1) {
                            }
                            var valid1 = errors === errs_1;
                          }
                          if (valid1) {
                            var data1 = data.timeStyle;
                            if (data1 === undefined) {
                              valid1 = false;
                              validate.errors = [
                                {
                                  keyword: 'required',
                                  dataPath: (dataPath || '') + '',
                                  schemaPath: '#/required',
                                  params: {
                                    missingProperty: 'timeStyle',
                                  },
                                  message:
                                    "should have required property 'timeStyle'",
                                },
                              ];
                              return false;
                            } else {
                              var errs_1 = errors;
                              var errs_2 = errors;
                              if (
                                data1 &&
                                typeof data1 === 'object' &&
                                !Array.isArray(data1)
                              ) {
                                if (true) {
                                  var errs__2 = errors;
                                  var valid3 = true;
                                  var data2 = data1.type;
                                  if (data2 === undefined) {
                                    valid3 = false;
                                    validate.errors = [
                                      {
                                        keyword: 'required',
                                        dataPath:
                                          (dataPath || '') + '.timeStyle',
                                        schemaPath:
                                          '#/definitions/dateStyleProperty/required',
                                        params: {
                                          missingProperty: 'type',
                                        },
                                        message:
                                          "should have required property 'type'",
                                      },
                                    ];
                                    return false;
                                  } else {
                                    var errs_3 = errors;
                                    if (typeof data2 === 'string') {
                                      if (!pattern0.test(data2)) {
                                        validate.errors = [
                                          {
                                            keyword: 'pattern',
                                            dataPath:
                                              (dataPath || '') +
                                              '.timeStyle.type',
                                            schemaPath:
                                              '#/definitions/dateStyleProperty/properties/type/pattern',
                                            params: {
                                              pattern: 'string',
                                            },
                                            message:
                                              'should match pattern "string"',
                                          },
                                        ];
                                        return false;
                                      } else {
                                      }
                                    } else {
                                      validate.errors = [
                                        {
                                          keyword: 'type',
                                          dataPath:
                                            (dataPath || '') +
                                            '.timeStyle.type',
                                          schemaPath:
                                            '#/definitions/dateStyleProperty/properties/type/type',
                                          params: {
                                            type: 'string',
                                          },
                                          message: 'should be string',
                                        },
                                      ];
                                      return false;
                                    }
                                    if (errors === errs_3) {
                                    }
                                    var valid3 = errors === errs_3;
                                  }
                                  if (valid3) {
                                    var data2 = data1.value;
                                    if (data2 === undefined) {
                                      valid3 = false;
                                      validate.errors = [
                                        {
                                          keyword: 'required',
                                          dataPath:
                                            (dataPath || '') + '.timeStyle',
                                          schemaPath:
                                            '#/definitions/dateStyleProperty/required',
                                          params: {
                                            missingProperty: 'value',
                                          },
                                          message:
                                            "should have required property 'value'",
                                        },
                                      ];
                                      return false;
                                    } else {
                                      var errs_3 = errors;
                                      if (typeof data2 !== 'string') {
                                        validate.errors = [
                                          {
                                            keyword: 'type',
                                            dataPath:
                                              (dataPath || '') +
                                              '.timeStyle.value',
                                            schemaPath:
                                              '#/definitions/dateStyleProperty/properties/value/type',
                                            params: {
                                              type: 'string',
                                            },
                                            message: 'should be string',
                                          },
                                        ];
                                        return false;
                                      }
                                      var schema3 =
                                        refVal[20].properties.value.enum;
                                      var valid3;
                                      valid3 = false;
                                      for (
                                        var i3 = 0;
                                        i3 < schema3.length;
                                        i3++
                                      )
                                        if (equal(data2, schema3[i3])) {
                                          valid3 = true;
                                          break;
                                        }
                                      if (!valid3) {
                                        validate.errors = [
                                          {
                                            keyword: 'enum',
                                            dataPath:
                                              (dataPath || '') +
                                              '.timeStyle.value',
                                            schemaPath:
                                              '#/definitions/dateStyleProperty/properties/value/enum',
                                            params: {
                                              allowedValues: schema3,
                                            },
                                            message:
                                              'should be equal to one of the allowed values',
                                          },
                                        ];
                                        return false;
                                      } else {
                                      }
                                      if (errors === errs_3) {
                                      }
                                      var valid3 = errors === errs_3;
                                    }
                                    if (valid3) {
                                    }
                                  }
                                  if (errs__2 == errors) {
                                  }
                                }
                              } else {
                                validate.errors = [
                                  {
                                    keyword: 'type',
                                    dataPath: (dataPath || '') + '.timeStyle',
                                    schemaPath:
                                      '#/definitions/dateStyleProperty/type',
                                    params: {
                                      type: 'object',
                                    },
                                    message: 'should be object',
                                  },
                                ];
                                return false;
                              }
                              if (errors === errs_2) {
                              }
                              var valid2 = errors === errs_2;
                              if (valid2) {
                              }
                              if (errors === errs_1) {
                              }
                              var valid1 = errors === errs_1;
                            }
                            if (valid1) {
                              if (data.dataDetectorTypes === undefined) {
                                valid1 = true;
                              } else {
                                var errs_1 = errors;
                                if (
                                  !refVal21(
                                    data.dataDetectorTypes,
                                    (dataPath || '') + '.dataDetectorTypes',
                                    data,
                                    'dataDetectorTypes',
                                    rootData,
                                  )
                                ) {
                                  if (vErrors === null)
                                    vErrors = refVal21.errors;
                                  else
                                    vErrors = vErrors.concat(refVal21.errors);
                                  errors = vErrors.length;
                                } else {
                                }
                                if (errors === errs_1) {
                                }
                                var valid1 = errors === errs_1;
                              }
                              if (valid1) {
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          if (errs__0 == errors) {
          }
        }
      } else {
        validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/type',
            params: {
              type: 'object',
            },
            message: 'should be object',
          },
        ];
        return false;
      }
      if (errors === 0) {
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal18.schema = {
    type: 'object',
    properties: {
      changeMessage: {
        $ref: '#/definitions/localizedStringProperty',
      },
      key: {
        $ref: '#/definitions/stringProperty',
      },
      label: {
        $ref: '#/definitions/localizedStringProperty',
      },
      textAlignment: {
        $ref: '#/definitions/textAlignmentProperty',
      },
      value: {
        $ref: '#/definitions/dateProperty',
      },
      dateStyle: {
        $ref: '#/definitions/dateStyleProperty',
      },
      ignoresTimeZone: {
        $ref: '#/definitions/booleanProperty',
      },
      isRelative: {
        $ref: '#/definitions/booleanProperty',
      },
      timeStyle: {
        $ref: '#/definitions/dateStyleProperty',
      },
      dataDetectorTypes: {
        $ref: '#/definitions/dataDetectorTypes',
      },
    },
    additionalProperties: false,
    required: ['key', 'value', 'dateStyle', 'timeStyle'],
  };
  refVal18.errors = null;
  refVal[18] = refVal18;
  var refVal19 = {
    type: 'object',
    properties: {
      type: {
        type: 'string',
        pattern: 'string',
      },
      value: {
        type: 'string',
        enum: [
          'PKTextAlignmentLeft',
          'PKTextAlignmentCenter',
          'PKTextAlignmentRight',
          'PKTextAlignmentNatural',
        ],
      },
    },
    required: ['type', 'value'],
  };
  refVal[19] = refVal19;
  var refVal20 = {
    type: 'object',
    properties: {
      type: {
        type: 'string',
        pattern: 'string',
      },
      value: {
        type: 'string',
        enum: [
          'PKDateStyleNone',
          'PKDateStyleShort',
          'PKDateStyleMedium',
          'PKDateStyleLong',
          'PKDateStyleFull',
        ],
      },
    },
    required: ['type', 'value'],
  };
  refVal[20] = refVal20;
  var refVal21 = (function() {
    var pattern0 = new RegExp('string');
    var pattern1 = new RegExp(
      '#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^}]{4,}\\}',
    );
    var pattern2 = new RegExp(
      '^((?!(#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^\\}]{4,}\\}))[\\s\\S])*$',
    );
    var pattern3 = new RegExp('number');
    var pattern4 = new RegExp('^#\\{.+\\}$');
    var pattern5 = new RegExp('[a-z]{2}');
    var pattern6 = new RegExp(
      '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
    );
    var pattern7 = new RegExp(
      '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
    );
    var pattern8 = new RegExp('boolean');
    return function validate(
      data,
      dataPath,
      parentData,
      parentDataProperty,
      rootData,
    ) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if (data && typeof data === 'object' && !Array.isArray(data)) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          var data1 = data.type;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [
              {
                keyword: 'required',
                dataPath: (dataPath || '') + '',
                schemaPath: '#/required',
                params: {
                  missingProperty: 'type',
                },
                message: "should have required property 'type'",
              },
            ];
            return false;
          } else {
            var errs_1 = errors;
            var errs_2 = errors;
            if (typeof data1 !== 'string') {
              validate.errors = [
                {
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.type',
                  schemaPath: '#/definitions/arrayType/type',
                  params: {
                    type: 'string',
                  },
                  message: 'should be string',
                },
              ];
              return false;
            }
            var schema2 = refVal[1].enum;
            var valid2;
            valid2 = false;
            for (var i2 = 0; i2 < schema2.length; i2++)
              if (equal(data1, schema2[i2])) {
                valid2 = true;
                break;
              }
            if (!valid2) {
              validate.errors = [
                {
                  keyword: 'enum',
                  dataPath: (dataPath || '') + '.type',
                  schemaPath: '#/definitions/arrayType/enum',
                  params: {
                    allowedValues: schema2,
                  },
                  message: 'should be equal to one of the allowed values',
                },
              ];
              return false;
            } else {
            }
            if (errors === errs_2) {
            }
            var valid2 = errors === errs_2;
            if (valid2) {
            }
            if (errors === errs_1) {
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            var data1 = data.items;
            if (data1 === undefined) {
              valid1 = false;
              validate.errors = [
                {
                  keyword: 'required',
                  dataPath: (dataPath || '') + '',
                  schemaPath: '#/required',
                  params: {
                    missingProperty: 'items',
                  },
                  message: "should have required property 'items'",
                },
              ];
              return false;
            } else {
              var errs_1 = errors;
              if (Array.isArray(data1)) {
                var errs__1 = errors;
                var valid1;
                for (var i1 = 0; i1 < data1.length; i1++) {
                  var data2 = data1[i1];
                  var errs_2 = errors;
                  if (
                    data2 &&
                    typeof data2 === 'object' &&
                    !Array.isArray(data2)
                  ) {
                    if (true) {
                      var errs__2 = errors;
                      var valid3 = true;
                      var data3 = data2.type;
                      if (data3 === undefined) {
                        valid3 = false;
                        validate.errors = [
                          {
                            keyword: 'required',
                            dataPath: (dataPath || '') + '.items[' + i1 + ']',
                            schemaPath: '#/properties/items/items/required',
                            params: {
                              missingProperty: 'type',
                            },
                            message: "should have required property 'type'",
                          },
                        ];
                        return false;
                      } else {
                        var errs_3 = errors;
                        if (typeof data3 === 'string') {
                          if (!pattern0.test(data3)) {
                            validate.errors = [
                              {
                                keyword: 'pattern',
                                dataPath:
                                  (dataPath || '') + '.items[' + i1 + '].type',
                                schemaPath:
                                  '#/properties/items/items/properties/type/pattern',
                                params: {
                                  pattern: 'string',
                                },
                                message: 'should match pattern "string"',
                              },
                            ];
                            return false;
                          } else {
                          }
                        } else {
                          validate.errors = [
                            {
                              keyword: 'type',
                              dataPath:
                                (dataPath || '') + '.items[' + i1 + '].type',
                              schemaPath:
                                '#/properties/items/items/properties/type/type',
                              params: {
                                type: 'string',
                              },
                              message: 'should be string',
                            },
                          ];
                          return false;
                        }
                        if (errors === errs_3) {
                        }
                        var valid3 = errors === errs_3;
                      }
                      if (valid3) {
                        var data3 = data2.value;
                        if (data3 === undefined) {
                          valid3 = false;
                          validate.errors = [
                            {
                              keyword: 'required',
                              dataPath: (dataPath || '') + '.items[' + i1 + ']',
                              schemaPath: '#/properties/items/items/required',
                              params: {
                                missingProperty: 'value',
                              },
                              message: "should have required property 'value'",
                            },
                          ];
                          return false;
                        } else {
                          var errs_3 = errors;
                          if (typeof data3 !== 'string') {
                            validate.errors = [
                              {
                                keyword: 'type',
                                dataPath:
                                  (dataPath || '') + '.items[' + i1 + '].value',
                                schemaPath:
                                  '#/properties/items/items/properties/value/type',
                                params: {
                                  type: 'string',
                                },
                                message: 'should be string',
                              },
                            ];
                            return false;
                          }
                          var schema3 =
                            validate.schema.properties.items.items.properties
                              .value.enum;
                          var valid3;
                          valid3 = false;
                          for (var i3 = 0; i3 < schema3.length; i3++)
                            if (equal(data3, schema3[i3])) {
                              valid3 = true;
                              break;
                            }
                          if (!valid3) {
                            validate.errors = [
                              {
                                keyword: 'enum',
                                dataPath:
                                  (dataPath || '') + '.items[' + i1 + '].value',
                                schemaPath:
                                  '#/properties/items/items/properties/value/enum',
                                params: {
                                  allowedValues: schema3,
                                },
                                message:
                                  'should be equal to one of the allowed values',
                              },
                            ];
                            return false;
                          } else {
                          }
                          if (errors === errs_3) {
                          }
                          var valid3 = errors === errs_3;
                        }
                        if (valid3) {
                        }
                      }
                      if (errs__2 == errors) {
                      }
                    }
                  } else {
                    validate.errors = [
                      {
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.items[' + i1 + ']',
                        schemaPath: '#/properties/items/items/type',
                        params: {
                          type: 'object',
                        },
                        message: 'should be object',
                      },
                    ];
                    return false;
                  }
                  if (errors === errs_2) {
                  }
                  var valid2 = errors === errs_2;
                  if (!valid2) break;
                }
                if (errs__1 == errors) {
                }
              } else {
                validate.errors = [
                  {
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.items',
                    schemaPath: '#/properties/items/type',
                    params: {
                      type: 'array',
                    },
                    message: 'should be array',
                  },
                ];
                return false;
              }
              if (errors === errs_1) {
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
            }
          }
          if (errs__0 == errors) {
          }
        }
      } else {
        validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/type',
            params: {
              type: 'object',
            },
            message: 'should be object',
          },
        ];
        return false;
      }
      if (errors === 0) {
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal21.schema = {
    type: 'object',
    properties: {
      type: {
        $ref: '#/definitions/arrayType',
      },
      items: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            type: {
              type: 'string',
              pattern: 'string',
            },
            value: {
              type: 'string',
              enum: [
                'PKDataDetectorTypePhoneNumber',
                'PKDataDetectorTypeLink',
                'PKDataDetectorTypeAddress',
                'PKDataDetectorTypeCalendarEvent',
              ],
            },
          },
          required: ['type', 'value'],
        },
      },
    },
    required: ['type', 'items'],
  };
  refVal21.errors = null;
  refVal[21] = refVal21;
  var refVal22 = (function() {
    var pattern0 = new RegExp('string');
    var pattern1 = new RegExp(
      '#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^}]{4,}\\}',
    );
    var pattern2 = new RegExp(
      '^((?!(#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^\\}]{4,}\\}))[\\s\\S])*$',
    );
    var pattern3 = new RegExp('number');
    var pattern4 = new RegExp('^#\\{.+\\}$');
    var pattern5 = new RegExp('[a-z]{2}');
    var pattern6 = new RegExp(
      '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
    );
    var pattern7 = new RegExp(
      '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
    );
    var pattern8 = new RegExp('boolean');
    return function validate(
      data,
      dataPath,
      parentData,
      parentDataProperty,
      rootData,
    ) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if (data && typeof data === 'object' && !Array.isArray(data)) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          for (var key0 in data) {
            var isAdditional0 = !(
              false ||
              key0 == 'changeMessage' ||
              key0 == 'key' ||
              key0 == 'label' ||
              key0 == 'textAlignment' ||
              key0 == 'value' ||
              key0 == 'numberStyle' ||
              key0 == 'dataDetectorTypes'
            );
            if (isAdditional0) {
              valid1 = false;
              validate.errors = [
                {
                  keyword: 'additionalProperties',
                  dataPath: (dataPath || '') + '',
                  schemaPath: '#/additionalProperties',
                  params: {
                    additionalProperty: '' + key0 + '',
                  },
                  message: 'should NOT have additional properties',
                },
              ];
              return false;
              break;
            }
          }
          if (valid1) {
            if (data.changeMessage === undefined) {
              valid1 = true;
            } else {
              var errs_1 = errors;
              if (
                !refVal[10](
                  data.changeMessage,
                  (dataPath || '') + '.changeMessage',
                  data,
                  'changeMessage',
                  rootData,
                )
              ) {
                if (vErrors === null) vErrors = refVal[10].errors;
                else vErrors = vErrors.concat(refVal[10].errors);
                errors = vErrors.length;
              } else {
              }
              if (errors === errs_1) {
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              if (data.key === undefined) {
                valid1 = false;
                validate.errors = [
                  {
                    keyword: 'required',
                    dataPath: (dataPath || '') + '',
                    schemaPath: '#/required',
                    params: {
                      missingProperty: 'key',
                    },
                    message: "should have required property 'key'",
                  },
                ];
                return false;
              } else {
                var errs_1 = errors;
                if (
                  !refVal[3](
                    data.key,
                    (dataPath || '') + '.key',
                    data,
                    'key',
                    rootData,
                  )
                ) {
                  if (vErrors === null) vErrors = refVal[3].errors;
                  else vErrors = vErrors.concat(refVal[3].errors);
                  errors = vErrors.length;
                } else {
                }
                if (errors === errs_1) {
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                if (data.label === undefined) {
                  valid1 = true;
                } else {
                  var errs_1 = errors;
                  if (
                    !refVal[10](
                      data.label,
                      (dataPath || '') + '.label',
                      data,
                      'label',
                      rootData,
                    )
                  ) {
                    if (vErrors === null) vErrors = refVal[10].errors;
                    else vErrors = vErrors.concat(refVal[10].errors);
                    errors = vErrors.length;
                  } else {
                  }
                  if (errors === errs_1) {
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  var data1 = data.textAlignment;
                  if (data1 === undefined) {
                    valid1 = true;
                  } else {
                    var errs_1 = errors;
                    var errs_2 = errors;
                    if (
                      data1 &&
                      typeof data1 === 'object' &&
                      !Array.isArray(data1)
                    ) {
                      if (true) {
                        var errs__2 = errors;
                        var valid3 = true;
                        var data2 = data1.type;
                        if (data2 === undefined) {
                          valid3 = false;
                          validate.errors = [
                            {
                              keyword: 'required',
                              dataPath: (dataPath || '') + '.textAlignment',
                              schemaPath:
                                '#/definitions/textAlignmentProperty/required',
                              params: {
                                missingProperty: 'type',
                              },
                              message: "should have required property 'type'",
                            },
                          ];
                          return false;
                        } else {
                          var errs_3 = errors;
                          if (typeof data2 === 'string') {
                            if (!pattern0.test(data2)) {
                              validate.errors = [
                                {
                                  keyword: 'pattern',
                                  dataPath:
                                    (dataPath || '') + '.textAlignment.type',
                                  schemaPath:
                                    '#/definitions/textAlignmentProperty/properties/type/pattern',
                                  params: {
                                    pattern: 'string',
                                  },
                                  message: 'should match pattern "string"',
                                },
                              ];
                              return false;
                            } else {
                            }
                          } else {
                            validate.errors = [
                              {
                                keyword: 'type',
                                dataPath:
                                  (dataPath || '') + '.textAlignment.type',
                                schemaPath:
                                  '#/definitions/textAlignmentProperty/properties/type/type',
                                params: {
                                  type: 'string',
                                },
                                message: 'should be string',
                              },
                            ];
                            return false;
                          }
                          if (errors === errs_3) {
                          }
                          var valid3 = errors === errs_3;
                        }
                        if (valid3) {
                          var data2 = data1.value;
                          if (data2 === undefined) {
                            valid3 = false;
                            validate.errors = [
                              {
                                keyword: 'required',
                                dataPath: (dataPath || '') + '.textAlignment',
                                schemaPath:
                                  '#/definitions/textAlignmentProperty/required',
                                params: {
                                  missingProperty: 'value',
                                },
                                message:
                                  "should have required property 'value'",
                              },
                            ];
                            return false;
                          } else {
                            var errs_3 = errors;
                            if (typeof data2 !== 'string') {
                              validate.errors = [
                                {
                                  keyword: 'type',
                                  dataPath:
                                    (dataPath || '') + '.textAlignment.value',
                                  schemaPath:
                                    '#/definitions/textAlignmentProperty/properties/value/type',
                                  params: {
                                    type: 'string',
                                  },
                                  message: 'should be string',
                                },
                              ];
                              return false;
                            }
                            var schema3 = refVal[19].properties.value.enum;
                            var valid3;
                            valid3 = false;
                            for (var i3 = 0; i3 < schema3.length; i3++)
                              if (equal(data2, schema3[i3])) {
                                valid3 = true;
                                break;
                              }
                            if (!valid3) {
                              validate.errors = [
                                {
                                  keyword: 'enum',
                                  dataPath:
                                    (dataPath || '') + '.textAlignment.value',
                                  schemaPath:
                                    '#/definitions/textAlignmentProperty/properties/value/enum',
                                  params: {
                                    allowedValues: schema3,
                                  },
                                  message:
                                    'should be equal to one of the allowed values',
                                },
                              ];
                              return false;
                            } else {
                            }
                            if (errors === errs_3) {
                            }
                            var valid3 = errors === errs_3;
                          }
                          if (valid3) {
                          }
                        }
                        if (errs__2 == errors) {
                        }
                      }
                    } else {
                      validate.errors = [
                        {
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.textAlignment',
                          schemaPath:
                            '#/definitions/textAlignmentProperty/type',
                          params: {
                            type: 'object',
                          },
                          message: 'should be object',
                        },
                      ];
                      return false;
                    }
                    if (errors === errs_2) {
                    }
                    var valid2 = errors === errs_2;
                    if (valid2) {
                    }
                    if (errors === errs_1) {
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    var data1 = data.value;
                    if (data1 === undefined) {
                      valid1 = false;
                      validate.errors = [
                        {
                          keyword: 'required',
                          dataPath: (dataPath || '') + '',
                          schemaPath: '#/required',
                          params: {
                            missingProperty: 'value',
                          },
                          message: "should have required property 'value'",
                        },
                      ];
                      return false;
                    } else {
                      var errs_1 = errors;
                      var errs_2 = errors;
                      if (
                        !data1 ||
                        typeof data1 !== 'object' ||
                        Array.isArray(data1)
                      ) {
                        validate.errors = [
                          {
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.value',
                            schemaPath: '#/definitions/numberProperty/type',
                            params: {
                              type: 'object',
                            },
                            message: 'should be object',
                          },
                        ];
                        return false;
                      }
                      var errs__2 = errors,
                        prevValid2 = false,
                        valid2 = false,
                        passingSchemas2 = null;
                      var errs_3 = errors;
                      if (
                        data1 &&
                        typeof data1 === 'object' &&
                        !Array.isArray(data1)
                      ) {
                        if (true) {
                          var errs__3 = errors;
                          var valid4 = true;
                          var data2 = data1.type;
                          if (data2 === undefined) {
                            valid4 = false;
                            var err = {
                              keyword: 'required',
                              dataPath: (dataPath || '') + '.value',
                              schemaPath:
                                '#/definitions/numberProperty/oneOf/0/required',
                              params: {
                                missingProperty: 'type',
                              },
                              message: "should have required property 'type'",
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          } else {
                            var errs_4 = errors;
                            if (typeof data2 === 'string') {
                              if (!pattern3.test(data2)) {
                                var err = {
                                  keyword: 'pattern',
                                  dataPath: (dataPath || '') + '.value.type',
                                  schemaPath:
                                    '#/definitions/numberProperty/oneOf/0/properties/type/pattern',
                                  params: {
                                    pattern: 'number',
                                  },
                                  message: 'should match pattern "number"',
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                              } else {
                              }
                            } else {
                              var err = {
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.value.type',
                                schemaPath:
                                  '#/definitions/numberProperty/oneOf/0/properties/type/type',
                                params: {
                                  type: 'string',
                                },
                                message: 'should be string',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            }
                            if (errors === errs_4) {
                            }
                            var valid4 = errors === errs_4;
                          }
                          if (valid4) {
                            if (data1.value === undefined) {
                              valid4 = false;
                              var err = {
                                keyword: 'required',
                                dataPath: (dataPath || '') + '.value',
                                schemaPath:
                                  '#/definitions/numberProperty/oneOf/0/required',
                                params: {
                                  missingProperty: 'value',
                                },
                                message:
                                  "should have required property 'value'",
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            } else {
                              var errs_4 = errors;
                              if (typeof data1.value !== 'number') {
                                var err = {
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.value.value',
                                  schemaPath:
                                    '#/definitions/numberProperty/oneOf/0/properties/value/type',
                                  params: {
                                    type: 'number',
                                  },
                                  message: 'should be number',
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                              }
                              var valid4 = errors === errs_4;
                            }
                            if (valid4) {
                            }
                          }
                          if (errs__3 == errors) {
                          }
                        }
                      }
                      if (errors === errs_3) {
                      }
                      var valid3 = errors === errs_3;
                      if (valid3) {
                        valid2 = prevValid2 = true;
                        passingSchemas2 = 0;
                      }
                      var errs_3 = errors;
                      if (
                        data1 &&
                        typeof data1 === 'object' &&
                        !Array.isArray(data1)
                      ) {
                        if (true) {
                          var errs__3 = errors;
                          var valid4 = true;
                          var data2 = data1.type;
                          if (data2 === undefined) {
                            valid4 = false;
                            var err = {
                              keyword: 'required',
                              dataPath: (dataPath || '') + '.value',
                              schemaPath:
                                '#/definitions/numberProperty/oneOf/1/required',
                              params: {
                                missingProperty: 'type',
                              },
                              message: "should have required property 'type'",
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          } else {
                            var errs_4 = errors;
                            if (typeof data2 === 'string') {
                              if (!pattern3.test(data2)) {
                                var err = {
                                  keyword: 'pattern',
                                  dataPath: (dataPath || '') + '.value.type',
                                  schemaPath:
                                    '#/definitions/numberProperty/oneOf/1/properties/type/pattern',
                                  params: {
                                    pattern: 'number',
                                  },
                                  message: 'should match pattern "number"',
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                              } else {
                              }
                            } else {
                              var err = {
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.value.type',
                                schemaPath:
                                  '#/definitions/numberProperty/oneOf/1/properties/type/type',
                                params: {
                                  type: 'string',
                                },
                                message: 'should be string',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            }
                            if (errors === errs_4) {
                            }
                            var valid4 = errors === errs_4;
                          }
                          if (valid4) {
                            var data2 = data1.value;
                            if (data2 === undefined) {
                              valid4 = false;
                              var err = {
                                keyword: 'required',
                                dataPath: (dataPath || '') + '.value',
                                schemaPath:
                                  '#/definitions/numberProperty/oneOf/1/required',
                                params: {
                                  missingProperty: 'value',
                                },
                                message:
                                  "should have required property 'value'",
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            } else {
                              var errs_4 = errors;
                              if (typeof data2 === 'string') {
                                if (!pattern4.test(data2)) {
                                  var err = {
                                    keyword: 'pattern',
                                    dataPath: (dataPath || '') + '.value.value',
                                    schemaPath:
                                      '#/definitions/numberProperty/oneOf/1/properties/value/pattern',
                                    params: {
                                      pattern: '^#\\{.+\\}$',
                                    },
                                    message:
                                      'should match pattern "^#\\{.+\\}$"',
                                  };
                                  if (vErrors === null) vErrors = [err];
                                  else vErrors.push(err);
                                  errors++;
                                } else {
                                }
                              } else {
                                var err = {
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.value.value',
                                  schemaPath:
                                    '#/definitions/numberProperty/oneOf/1/properties/value/type',
                                  params: {
                                    type: 'string',
                                  },
                                  message: 'should be string',
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                              }
                              if (errors === errs_4) {
                              }
                              var valid4 = errors === errs_4;
                            }
                            if (valid4) {
                              if (data1.fallback === undefined) {
                                valid4 = false;
                                var err = {
                                  keyword: 'required',
                                  dataPath: (dataPath || '') + '.value',
                                  schemaPath:
                                    '#/definitions/numberProperty/oneOf/1/required',
                                  params: {
                                    missingProperty: 'fallback',
                                  },
                                  message:
                                    "should have required property 'fallback'",
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                              } else {
                                var errs_4 = errors;
                                if (typeof data1.fallback !== 'number') {
                                  var err = {
                                    keyword: 'type',
                                    dataPath:
                                      (dataPath || '') + '.value.fallback',
                                    schemaPath:
                                      '#/definitions/numberProperty/oneOf/1/properties/fallback/type',
                                    params: {
                                      type: 'number',
                                    },
                                    message: 'should be number',
                                  };
                                  if (vErrors === null) vErrors = [err];
                                  else vErrors.push(err);
                                  errors++;
                                }
                                var valid4 = errors === errs_4;
                              }
                              if (valid4) {
                              }
                            }
                          }
                          if (errs__3 == errors) {
                          }
                        }
                      }
                      if (errors === errs_3) {
                      }
                      var valid3 = errors === errs_3;
                      if (valid3 && prevValid2) {
                        valid2 = false;
                        passingSchemas2 = [passingSchemas2, 1];
                      } else {
                        if (valid3) {
                          valid2 = prevValid2 = true;
                          passingSchemas2 = 1;
                        }
                      }
                      if (!valid2) {
                        var err = {
                          keyword: 'oneOf',
                          dataPath: (dataPath || '') + '.value',
                          schemaPath: '#/definitions/numberProperty/oneOf',
                          params: {
                            passingSchemas: passingSchemas2,
                          },
                          message: 'should match exactly one schema in oneOf',
                        };
                        if (vErrors === null) vErrors = [err];
                        else vErrors.push(err);
                        errors++;
                        validate.errors = vErrors;
                        return false;
                      } else {
                        errors = errs__2;
                        if (vErrors !== null) {
                          if (errs__2) vErrors.length = errs__2;
                          else vErrors = null;
                        }
                      }
                      if (errors === errs_2) {
                      }
                      var valid2 = errors === errs_2;
                      if (valid2) {
                      }
                      if (errors === errs_1) {
                      }
                      var valid1 = errors === errs_1;
                    }
                    if (valid1) {
                      var data1 = data.numberStyle;
                      if (data1 === undefined) {
                        valid1 = false;
                        validate.errors = [
                          {
                            keyword: 'required',
                            dataPath: (dataPath || '') + '',
                            schemaPath: '#/required',
                            params: {
                              missingProperty: 'numberStyle',
                            },
                            message:
                              "should have required property 'numberStyle'",
                          },
                        ];
                        return false;
                      } else {
                        var errs_1 = errors;
                        var errs_2 = errors;
                        if (
                          data1 &&
                          typeof data1 === 'object' &&
                          !Array.isArray(data1)
                        ) {
                          if (true) {
                            var errs__2 = errors;
                            var valid3 = true;
                            var data2 = data1.type;
                            if (data2 === undefined) {
                              valid3 = false;
                              validate.errors = [
                                {
                                  keyword: 'required',
                                  dataPath: (dataPath || '') + '.numberStyle',
                                  schemaPath:
                                    '#/definitions/numberStyleProperty/required',
                                  params: {
                                    missingProperty: 'type',
                                  },
                                  message:
                                    "should have required property 'type'",
                                },
                              ];
                              return false;
                            } else {
                              var errs_3 = errors;
                              if (typeof data2 === 'string') {
                                if (!pattern0.test(data2)) {
                                  validate.errors = [
                                    {
                                      keyword: 'pattern',
                                      dataPath:
                                        (dataPath || '') + '.numberStyle.type',
                                      schemaPath:
                                        '#/definitions/numberStyleProperty/properties/type/pattern',
                                      params: {
                                        pattern: 'string',
                                      },
                                      message: 'should match pattern "string"',
                                    },
                                  ];
                                  return false;
                                } else {
                                }
                              } else {
                                validate.errors = [
                                  {
                                    keyword: 'type',
                                    dataPath:
                                      (dataPath || '') + '.numberStyle.type',
                                    schemaPath:
                                      '#/definitions/numberStyleProperty/properties/type/type',
                                    params: {
                                      type: 'string',
                                    },
                                    message: 'should be string',
                                  },
                                ];
                                return false;
                              }
                              if (errors === errs_3) {
                              }
                              var valid3 = errors === errs_3;
                            }
                            if (valid3) {
                              var data2 = data1.value;
                              if (data2 === undefined) {
                                valid3 = false;
                                validate.errors = [
                                  {
                                    keyword: 'required',
                                    dataPath: (dataPath || '') + '.numberStyle',
                                    schemaPath:
                                      '#/definitions/numberStyleProperty/required',
                                    params: {
                                      missingProperty: 'value',
                                    },
                                    message:
                                      "should have required property 'value'",
                                  },
                                ];
                                return false;
                              } else {
                                var errs_3 = errors;
                                if (typeof data2 !== 'string') {
                                  validate.errors = [
                                    {
                                      keyword: 'type',
                                      dataPath:
                                        (dataPath || '') + '.numberStyle.value',
                                      schemaPath:
                                        '#/definitions/numberStyleProperty/properties/value/type',
                                      params: {
                                        type: 'string',
                                      },
                                      message: 'should be string',
                                    },
                                  ];
                                  return false;
                                }
                                var schema3 = refVal23.properties.value.enum;
                                var valid3;
                                valid3 = false;
                                for (var i3 = 0; i3 < schema3.length; i3++)
                                  if (equal(data2, schema3[i3])) {
                                    valid3 = true;
                                    break;
                                  }
                                if (!valid3) {
                                  validate.errors = [
                                    {
                                      keyword: 'enum',
                                      dataPath:
                                        (dataPath || '') + '.numberStyle.value',
                                      schemaPath:
                                        '#/definitions/numberStyleProperty/properties/value/enum',
                                      params: {
                                        allowedValues: schema3,
                                      },
                                      message:
                                        'should be equal to one of the allowed values',
                                    },
                                  ];
                                  return false;
                                } else {
                                }
                                if (errors === errs_3) {
                                }
                                var valid3 = errors === errs_3;
                              }
                              if (valid3) {
                              }
                            }
                            if (errs__2 == errors) {
                            }
                          }
                        } else {
                          validate.errors = [
                            {
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.numberStyle',
                              schemaPath:
                                '#/definitions/numberStyleProperty/type',
                              params: {
                                type: 'object',
                              },
                              message: 'should be object',
                            },
                          ];
                          return false;
                        }
                        if (errors === errs_2) {
                        }
                        var valid2 = errors === errs_2;
                        if (valid2) {
                        }
                        if (errors === errs_1) {
                        }
                        var valid1 = errors === errs_1;
                      }
                      if (valid1) {
                        if (data.dataDetectorTypes === undefined) {
                          valid1 = true;
                        } else {
                          var errs_1 = errors;
                          if (
                            !refVal[21](
                              data.dataDetectorTypes,
                              (dataPath || '') + '.dataDetectorTypes',
                              data,
                              'dataDetectorTypes',
                              rootData,
                            )
                          ) {
                            if (vErrors === null) vErrors = refVal[21].errors;
                            else vErrors = vErrors.concat(refVal[21].errors);
                            errors = vErrors.length;
                          } else {
                          }
                          if (errors === errs_1) {
                          }
                          var valid1 = errors === errs_1;
                        }
                        if (valid1) {
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          if (errs__0 == errors) {
          }
        }
      } else {
        validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/type',
            params: {
              type: 'object',
            },
            message: 'should be object',
          },
        ];
        return false;
      }
      if (errors === 0) {
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal22.schema = {
    type: 'object',
    properties: {
      changeMessage: {
        $ref: '#/definitions/localizedStringProperty',
      },
      key: {
        $ref: '#/definitions/stringProperty',
      },
      label: {
        $ref: '#/definitions/localizedStringProperty',
      },
      textAlignment: {
        $ref: '#/definitions/textAlignmentProperty',
      },
      value: {
        $ref: '#/definitions/numberProperty',
      },
      numberStyle: {
        $ref: '#/definitions/numberStyleProperty',
      },
      dataDetectorTypes: {
        $ref: '#/definitions/dataDetectorTypes',
      },
    },
    additionalProperties: false,
    required: ['key', 'value', 'numberStyle'],
  };
  refVal22.errors = null;
  refVal[22] = refVal22;
  var refVal23 = {
    type: 'object',
    properties: {
      type: {
        type: 'string',
        pattern: 'string',
      },
      value: {
        type: 'string',
        enum: [
          'PKNumberStyleDecimal',
          'PKNumberStylePercent',
          'PKNumberStyleScientific',
          'PKNumberStyleSpellOut',
        ],
      },
    },
    required: ['type', 'value'],
  };
  refVal[23] = refVal23;
  var refVal24 = (function() {
    var pattern0 = new RegExp('string');
    var pattern1 = new RegExp(
      '#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^}]{4,}\\}',
    );
    var pattern2 = new RegExp(
      '^((?!(#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^\\}]{4,}\\}))[\\s\\S])*$',
    );
    var pattern3 = new RegExp('number');
    var pattern4 = new RegExp('^#\\{.+\\}$');
    var pattern5 = new RegExp('[a-z]{2}');
    var pattern6 = new RegExp(
      '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
    );
    var pattern7 = new RegExp(
      '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
    );
    var pattern8 = new RegExp('boolean');
    return function validate(
      data,
      dataPath,
      parentData,
      parentDataProperty,
      rootData,
    ) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if (data && typeof data === 'object' && !Array.isArray(data)) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          if (data.changeMessage === undefined) {
            valid1 = true;
          } else {
            var errs_1 = errors;
            if (
              !refVal[10](
                data.changeMessage,
                (dataPath || '') + '.changeMessage',
                data,
                'changeMessage',
                rootData,
              )
            ) {
              if (vErrors === null) vErrors = refVal[10].errors;
              else vErrors = vErrors.concat(refVal[10].errors);
              errors = vErrors.length;
            } else {
            }
            if (errors === errs_1) {
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.key === undefined) {
              valid1 = false;
              validate.errors = [
                {
                  keyword: 'required',
                  dataPath: (dataPath || '') + '',
                  schemaPath: '#/required',
                  params: {
                    missingProperty: 'key',
                  },
                  message: "should have required property 'key'",
                },
              ];
              return false;
            } else {
              var errs_1 = errors;
              if (
                !refVal[3](
                  data.key,
                  (dataPath || '') + '.key',
                  data,
                  'key',
                  rootData,
                )
              ) {
                if (vErrors === null) vErrors = refVal[3].errors;
                else vErrors = vErrors.concat(refVal[3].errors);
                errors = vErrors.length;
              } else {
              }
              if (errors === errs_1) {
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              if (data.label === undefined) {
                valid1 = true;
              } else {
                var errs_1 = errors;
                if (
                  !refVal[10](
                    data.label,
                    (dataPath || '') + '.label',
                    data,
                    'label',
                    rootData,
                  )
                ) {
                  if (vErrors === null) vErrors = refVal[10].errors;
                  else vErrors = vErrors.concat(refVal[10].errors);
                  errors = vErrors.length;
                } else {
                }
                if (errors === errs_1) {
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                var data1 = data.textAlignment;
                if (data1 === undefined) {
                  valid1 = true;
                } else {
                  var errs_1 = errors;
                  var errs_2 = errors;
                  if (
                    data1 &&
                    typeof data1 === 'object' &&
                    !Array.isArray(data1)
                  ) {
                    if (true) {
                      var errs__2 = errors;
                      var valid3 = true;
                      var data2 = data1.type;
                      if (data2 === undefined) {
                        valid3 = false;
                        validate.errors = [
                          {
                            keyword: 'required',
                            dataPath: (dataPath || '') + '.textAlignment',
                            schemaPath:
                              '#/definitions/textAlignmentProperty/required',
                            params: {
                              missingProperty: 'type',
                            },
                            message: "should have required property 'type'",
                          },
                        ];
                        return false;
                      } else {
                        var errs_3 = errors;
                        if (typeof data2 === 'string') {
                          if (!pattern0.test(data2)) {
                            validate.errors = [
                              {
                                keyword: 'pattern',
                                dataPath:
                                  (dataPath || '') + '.textAlignment.type',
                                schemaPath:
                                  '#/definitions/textAlignmentProperty/properties/type/pattern',
                                params: {
                                  pattern: 'string',
                                },
                                message: 'should match pattern "string"',
                              },
                            ];
                            return false;
                          } else {
                          }
                        } else {
                          validate.errors = [
                            {
                              keyword: 'type',
                              dataPath:
                                (dataPath || '') + '.textAlignment.type',
                              schemaPath:
                                '#/definitions/textAlignmentProperty/properties/type/type',
                              params: {
                                type: 'string',
                              },
                              message: 'should be string',
                            },
                          ];
                          return false;
                        }
                        if (errors === errs_3) {
                        }
                        var valid3 = errors === errs_3;
                      }
                      if (valid3) {
                        var data2 = data1.value;
                        if (data2 === undefined) {
                          valid3 = false;
                          validate.errors = [
                            {
                              keyword: 'required',
                              dataPath: (dataPath || '') + '.textAlignment',
                              schemaPath:
                                '#/definitions/textAlignmentProperty/required',
                              params: {
                                missingProperty: 'value',
                              },
                              message: "should have required property 'value'",
                            },
                          ];
                          return false;
                        } else {
                          var errs_3 = errors;
                          if (typeof data2 !== 'string') {
                            validate.errors = [
                              {
                                keyword: 'type',
                                dataPath:
                                  (dataPath || '') + '.textAlignment.value',
                                schemaPath:
                                  '#/definitions/textAlignmentProperty/properties/value/type',
                                params: {
                                  type: 'string',
                                },
                                message: 'should be string',
                              },
                            ];
                            return false;
                          }
                          var schema3 = refVal[19].properties.value.enum;
                          var valid3;
                          valid3 = false;
                          for (var i3 = 0; i3 < schema3.length; i3++)
                            if (equal(data2, schema3[i3])) {
                              valid3 = true;
                              break;
                            }
                          if (!valid3) {
                            validate.errors = [
                              {
                                keyword: 'enum',
                                dataPath:
                                  (dataPath || '') + '.textAlignment.value',
                                schemaPath:
                                  '#/definitions/textAlignmentProperty/properties/value/enum',
                                params: {
                                  allowedValues: schema3,
                                },
                                message:
                                  'should be equal to one of the allowed values',
                              },
                            ];
                            return false;
                          } else {
                          }
                          if (errors === errs_3) {
                          }
                          var valid3 = errors === errs_3;
                        }
                        if (valid3) {
                        }
                      }
                      if (errs__2 == errors) {
                      }
                    }
                  } else {
                    validate.errors = [
                      {
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.textAlignment',
                        schemaPath: '#/definitions/textAlignmentProperty/type',
                        params: {
                          type: 'object',
                        },
                        message: 'should be object',
                      },
                    ];
                    return false;
                  }
                  if (errors === errs_2) {
                  }
                  var valid2 = errors === errs_2;
                  if (valid2) {
                  }
                  if (errors === errs_1) {
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  var data1 = data.value;
                  if (data1 === undefined) {
                    valid1 = false;
                    validate.errors = [
                      {
                        keyword: 'required',
                        dataPath: (dataPath || '') + '',
                        schemaPath: '#/required',
                        params: {
                          missingProperty: 'value',
                        },
                        message: "should have required property 'value'",
                      },
                    ];
                    return false;
                  } else {
                    var errs_1 = errors;
                    var errs_2 = errors;
                    if (
                      !data1 ||
                      typeof data1 !== 'object' ||
                      Array.isArray(data1)
                    ) {
                      validate.errors = [
                        {
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.value',
                          schemaPath: '#/definitions/numberProperty/type',
                          params: {
                            type: 'object',
                          },
                          message: 'should be object',
                        },
                      ];
                      return false;
                    }
                    var errs__2 = errors,
                      prevValid2 = false,
                      valid2 = false,
                      passingSchemas2 = null;
                    var errs_3 = errors;
                    if (
                      data1 &&
                      typeof data1 === 'object' &&
                      !Array.isArray(data1)
                    ) {
                      if (true) {
                        var errs__3 = errors;
                        var valid4 = true;
                        var data2 = data1.type;
                        if (data2 === undefined) {
                          valid4 = false;
                          var err = {
                            keyword: 'required',
                            dataPath: (dataPath || '') + '.value',
                            schemaPath:
                              '#/definitions/numberProperty/oneOf/0/required',
                            params: {
                              missingProperty: 'type',
                            },
                            message: "should have required property 'type'",
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                        } else {
                          var errs_4 = errors;
                          if (typeof data2 === 'string') {
                            if (!pattern3.test(data2)) {
                              var err = {
                                keyword: 'pattern',
                                dataPath: (dataPath || '') + '.value.type',
                                schemaPath:
                                  '#/definitions/numberProperty/oneOf/0/properties/type/pattern',
                                params: {
                                  pattern: 'number',
                                },
                                message: 'should match pattern "number"',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            } else {
                            }
                          } else {
                            var err = {
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.value.type',
                              schemaPath:
                                '#/definitions/numberProperty/oneOf/0/properties/type/type',
                              params: {
                                type: 'string',
                              },
                              message: 'should be string',
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          }
                          if (errors === errs_4) {
                          }
                          var valid4 = errors === errs_4;
                        }
                        if (valid4) {
                          if (data1.value === undefined) {
                            valid4 = false;
                            var err = {
                              keyword: 'required',
                              dataPath: (dataPath || '') + '.value',
                              schemaPath:
                                '#/definitions/numberProperty/oneOf/0/required',
                              params: {
                                missingProperty: 'value',
                              },
                              message: "should have required property 'value'",
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          } else {
                            var errs_4 = errors;
                            if (typeof data1.value !== 'number') {
                              var err = {
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.value.value',
                                schemaPath:
                                  '#/definitions/numberProperty/oneOf/0/properties/value/type',
                                params: {
                                  type: 'number',
                                },
                                message: 'should be number',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            }
                            var valid4 = errors === errs_4;
                          }
                          if (valid4) {
                          }
                        }
                        if (errs__3 == errors) {
                        }
                      }
                    }
                    if (errors === errs_3) {
                    }
                    var valid3 = errors === errs_3;
                    if (valid3) {
                      valid2 = prevValid2 = true;
                      passingSchemas2 = 0;
                    }
                    var errs_3 = errors;
                    if (
                      data1 &&
                      typeof data1 === 'object' &&
                      !Array.isArray(data1)
                    ) {
                      if (true) {
                        var errs__3 = errors;
                        var valid4 = true;
                        var data2 = data1.type;
                        if (data2 === undefined) {
                          valid4 = false;
                          var err = {
                            keyword: 'required',
                            dataPath: (dataPath || '') + '.value',
                            schemaPath:
                              '#/definitions/numberProperty/oneOf/1/required',
                            params: {
                              missingProperty: 'type',
                            },
                            message: "should have required property 'type'",
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                        } else {
                          var errs_4 = errors;
                          if (typeof data2 === 'string') {
                            if (!pattern3.test(data2)) {
                              var err = {
                                keyword: 'pattern',
                                dataPath: (dataPath || '') + '.value.type',
                                schemaPath:
                                  '#/definitions/numberProperty/oneOf/1/properties/type/pattern',
                                params: {
                                  pattern: 'number',
                                },
                                message: 'should match pattern "number"',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            } else {
                            }
                          } else {
                            var err = {
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.value.type',
                              schemaPath:
                                '#/definitions/numberProperty/oneOf/1/properties/type/type',
                              params: {
                                type: 'string',
                              },
                              message: 'should be string',
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          }
                          if (errors === errs_4) {
                          }
                          var valid4 = errors === errs_4;
                        }
                        if (valid4) {
                          var data2 = data1.value;
                          if (data2 === undefined) {
                            valid4 = false;
                            var err = {
                              keyword: 'required',
                              dataPath: (dataPath || '') + '.value',
                              schemaPath:
                                '#/definitions/numberProperty/oneOf/1/required',
                              params: {
                                missingProperty: 'value',
                              },
                              message: "should have required property 'value'",
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          } else {
                            var errs_4 = errors;
                            if (typeof data2 === 'string') {
                              if (!pattern4.test(data2)) {
                                var err = {
                                  keyword: 'pattern',
                                  dataPath: (dataPath || '') + '.value.value',
                                  schemaPath:
                                    '#/definitions/numberProperty/oneOf/1/properties/value/pattern',
                                  params: {
                                    pattern: '^#\\{.+\\}$',
                                  },
                                  message: 'should match pattern "^#\\{.+\\}$"',
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                              } else {
                              }
                            } else {
                              var err = {
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.value.value',
                                schemaPath:
                                  '#/definitions/numberProperty/oneOf/1/properties/value/type',
                                params: {
                                  type: 'string',
                                },
                                message: 'should be string',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            }
                            if (errors === errs_4) {
                            }
                            var valid4 = errors === errs_4;
                          }
                          if (valid4) {
                            if (data1.fallback === undefined) {
                              valid4 = false;
                              var err = {
                                keyword: 'required',
                                dataPath: (dataPath || '') + '.value',
                                schemaPath:
                                  '#/definitions/numberProperty/oneOf/1/required',
                                params: {
                                  missingProperty: 'fallback',
                                },
                                message:
                                  "should have required property 'fallback'",
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            } else {
                              var errs_4 = errors;
                              if (typeof data1.fallback !== 'number') {
                                var err = {
                                  keyword: 'type',
                                  dataPath:
                                    (dataPath || '') + '.value.fallback',
                                  schemaPath:
                                    '#/definitions/numberProperty/oneOf/1/properties/fallback/type',
                                  params: {
                                    type: 'number',
                                  },
                                  message: 'should be number',
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                              }
                              var valid4 = errors === errs_4;
                            }
                            if (valid4) {
                            }
                          }
                        }
                        if (errs__3 == errors) {
                        }
                      }
                    }
                    if (errors === errs_3) {
                    }
                    var valid3 = errors === errs_3;
                    if (valid3 && prevValid2) {
                      valid2 = false;
                      passingSchemas2 = [passingSchemas2, 1];
                    } else {
                      if (valid3) {
                        valid2 = prevValid2 = true;
                        passingSchemas2 = 1;
                      }
                    }
                    if (!valid2) {
                      var err = {
                        keyword: 'oneOf',
                        dataPath: (dataPath || '') + '.value',
                        schemaPath: '#/definitions/numberProperty/oneOf',
                        params: {
                          passingSchemas: passingSchemas2,
                        },
                        message: 'should match exactly one schema in oneOf',
                      };
                      if (vErrors === null) vErrors = [err];
                      else vErrors.push(err);
                      errors++;
                      validate.errors = vErrors;
                      return false;
                    } else {
                      errors = errs__2;
                      if (vErrors !== null) {
                        if (errs__2) vErrors.length = errs__2;
                        else vErrors = null;
                      }
                    }
                    if (errors === errs_2) {
                    }
                    var valid2 = errors === errs_2;
                    if (valid2) {
                    }
                    if (errors === errs_1) {
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    if (data.dataDetectorTypes === undefined) {
                      valid1 = true;
                    } else {
                      var errs_1 = errors;
                      if (
                        !refVal[21](
                          data.dataDetectorTypes,
                          (dataPath || '') + '.dataDetectorTypes',
                          data,
                          'dataDetectorTypes',
                          rootData,
                        )
                      ) {
                        if (vErrors === null) vErrors = refVal[21].errors;
                        else vErrors = vErrors.concat(refVal[21].errors);
                        errors = vErrors.length;
                      } else {
                      }
                      if (errors === errs_1) {
                      }
                      var valid1 = errors === errs_1;
                    }
                    if (valid1) {
                      if (data.currencyCode === undefined) {
                        valid1 = false;
                        validate.errors = [
                          {
                            keyword: 'required',
                            dataPath: (dataPath || '') + '',
                            schemaPath: '#/required',
                            params: {
                              missingProperty: 'currencyCode',
                            },
                            message:
                              "should have required property 'currencyCode'",
                          },
                        ];
                        return false;
                      } else {
                        var errs_1 = errors;
                        if (
                          !refVal[3](
                            data.currencyCode,
                            (dataPath || '') + '.currencyCode',
                            data,
                            'currencyCode',
                            rootData,
                          )
                        ) {
                          if (vErrors === null) vErrors = refVal[3].errors;
                          else vErrors = vErrors.concat(refVal[3].errors);
                          errors = vErrors.length;
                        } else {
                        }
                        if (errors === errs_1) {
                        }
                        var valid1 = errors === errs_1;
                      }
                      if (valid1) {
                      }
                    }
                  }
                }
              }
            }
          }
          if (errs__0 == errors) {
          }
        }
      } else {
        validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/type',
            params: {
              type: 'object',
            },
            message: 'should be object',
          },
        ];
        return false;
      }
      if (errors === 0) {
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal24.schema = {
    type: 'object',
    properties: {
      changeMessage: {
        $ref: '#/definitions/localizedStringProperty',
      },
      key: {
        $ref: '#/definitions/stringProperty',
      },
      label: {
        $ref: '#/definitions/localizedStringProperty',
      },
      textAlignment: {
        $ref: '#/definitions/textAlignmentProperty',
      },
      value: {
        $ref: '#/definitions/numberProperty',
      },
      dataDetectorTypes: {
        $ref: '#/definitions/dataDetectorTypes',
      },
      currencyCode: {
        $ref: '#/definitions/stringProperty',
      },
    },
    required: ['key', 'value', 'currencyCode'],
  };
  refVal24.errors = null;
  refVal[24] = refVal24;
  var refVal25 = (function() {
    var pattern0 = new RegExp('string');
    var pattern1 = new RegExp(
      '#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^}]{4,}\\}',
    );
    var pattern2 = new RegExp(
      '^((?!(#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^\\}]{4,}\\}))[\\s\\S])*$',
    );
    var pattern3 = new RegExp('number');
    var pattern4 = new RegExp('^#\\{.+\\}$');
    var pattern5 = new RegExp('[a-z]{2}');
    var pattern6 = new RegExp(
      '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
    );
    var pattern7 = new RegExp(
      '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
    );
    var pattern8 = new RegExp('boolean');
    return function validate(
      data,
      dataPath,
      parentData,
      parentDataProperty,
      rootData,
    ) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if (data && typeof data === 'object' && !Array.isArray(data)) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          for (var key0 in data) {
            var isAdditional0 = !(
              false ||
              key0 == 'changeMessage' ||
              key0 == 'attributedValue' ||
              key0 == 'key' ||
              key0 == 'label' ||
              key0 == 'textAlignment' ||
              key0 == 'value' ||
              key0 == 'dataDetectorTypes'
            );
            if (isAdditional0) {
              valid1 = false;
              validate.errors = [
                {
                  keyword: 'additionalProperties',
                  dataPath: (dataPath || '') + '',
                  schemaPath: '#/additionalProperties',
                  params: {
                    additionalProperty: '' + key0 + '',
                  },
                  message: 'should NOT have additional properties',
                },
              ];
              return false;
              break;
            }
          }
          if (valid1) {
            if (data.changeMessage === undefined) {
              valid1 = true;
            } else {
              var errs_1 = errors;
              if (
                !refVal[10](
                  data.changeMessage,
                  (dataPath || '') + '.changeMessage',
                  data,
                  'changeMessage',
                  rootData,
                )
              ) {
                if (vErrors === null) vErrors = refVal[10].errors;
                else vErrors = vErrors.concat(refVal[10].errors);
                errors = vErrors.length;
              } else {
              }
              if (errors === errs_1) {
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              var data1 = data.attributedValue;
              if (data1 === undefined) {
                valid1 = true;
              } else {
                var errs_1 = errors;
                var errs__1 = errors;
                var valid1 = false;
                var errs_2 = errors;
                if (
                  !refVal[10](
                    data1,
                    (dataPath || '') + '.attributedValue',
                    data,
                    'attributedValue',
                    rootData,
                  )
                ) {
                  if (vErrors === null) vErrors = refVal[10].errors;
                  else vErrors = vErrors.concat(refVal[10].errors);
                  errors = vErrors.length;
                } else {
                }
                if (errors === errs_2) {
                }
                var valid2 = errors === errs_2;
                valid1 = valid1 || valid2;
                if (!valid1) {
                  var errs_2 = errors;
                  if (
                    !refVal[3](
                      data1,
                      (dataPath || '') + '.attributedValue',
                      data,
                      'attributedValue',
                      rootData,
                    )
                  ) {
                    if (vErrors === null) vErrors = refVal[3].errors;
                    else vErrors = vErrors.concat(refVal[3].errors);
                    errors = vErrors.length;
                  } else {
                  }
                  if (errors === errs_2) {
                  }
                  var valid2 = errors === errs_2;
                  valid1 = valid1 || valid2;
                  if (!valid1) {
                  }
                }
                if (!valid1) {
                  var err = {
                    keyword: 'anyOf',
                    dataPath: (dataPath || '') + '.attributedValue',
                    schemaPath: '#/properties/attributedValue/anyOf',
                    params: {},
                    message: 'should match some schema in anyOf',
                  };
                  if (vErrors === null) vErrors = [err];
                  else vErrors.push(err);
                  errors++;
                  validate.errors = vErrors;
                  return false;
                } else {
                  errors = errs__1;
                  if (vErrors !== null) {
                    if (errs__1) vErrors.length = errs__1;
                    else vErrors = null;
                  }
                }
                if (errors === errs_1) {
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                if (data.key === undefined) {
                  valid1 = false;
                  validate.errors = [
                    {
                      keyword: 'required',
                      dataPath: (dataPath || '') + '',
                      schemaPath: '#/required',
                      params: {
                        missingProperty: 'key',
                      },
                      message: "should have required property 'key'",
                    },
                  ];
                  return false;
                } else {
                  var errs_1 = errors;
                  if (
                    !refVal[3](
                      data.key,
                      (dataPath || '') + '.key',
                      data,
                      'key',
                      rootData,
                    )
                  ) {
                    if (vErrors === null) vErrors = refVal[3].errors;
                    else vErrors = vErrors.concat(refVal[3].errors);
                    errors = vErrors.length;
                  } else {
                  }
                  if (errors === errs_1) {
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  if (data.label === undefined) {
                    valid1 = true;
                  } else {
                    var errs_1 = errors;
                    if (
                      !refVal[10](
                        data.label,
                        (dataPath || '') + '.label',
                        data,
                        'label',
                        rootData,
                      )
                    ) {
                      if (vErrors === null) vErrors = refVal[10].errors;
                      else vErrors = vErrors.concat(refVal[10].errors);
                      errors = vErrors.length;
                    } else {
                    }
                    if (errors === errs_1) {
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    var data1 = data.textAlignment;
                    if (data1 === undefined) {
                      valid1 = true;
                    } else {
                      var errs_1 = errors;
                      var errs_2 = errors;
                      if (
                        data1 &&
                        typeof data1 === 'object' &&
                        !Array.isArray(data1)
                      ) {
                        if (true) {
                          var errs__2 = errors;
                          var valid3 = true;
                          var data2 = data1.type;
                          if (data2 === undefined) {
                            valid3 = false;
                            validate.errors = [
                              {
                                keyword: 'required',
                                dataPath: (dataPath || '') + '.textAlignment',
                                schemaPath:
                                  '#/definitions/textAlignmentProperty/required',
                                params: {
                                  missingProperty: 'type',
                                },
                                message: "should have required property 'type'",
                              },
                            ];
                            return false;
                          } else {
                            var errs_3 = errors;
                            if (typeof data2 === 'string') {
                              if (!pattern0.test(data2)) {
                                validate.errors = [
                                  {
                                    keyword: 'pattern',
                                    dataPath:
                                      (dataPath || '') + '.textAlignment.type',
                                    schemaPath:
                                      '#/definitions/textAlignmentProperty/properties/type/pattern',
                                    params: {
                                      pattern: 'string',
                                    },
                                    message: 'should match pattern "string"',
                                  },
                                ];
                                return false;
                              } else {
                              }
                            } else {
                              validate.errors = [
                                {
                                  keyword: 'type',
                                  dataPath:
                                    (dataPath || '') + '.textAlignment.type',
                                  schemaPath:
                                    '#/definitions/textAlignmentProperty/properties/type/type',
                                  params: {
                                    type: 'string',
                                  },
                                  message: 'should be string',
                                },
                              ];
                              return false;
                            }
                            if (errors === errs_3) {
                            }
                            var valid3 = errors === errs_3;
                          }
                          if (valid3) {
                            var data2 = data1.value;
                            if (data2 === undefined) {
                              valid3 = false;
                              validate.errors = [
                                {
                                  keyword: 'required',
                                  dataPath: (dataPath || '') + '.textAlignment',
                                  schemaPath:
                                    '#/definitions/textAlignmentProperty/required',
                                  params: {
                                    missingProperty: 'value',
                                  },
                                  message:
                                    "should have required property 'value'",
                                },
                              ];
                              return false;
                            } else {
                              var errs_3 = errors;
                              if (typeof data2 !== 'string') {
                                validate.errors = [
                                  {
                                    keyword: 'type',
                                    dataPath:
                                      (dataPath || '') + '.textAlignment.value',
                                    schemaPath:
                                      '#/definitions/textAlignmentProperty/properties/value/type',
                                    params: {
                                      type: 'string',
                                    },
                                    message: 'should be string',
                                  },
                                ];
                                return false;
                              }
                              var schema3 = refVal[19].properties.value.enum;
                              var valid3;
                              valid3 = false;
                              for (var i3 = 0; i3 < schema3.length; i3++)
                                if (equal(data2, schema3[i3])) {
                                  valid3 = true;
                                  break;
                                }
                              if (!valid3) {
                                validate.errors = [
                                  {
                                    keyword: 'enum',
                                    dataPath:
                                      (dataPath || '') + '.textAlignment.value',
                                    schemaPath:
                                      '#/definitions/textAlignmentProperty/properties/value/enum',
                                    params: {
                                      allowedValues: schema3,
                                    },
                                    message:
                                      'should be equal to one of the allowed values',
                                  },
                                ];
                                return false;
                              } else {
                              }
                              if (errors === errs_3) {
                              }
                              var valid3 = errors === errs_3;
                            }
                            if (valid3) {
                            }
                          }
                          if (errs__2 == errors) {
                          }
                        }
                      } else {
                        validate.errors = [
                          {
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.textAlignment',
                            schemaPath:
                              '#/definitions/textAlignmentProperty/type',
                            params: {
                              type: 'object',
                            },
                            message: 'should be object',
                          },
                        ];
                        return false;
                      }
                      if (errors === errs_2) {
                      }
                      var valid2 = errors === errs_2;
                      if (valid2) {
                      }
                      if (errors === errs_1) {
                      }
                      var valid1 = errors === errs_1;
                    }
                    if (valid1) {
                      var data1 = data.value;
                      if (data1 === undefined) {
                        valid1 = false;
                        validate.errors = [
                          {
                            keyword: 'required',
                            dataPath: (dataPath || '') + '',
                            schemaPath: '#/required',
                            params: {
                              missingProperty: 'value',
                            },
                            message: "should have required property 'value'",
                          },
                        ];
                        return false;
                      } else {
                        var errs_1 = errors;
                        var errs__1 = errors;
                        var valid1 = false;
                        var errs_2 = errors;
                        if (
                          !refVal[10](
                            data1,
                            (dataPath || '') + '.value',
                            data,
                            'value',
                            rootData,
                          )
                        ) {
                          if (vErrors === null) vErrors = refVal[10].errors;
                          else vErrors = vErrors.concat(refVal[10].errors);
                          errors = vErrors.length;
                        } else {
                        }
                        if (errors === errs_2) {
                        }
                        var valid2 = errors === errs_2;
                        valid1 = valid1 || valid2;
                        if (!valid1) {
                          var errs_2 = errors;
                          if (
                            !refVal[3](
                              data1,
                              (dataPath || '') + '.value',
                              data,
                              'value',
                              rootData,
                            )
                          ) {
                            if (vErrors === null) vErrors = refVal[3].errors;
                            else vErrors = vErrors.concat(refVal[3].errors);
                            errors = vErrors.length;
                          } else {
                          }
                          if (errors === errs_2) {
                          }
                          var valid2 = errors === errs_2;
                          valid1 = valid1 || valid2;
                          if (!valid1) {
                          }
                        }
                        if (!valid1) {
                          var err = {
                            keyword: 'anyOf',
                            dataPath: (dataPath || '') + '.value',
                            schemaPath: '#/properties/value/anyOf',
                            params: {},
                            message: 'should match some schema in anyOf',
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                          validate.errors = vErrors;
                          return false;
                        } else {
                          errors = errs__1;
                          if (vErrors !== null) {
                            if (errs__1) vErrors.length = errs__1;
                            else vErrors = null;
                          }
                        }
                        if (errors === errs_1) {
                        }
                        var valid1 = errors === errs_1;
                      }
                      if (valid1) {
                        if (data.dataDetectorTypes === undefined) {
                          valid1 = true;
                        } else {
                          var errs_1 = errors;
                          if (
                            !refVal[21](
                              data.dataDetectorTypes,
                              (dataPath || '') + '.dataDetectorTypes',
                              data,
                              'dataDetectorTypes',
                              rootData,
                            )
                          ) {
                            if (vErrors === null) vErrors = refVal[21].errors;
                            else vErrors = vErrors.concat(refVal[21].errors);
                            errors = vErrors.length;
                          } else {
                          }
                          if (errors === errs_1) {
                          }
                          var valid1 = errors === errs_1;
                        }
                        if (valid1) {
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          if (errs__0 == errors) {
          }
        }
      } else {
        validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/type',
            params: {
              type: 'object',
            },
            message: 'should be object',
          },
        ];
        return false;
      }
      if (errors === 0) {
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal25.schema = {
    type: 'object',
    properties: {
      changeMessage: {
        $ref: '#/definitions/localizedStringProperty',
      },
      attributedValue: {
        anyOf: [
          {
            $ref: '#/definitions/localizedStringProperty',
          },
          {
            $ref: '#/definitions/stringProperty',
          },
        ],
      },
      key: {
        $ref: '#/definitions/stringProperty',
      },
      label: {
        $ref: '#/definitions/localizedStringProperty',
      },
      textAlignment: {
        $ref: '#/definitions/textAlignmentProperty',
      },
      value: {
        anyOf: [
          {
            $ref: '#/definitions/localizedStringProperty',
          },
          {
            $ref: '#/definitions/stringProperty',
          },
        ],
      },
      dataDetectorTypes: {
        $ref: '#/definitions/dataDetectorTypes',
      },
    },
    additionalProperties: false,
    required: ['key', 'value'],
  };
  refVal25.errors = null;
  refVal[25] = refVal25;
  var refVal26 = (function() {
    var pattern0 = new RegExp('string');
    var pattern1 = new RegExp(
      '#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^}]{4,}\\}',
    );
    var pattern2 = new RegExp(
      '^((?!(#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^\\}]{4,}\\}))[\\s\\S])*$',
    );
    var pattern3 = new RegExp('number');
    var pattern4 = new RegExp('^#\\{.+\\}$');
    var pattern5 = new RegExp('[a-z]{2}');
    var pattern6 = new RegExp(
      '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
    );
    var pattern7 = new RegExp(
      '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
    );
    var pattern8 = new RegExp('boolean');
    return function validate(
      data,
      dataPath,
      parentData,
      parentDataProperty,
      rootData,
    ) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if (data && typeof data === 'object' && !Array.isArray(data)) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          var data1 = data.type;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [
              {
                keyword: 'required',
                dataPath: (dataPath || '') + '',
                schemaPath: '#/required',
                params: {
                  missingProperty: 'type',
                },
                message: "should have required property 'type'",
              },
            ];
            return false;
          } else {
            var errs_1 = errors;
            var errs_2 = errors;
            if (typeof data1 !== 'string') {
              validate.errors = [
                {
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.type',
                  schemaPath: '#/definitions/objectType/type',
                  params: {
                    type: 'string',
                  },
                  message: 'should be string',
                },
              ];
              return false;
            }
            var schema2 = refVal[15].enum;
            var valid2;
            valid2 = false;
            for (var i2 = 0; i2 < schema2.length; i2++)
              if (equal(data1, schema2[i2])) {
                valid2 = true;
                break;
              }
            if (!valid2) {
              validate.errors = [
                {
                  keyword: 'enum',
                  dataPath: (dataPath || '') + '.type',
                  schemaPath: '#/definitions/objectType/enum',
                  params: {
                    allowedValues: schema2,
                  },
                  message: 'should be equal to one of the allowed values',
                },
              ];
              return false;
            } else {
            }
            if (errors === errs_2) {
            }
            var valid2 = errors === errs_2;
            if (valid2) {
            }
            if (errors === errs_1) {
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            var data1 = data.properties;
            if (data1 === undefined) {
              valid1 = false;
              validate.errors = [
                {
                  keyword: 'required',
                  dataPath: (dataPath || '') + '',
                  schemaPath: '#/required',
                  params: {
                    missingProperty: 'properties',
                  },
                  message: "should have required property 'properties'",
                },
              ];
              return false;
            } else {
              var errs_1 = errors;
              if (data1 && typeof data1 === 'object' && !Array.isArray(data1)) {
                var errs__1 = errors;
                var valid2 = true;
                if (data1.auxiliaryFields === undefined) {
                  valid2 = true;
                } else {
                  var errs_2 = errors;
                  if (
                    !refVal[16](
                      data1.auxiliaryFields,
                      (dataPath || '') + '.properties.auxiliaryFields',
                      data1,
                      'auxiliaryFields',
                      rootData,
                    )
                  ) {
                    if (vErrors === null) vErrors = refVal[16].errors;
                    else vErrors = vErrors.concat(refVal[16].errors);
                    errors = vErrors.length;
                  } else {
                  }
                  if (errors === errs_2) {
                  }
                  var valid2 = errors === errs_2;
                }
                if (valid2) {
                  if (data1.backFields === undefined) {
                    valid2 = true;
                  } else {
                    var errs_2 = errors;
                    if (
                      !refVal[16](
                        data1.backFields,
                        (dataPath || '') + '.properties.backFields',
                        data1,
                        'backFields',
                        rootData,
                      )
                    ) {
                      if (vErrors === null) vErrors = refVal[16].errors;
                      else vErrors = vErrors.concat(refVal[16].errors);
                      errors = vErrors.length;
                    } else {
                    }
                    if (errors === errs_2) {
                    }
                    var valid2 = errors === errs_2;
                  }
                  if (valid2) {
                    if (data1.headerFields === undefined) {
                      valid2 = true;
                    } else {
                      var errs_2 = errors;
                      if (
                        !refVal[16](
                          data1.headerFields,
                          (dataPath || '') + '.properties.headerFields',
                          data1,
                          'headerFields',
                          rootData,
                        )
                      ) {
                        if (vErrors === null) vErrors = refVal[16].errors;
                        else vErrors = vErrors.concat(refVal[16].errors);
                        errors = vErrors.length;
                      } else {
                      }
                      if (errors === errs_2) {
                      }
                      var valid2 = errors === errs_2;
                    }
                    if (valid2) {
                      if (data1.primaryFields === undefined) {
                        valid2 = true;
                      } else {
                        var errs_2 = errors;
                        if (
                          !refVal[16](
                            data1.primaryFields,
                            (dataPath || '') + '.properties.primaryFields',
                            data1,
                            'primaryFields',
                            rootData,
                          )
                        ) {
                          if (vErrors === null) vErrors = refVal[16].errors;
                          else vErrors = vErrors.concat(refVal[16].errors);
                          errors = vErrors.length;
                        } else {
                        }
                        if (errors === errs_2) {
                        }
                        var valid2 = errors === errs_2;
                      }
                      if (valid2) {
                        if (data1.secondaryFields === undefined) {
                          valid2 = true;
                        } else {
                          var errs_2 = errors;
                          if (
                            !refVal[16](
                              data1.secondaryFields,
                              (dataPath || '') + '.properties.secondaryFields',
                              data1,
                              'secondaryFields',
                              rootData,
                            )
                          ) {
                            if (vErrors === null) vErrors = refVal[16].errors;
                            else vErrors = vErrors.concat(refVal[16].errors);
                            errors = vErrors.length;
                          } else {
                          }
                          if (errors === errs_2) {
                          }
                          var valid2 = errors === errs_2;
                        }
                        if (valid2) {
                        }
                      }
                    }
                  }
                }
                if (errs__1 == errors) {
                }
              } else {
                validate.errors = [
                  {
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.properties',
                    schemaPath: '#/properties/properties/type',
                    params: {
                      type: 'object',
                    },
                    message: 'should be object',
                  },
                ];
                return false;
              }
              if (errors === errs_1) {
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
            }
          }
          if (errs__0 == errors) {
          }
        }
      } else {
        validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/type',
            params: {
              type: 'object',
            },
            message: 'should be object',
          },
        ];
        return false;
      }
      if (errors === 0) {
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal26.schema = {
    type: 'object',
    properties: {
      type: {
        $ref: '#/definitions/objectType',
      },
      properties: {
        type: 'object',
        properties: {
          auxiliaryFields: {
            $ref: '#/definitions/fields',
          },
          backFields: {
            $ref: '#/definitions/fields',
          },
          headerFields: {
            $ref: '#/definitions/fields',
          },
          primaryFields: {
            $ref: '#/definitions/fields',
          },
          secondaryFields: {
            $ref: '#/definitions/fields',
          },
        },
      },
    },
    required: ['type', 'properties'],
  };
  refVal26.errors = null;
  refVal[26] = refVal26;
  var refVal27 = {
    oneOf: [
      {
        type: 'string',
      },
      {
        type: 'object',
      },
    ],
  };
  refVal[27] = refVal27;
  return function validate(
    data,
    dataPath,
    parentData,
    parentDataProperty,
    rootData,
  ) {
    'use strict' /*# sourceURL=http://yourpass.cz/schemas/template.json */;
    var vErrors = null;
    var errors = 0;
    if (rootData === undefined) rootData = data;
    if (data && typeof data === 'object' && !Array.isArray(data)) {
      var errs__0 = errors;
      var valid1 = true;
      if (data.id === undefined) {
        valid1 = true;
      } else {
        var errs_1 = errors;
        if (typeof data.id !== 'string') {
          validate.errors = [
            {
              keyword: 'type',
              dataPath: (dataPath || '') + '.id',
              schemaPath: '#/properties/id/type',
              params: {
                type: 'string',
              },
              message: 'should be string',
            },
          ];
          return false;
        }
        var valid1 = errors === errs_1;
      }
      if (valid1) {
        if (data.name === undefined) {
          valid1 = true;
        } else {
          var errs_1 = errors;
          if (typeof data.name !== 'string') {
            validate.errors = [
              {
                keyword: 'type',
                dataPath: (dataPath || '') + '.name',
                schemaPath: '#/properties/name/type',
                params: {
                  type: 'string',
                },
                message: 'should be string',
              },
            ];
            return false;
          }
          var valid1 = errors === errs_1;
        }
        if (valid1) {
          var data1 = data.jsonTemplate;
          if (data1 === undefined) {
            valid1 = true;
          } else {
            var errs_1 = errors;
            if (data1 && typeof data1 === 'object' && !Array.isArray(data1)) {
              if (true) {
                var errs__1 = errors;
                var valid2 = true;
                var data2 = data1.beacons;
                if (data2 === undefined) {
                  valid2 = true;
                } else {
                  var errs_2 = errors;
                  if (
                    data2 &&
                    typeof data2 === 'object' &&
                    !Array.isArray(data2)
                  ) {
                    var errs__2 = errors;
                    var valid3 = true;
                    var data3 = data2.type;
                    if (data3 === undefined) {
                      valid3 = true;
                    } else {
                      var errs_3 = errors;
                      var errs_4 = errors;
                      if (typeof data3 !== 'string') {
                        validate.errors = [
                          {
                            keyword: 'type',
                            dataPath:
                              (dataPath || '') + '.jsonTemplate.beacons.type',
                            schemaPath: '#/definitions/arrayType/type',
                            params: {
                              type: 'string',
                            },
                            message: 'should be string',
                          },
                        ];
                        return false;
                      }
                      var schema4 = refVal1.enum;
                      var valid4;
                      valid4 = false;
                      for (var i4 = 0; i4 < schema4.length; i4++)
                        if (equal(data3, schema4[i4])) {
                          valid4 = true;
                          break;
                        }
                      if (!valid4) {
                        validate.errors = [
                          {
                            keyword: 'enum',
                            dataPath:
                              (dataPath || '') + '.jsonTemplate.beacons.type',
                            schemaPath: '#/definitions/arrayType/enum',
                            params: {
                              allowedValues: schema4,
                            },
                            message:
                              'should be equal to one of the allowed values',
                          },
                        ];
                        return false;
                      } else {
                      }
                      if (errors === errs_4) {
                      }
                      var valid4 = errors === errs_4;
                      if (valid4) {
                      }
                      if (errors === errs_3) {
                      }
                      var valid3 = errors === errs_3;
                    }
                    if (valid3) {
                      var data3 = data2.items;
                      if (data3 === undefined) {
                        valid3 = true;
                      } else {
                        var errs_3 = errors;
                        if (Array.isArray(data3)) {
                          var errs__3 = errors;
                          var valid3;
                          for (var i3 = 0; i3 < data3.length; i3++) {
                            var errs_4 = errors;
                            if (
                              !refVal2(
                                data3[i3],
                                (dataPath || '') +
                                  '.jsonTemplate.beacons.items[' +
                                  i3 +
                                  ']',
                                data3,
                                i3,
                                rootData,
                              )
                            ) {
                              if (vErrors === null) vErrors = refVal2.errors;
                              else vErrors = vErrors.concat(refVal2.errors);
                              errors = vErrors.length;
                            } else {
                            }
                            if (errors === errs_4) {
                            }
                            var valid4 = errors === errs_4;
                            if (!valid4) break;
                          }
                          if (errs__3 == errors) {
                          }
                        } else {
                          validate.errors = [
                            {
                              keyword: 'type',
                              dataPath:
                                (dataPath || '') +
                                '.jsonTemplate.beacons.items',
                              schemaPath:
                                '#/properties/jsonTemplate/properties/beacons/properties/items/type',
                              params: {
                                type: 'array',
                              },
                              message: 'should be array',
                            },
                          ];
                          return false;
                        }
                        if (errors === errs_3) {
                        }
                        var valid3 = errors === errs_3;
                      }
                      if (valid3) {
                      }
                    }
                    if (errs__2 == errors) {
                    }
                  } else {
                    validate.errors = [
                      {
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.jsonTemplate.beacons',
                        schemaPath:
                          '#/properties/jsonTemplate/properties/beacons/type',
                        params: {
                          type: 'object',
                        },
                        message: 'should be object',
                      },
                    ];
                    return false;
                  }
                  if (errors === errs_2) {
                  }
                  var valid2 = errors === errs_2;
                }
                if (valid2) {
                  var data2 = data1.barcodes;
                  if (data2 === undefined) {
                    valid2 = true;
                  } else {
                    var errs_2 = errors;
                    if (
                      data2 &&
                      typeof data2 === 'object' &&
                      !Array.isArray(data2)
                    ) {
                      var errs__2 = errors;
                      var valid3 = true;
                      var data3 = data2.type;
                      if (data3 === undefined) {
                        valid3 = true;
                      } else {
                        var errs_3 = errors;
                        var errs_4 = errors;
                        if (typeof data3 !== 'string') {
                          validate.errors = [
                            {
                              keyword: 'type',
                              dataPath:
                                (dataPath || '') +
                                '.jsonTemplate.barcodes.type',
                              schemaPath: '#/definitions/arrayType/type',
                              params: {
                                type: 'string',
                              },
                              message: 'should be string',
                            },
                          ];
                          return false;
                        }
                        var schema4 = refVal[1].enum;
                        var valid4;
                        valid4 = false;
                        for (var i4 = 0; i4 < schema4.length; i4++)
                          if (equal(data3, schema4[i4])) {
                            valid4 = true;
                            break;
                          }
                        if (!valid4) {
                          validate.errors = [
                            {
                              keyword: 'enum',
                              dataPath:
                                (dataPath || '') +
                                '.jsonTemplate.barcodes.type',
                              schemaPath: '#/definitions/arrayType/enum',
                              params: {
                                allowedValues: schema4,
                              },
                              message:
                                'should be equal to one of the allowed values',
                            },
                          ];
                          return false;
                        } else {
                        }
                        if (errors === errs_4) {
                        }
                        var valid4 = errors === errs_4;
                        if (valid4) {
                        }
                        if (errors === errs_3) {
                        }
                        var valid3 = errors === errs_3;
                      }
                      if (valid3) {
                        var data3 = data2.items;
                        if (data3 === undefined) {
                          valid3 = true;
                        } else {
                          var errs_3 = errors;
                          if (Array.isArray(data3)) {
                            var errs__3 = errors;
                            var valid3;
                            for (var i3 = 0; i3 < data3.length; i3++) {
                              var errs_4 = errors;
                              if (
                                !refVal7(
                                  data3[i3],
                                  (dataPath || '') +
                                    '.jsonTemplate.barcodes.items[' +
                                    i3 +
                                    ']',
                                  data3,
                                  i3,
                                  rootData,
                                )
                              ) {
                                if (vErrors === null) vErrors = refVal7.errors;
                                else vErrors = vErrors.concat(refVal7.errors);
                                errors = vErrors.length;
                              } else {
                              }
                              if (errors === errs_4) {
                              }
                              var valid4 = errors === errs_4;
                              if (!valid4) break;
                            }
                            if (errs__3 == errors) {
                            }
                          } else {
                            validate.errors = [
                              {
                                keyword: 'type',
                                dataPath:
                                  (dataPath || '') +
                                  '.jsonTemplate.barcodes.items',
                                schemaPath:
                                  '#/properties/jsonTemplate/properties/barcodes/properties/items/type',
                                params: {
                                  type: 'array',
                                },
                                message: 'should be array',
                              },
                            ];
                            return false;
                          }
                          if (errors === errs_3) {
                          }
                          var valid3 = errors === errs_3;
                        }
                        if (valid3) {
                        }
                      }
                      if (errs__2 == errors) {
                      }
                    } else {
                      validate.errors = [
                        {
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.jsonTemplate.barcodes',
                          schemaPath:
                            '#/properties/jsonTemplate/properties/barcodes/type',
                          params: {
                            type: 'object',
                          },
                          message: 'should be object',
                        },
                      ];
                      return false;
                    }
                    if (errors === errs_2) {
                    }
                    var valid2 = errors === errs_2;
                  }
                  if (valid2) {
                    var data2 = data1.locations;
                    if (data2 === undefined) {
                      valid2 = true;
                    } else {
                      var errs_2 = errors;
                      if (
                        data2 &&
                        typeof data2 === 'object' &&
                        !Array.isArray(data2)
                      ) {
                        var errs__2 = errors;
                        var valid3 = true;
                        var data3 = data2.type;
                        if (data3 === undefined) {
                          valid3 = true;
                        } else {
                          var errs_3 = errors;
                          var errs_4 = errors;
                          if (typeof data3 !== 'string') {
                            validate.errors = [
                              {
                                keyword: 'type',
                                dataPath:
                                  (dataPath || '') +
                                  '.jsonTemplate.locations.type',
                                schemaPath: '#/definitions/arrayType/type',
                                params: {
                                  type: 'string',
                                },
                                message: 'should be string',
                              },
                            ];
                            return false;
                          }
                          var schema4 = refVal[1].enum;
                          var valid4;
                          valid4 = false;
                          for (var i4 = 0; i4 < schema4.length; i4++)
                            if (equal(data3, schema4[i4])) {
                              valid4 = true;
                              break;
                            }
                          if (!valid4) {
                            validate.errors = [
                              {
                                keyword: 'enum',
                                dataPath:
                                  (dataPath || '') +
                                  '.jsonTemplate.locations.type',
                                schemaPath: '#/definitions/arrayType/enum',
                                params: {
                                  allowedValues: schema4,
                                },
                                message:
                                  'should be equal to one of the allowed values',
                              },
                            ];
                            return false;
                          } else {
                          }
                          if (errors === errs_4) {
                          }
                          var valid4 = errors === errs_4;
                          if (valid4) {
                          }
                          if (errors === errs_3) {
                          }
                          var valid3 = errors === errs_3;
                        }
                        if (valid3) {
                          var data3 = data2.items;
                          if (data3 === undefined) {
                            valid3 = true;
                          } else {
                            var errs_3 = errors;
                            if (Array.isArray(data3)) {
                              var errs__3 = errors;
                              var valid3;
                              for (var i3 = 0; i3 < data3.length; i3++) {
                                var errs_4 = errors;
                                if (
                                  !refVal8(
                                    data3[i3],
                                    (dataPath || '') +
                                      '.jsonTemplate.locations.items[' +
                                      i3 +
                                      ']',
                                    data3,
                                    i3,
                                    rootData,
                                  )
                                ) {
                                  if (vErrors === null)
                                    vErrors = refVal8.errors;
                                  else vErrors = vErrors.concat(refVal8.errors);
                                  errors = vErrors.length;
                                } else {
                                }
                                if (errors === errs_4) {
                                }
                                var valid4 = errors === errs_4;
                                if (!valid4) break;
                              }
                              if (errs__3 == errors) {
                              }
                            } else {
                              validate.errors = [
                                {
                                  keyword: 'type',
                                  dataPath:
                                    (dataPath || '') +
                                    '.jsonTemplate.locations.items',
                                  schemaPath:
                                    '#/properties/jsonTemplate/properties/locations/properties/items/type',
                                  params: {
                                    type: 'array',
                                  },
                                  message: 'should be array',
                                },
                              ];
                              return false;
                            }
                            if (errors === errs_3) {
                            }
                            var valid3 = errors === errs_3;
                          }
                          if (valid3) {
                          }
                        }
                        if (errs__2 == errors) {
                        }
                      } else {
                        validate.errors = [
                          {
                            keyword: 'type',
                            dataPath:
                              (dataPath || '') + '.jsonTemplate.locations',
                            schemaPath:
                              '#/properties/jsonTemplate/properties/locations/type',
                            params: {
                              type: 'object',
                            },
                            message: 'should be object',
                          },
                        ];
                        return false;
                      }
                      if (errors === errs_2) {
                      }
                      var valid2 = errors === errs_2;
                    }
                    if (valid2) {
                      if (data1.logoText === undefined) {
                        valid2 = true;
                      } else {
                        var errs_2 = errors;
                        if (
                          !refVal10(
                            data1.logoText,
                            (dataPath || '') + '.jsonTemplate.logoText',
                            data1,
                            'logoText',
                            rootData,
                          )
                        ) {
                          if (vErrors === null) vErrors = refVal10.errors;
                          else vErrors = vErrors.concat(refVal10.errors);
                          errors = vErrors.length;
                        } else {
                        }
                        if (errors === errs_2) {
                        }
                        var valid2 = errors === errs_2;
                      }
                      if (valid2) {
                        var data2 = data1.labelColor;
                        if (data2 === undefined) {
                          valid2 = true;
                        } else {
                          var errs_2 = errors;
                          var errs_3 = errors;
                          if (
                            !data2 ||
                            typeof data2 !== 'object' ||
                            Array.isArray(data2)
                          ) {
                            validate.errors = [
                              {
                                keyword: 'type',
                                dataPath:
                                  (dataPath || '') + '.jsonTemplate.labelColor',
                                schemaPath: '#/definitions/colorProperty/type',
                                params: {
                                  type: 'object',
                                },
                                message: 'should be object',
                              },
                            ];
                            return false;
                          }
                          var errs__3 = errors,
                            prevValid3 = false,
                            valid3 = false,
                            passingSchemas3 = null;
                          var errs_4 = errors;
                          if (
                            data2 &&
                            typeof data2 === 'object' &&
                            !Array.isArray(data2)
                          ) {
                            if (true) {
                              var errs__4 = errors;
                              var valid5 = true;
                              var data3 = data2.type;
                              if (data3 === undefined) {
                                valid5 = false;
                                var err = {
                                  keyword: 'required',
                                  dataPath:
                                    (dataPath || '') +
                                    '.jsonTemplate.labelColor',
                                  schemaPath:
                                    '#/definitions/colorProperty/oneOf/0/required',
                                  params: {
                                    missingProperty: 'type',
                                  },
                                  message:
                                    "should have required property 'type'",
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                              } else {
                                var errs_5 = errors;
                                if (typeof data3 === 'string') {
                                  if (!pattern0.test(data3)) {
                                    var err = {
                                      keyword: 'pattern',
                                      dataPath:
                                        (dataPath || '') +
                                        '.jsonTemplate.labelColor.type',
                                      schemaPath:
                                        '#/definitions/colorProperty/oneOf/0/properties/type/pattern',
                                      params: {
                                        pattern: 'string',
                                      },
                                      message: 'should match pattern "string"',
                                    };
                                    if (vErrors === null) vErrors = [err];
                                    else vErrors.push(err);
                                    errors++;
                                  } else {
                                  }
                                } else {
                                  var err = {
                                    keyword: 'type',
                                    dataPath:
                                      (dataPath || '') +
                                      '.jsonTemplate.labelColor.type',
                                    schemaPath:
                                      '#/definitions/colorProperty/oneOf/0/properties/type/type',
                                    params: {
                                      type: 'string',
                                    },
                                    message: 'should be string',
                                  };
                                  if (vErrors === null) vErrors = [err];
                                  else vErrors.push(err);
                                  errors++;
                                }
                                if (errors === errs_5) {
                                }
                                var valid5 = errors === errs_5;
                              }
                              if (valid5) {
                                var data3 = data2.value;
                                if (data3 === undefined) {
                                  valid5 = false;
                                  var err = {
                                    keyword: 'required',
                                    dataPath:
                                      (dataPath || '') +
                                      '.jsonTemplate.labelColor',
                                    schemaPath:
                                      '#/definitions/colorProperty/oneOf/0/required',
                                    params: {
                                      missingProperty: 'value',
                                    },
                                    message:
                                      "should have required property 'value'",
                                  };
                                  if (vErrors === null) vErrors = [err];
                                  else vErrors.push(err);
                                  errors++;
                                } else {
                                  var errs_5 = errors;
                                  if (typeof data3 === 'string') {
                                    if (!pattern6.test(data3)) {
                                      var err = {
                                        keyword: 'pattern',
                                        dataPath:
                                          (dataPath || '') +
                                          '.jsonTemplate.labelColor.value',
                                        schemaPath:
                                          '#/definitions/colorProperty/oneOf/0/properties/value/pattern',
                                        params: {
                                          pattern:
                                            '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
                                        },
                                        message:
                                          'should match pattern "^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$"',
                                      };
                                      if (vErrors === null) vErrors = [err];
                                      else vErrors.push(err);
                                      errors++;
                                    } else {
                                    }
                                  } else {
                                    var err = {
                                      keyword: 'type',
                                      dataPath:
                                        (dataPath || '') +
                                        '.jsonTemplate.labelColor.value',
                                      schemaPath:
                                        '#/definitions/colorProperty/oneOf/0/properties/value/type',
                                      params: {
                                        type: 'string',
                                      },
                                      message: 'should be string',
                                    };
                                    if (vErrors === null) vErrors = [err];
                                    else vErrors.push(err);
                                    errors++;
                                  }
                                  if (errors === errs_5) {
                                  }
                                  var valid5 = errors === errs_5;
                                }
                                if (valid5) {
                                }
                              }
                              if (errs__4 == errors) {
                              }
                            }
                          }
                          if (errors === errs_4) {
                          }
                          var valid4 = errors === errs_4;
                          if (valid4) {
                            valid3 = prevValid3 = true;
                            passingSchemas3 = 0;
                          }
                          var errs_4 = errors;
                          if (
                            data2 &&
                            typeof data2 === 'object' &&
                            !Array.isArray(data2)
                          ) {
                            if (true) {
                              var errs__4 = errors;
                              var valid5 = true;
                              var data3 = data2.type;
                              if (data3 === undefined) {
                                valid5 = false;
                                var err = {
                                  keyword: 'required',
                                  dataPath:
                                    (dataPath || '') +
                                    '.jsonTemplate.labelColor',
                                  schemaPath:
                                    '#/definitions/colorProperty/oneOf/1/required',
                                  params: {
                                    missingProperty: 'type',
                                  },
                                  message:
                                    "should have required property 'type'",
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                              } else {
                                var errs_5 = errors;
                                if (typeof data3 === 'string') {
                                  if (!pattern0.test(data3)) {
                                    var err = {
                                      keyword: 'pattern',
                                      dataPath:
                                        (dataPath || '') +
                                        '.jsonTemplate.labelColor.type',
                                      schemaPath:
                                        '#/definitions/colorProperty/oneOf/1/properties/type/pattern',
                                      params: {
                                        pattern: 'string',
                                      },
                                      message: 'should match pattern "string"',
                                    };
                                    if (vErrors === null) vErrors = [err];
                                    else vErrors.push(err);
                                    errors++;
                                  } else {
                                  }
                                } else {
                                  var err = {
                                    keyword: 'type',
                                    dataPath:
                                      (dataPath || '') +
                                      '.jsonTemplate.labelColor.type',
                                    schemaPath:
                                      '#/definitions/colorProperty/oneOf/1/properties/type/type',
                                    params: {
                                      type: 'string',
                                    },
                                    message: 'should be string',
                                  };
                                  if (vErrors === null) vErrors = [err];
                                  else vErrors.push(err);
                                  errors++;
                                }
                                if (errors === errs_5) {
                                }
                                var valid5 = errors === errs_5;
                              }
                              if (valid5) {
                                var data3 = data2.value;
                                if (data3 === undefined) {
                                  valid5 = false;
                                  var err = {
                                    keyword: 'required',
                                    dataPath:
                                      (dataPath || '') +
                                      '.jsonTemplate.labelColor',
                                    schemaPath:
                                      '#/definitions/colorProperty/oneOf/1/required',
                                    params: {
                                      missingProperty: 'value',
                                    },
                                    message:
                                      "should have required property 'value'",
                                  };
                                  if (vErrors === null) vErrors = [err];
                                  else vErrors.push(err);
                                  errors++;
                                } else {
                                  var errs_5 = errors;
                                  if (typeof data3 === 'string') {
                                    if (!pattern4.test(data3)) {
                                      var err = {
                                        keyword: 'pattern',
                                        dataPath:
                                          (dataPath || '') +
                                          '.jsonTemplate.labelColor.value',
                                        schemaPath:
                                          '#/definitions/colorProperty/oneOf/1/properties/value/pattern',
                                        params: {
                                          pattern: '^#\\{.+\\}$',
                                        },
                                        message:
                                          'should match pattern "^#\\{.+\\}$"',
                                      };
                                      if (vErrors === null) vErrors = [err];
                                      else vErrors.push(err);
                                      errors++;
                                    } else {
                                    }
                                  } else {
                                    var err = {
                                      keyword: 'type',
                                      dataPath:
                                        (dataPath || '') +
                                        '.jsonTemplate.labelColor.value',
                                      schemaPath:
                                        '#/definitions/colorProperty/oneOf/1/properties/value/type',
                                      params: {
                                        type: 'string',
                                      },
                                      message: 'should be string',
                                    };
                                    if (vErrors === null) vErrors = [err];
                                    else vErrors.push(err);
                                    errors++;
                                  }
                                  if (errors === errs_5) {
                                  }
                                  var valid5 = errors === errs_5;
                                }
                                if (valid5) {
                                  var data3 = data2.fallback;
                                  if (data3 === undefined) {
                                    valid5 = false;
                                    var err = {
                                      keyword: 'required',
                                      dataPath:
                                        (dataPath || '') +
                                        '.jsonTemplate.labelColor',
                                      schemaPath:
                                        '#/definitions/colorProperty/oneOf/1/required',
                                      params: {
                                        missingProperty: 'fallback',
                                      },
                                      message:
                                        "should have required property 'fallback'",
                                    };
                                    if (vErrors === null) vErrors = [err];
                                    else vErrors.push(err);
                                    errors++;
                                  } else {
                                    var errs_5 = errors;
                                    if (typeof data3 === 'string') {
                                      if (!pattern6.test(data3)) {
                                        var err = {
                                          keyword: 'pattern',
                                          dataPath:
                                            (dataPath || '') +
                                            '.jsonTemplate.labelColor.fallback',
                                          schemaPath:
                                            '#/definitions/colorProperty/oneOf/1/properties/fallback/pattern',
                                          params: {
                                            pattern:
                                              '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
                                          },
                                          message:
                                            'should match pattern "^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$"',
                                        };
                                        if (vErrors === null) vErrors = [err];
                                        else vErrors.push(err);
                                        errors++;
                                      } else {
                                      }
                                    } else {
                                      var err = {
                                        keyword: 'type',
                                        dataPath:
                                          (dataPath || '') +
                                          '.jsonTemplate.labelColor.fallback',
                                        schemaPath:
                                          '#/definitions/colorProperty/oneOf/1/properties/fallback/type',
                                        params: {
                                          type: 'string',
                                        },
                                        message: 'should be string',
                                      };
                                      if (vErrors === null) vErrors = [err];
                                      else vErrors.push(err);
                                      errors++;
                                    }
                                    if (errors === errs_5) {
                                    }
                                    var valid5 = errors === errs_5;
                                  }
                                  if (valid5) {
                                  }
                                }
                              }
                              if (errs__4 == errors) {
                              }
                            }
                          }
                          if (errors === errs_4) {
                          }
                          var valid4 = errors === errs_4;
                          if (valid4 && prevValid3) {
                            valid3 = false;
                            passingSchemas3 = [passingSchemas3, 1];
                          } else {
                            if (valid4) {
                              valid3 = prevValid3 = true;
                              passingSchemas3 = 1;
                            }
                          }
                          if (!valid3) {
                            var err = {
                              keyword: 'oneOf',
                              dataPath:
                                (dataPath || '') + '.jsonTemplate.labelColor',
                              schemaPath: '#/definitions/colorProperty/oneOf',
                              params: {
                                passingSchemas: passingSchemas3,
                              },
                              message:
                                'should match exactly one schema in oneOf',
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                            validate.errors = vErrors;
                            return false;
                          } else {
                            errors = errs__3;
                            if (vErrors !== null) {
                              if (errs__3) vErrors.length = errs__3;
                              else vErrors = null;
                            }
                          }
                          if (errors === errs_3) {
                          }
                          var valid3 = errors === errs_3;
                          if (valid3) {
                          }
                          if (errors === errs_2) {
                          }
                          var valid2 = errors === errs_2;
                        }
                        if (valid2) {
                          if (data1.description === undefined) {
                            valid2 = false;
                            validate.errors = [
                              {
                                keyword: 'required',
                                dataPath: (dataPath || '') + '.jsonTemplate',
                                schemaPath:
                                  '#/properties/jsonTemplate/required',
                                params: {
                                  missingProperty: 'description',
                                },
                                message:
                                  "should have required property 'description'",
                              },
                            ];
                            return false;
                          } else {
                            var errs_2 = errors;
                            if (
                              !refVal[10](
                                data1.description,
                                (dataPath || '') + '.jsonTemplate.description',
                                data1,
                                'description',
                                rootData,
                              )
                            ) {
                              if (vErrors === null) vErrors = refVal[10].errors;
                              else vErrors = vErrors.concat(refVal[10].errors);
                              errors = vErrors.length;
                            } else {
                            }
                            if (errors === errs_2) {
                            }
                            var valid2 = errors === errs_2;
                          }
                          if (valid2) {
                            var data2 = data1.maxDistance;
                            if (data2 === undefined) {
                              valid2 = true;
                            } else {
                              var errs_2 = errors;
                              var errs_3 = errors;
                              if (
                                !data2 ||
                                typeof data2 !== 'object' ||
                                Array.isArray(data2)
                              ) {
                                validate.errors = [
                                  {
                                    keyword: 'type',
                                    dataPath:
                                      (dataPath || '') +
                                      '.jsonTemplate.maxDistance',
                                    schemaPath:
                                      '#/definitions/numberProperty/type',
                                    params: {
                                      type: 'object',
                                    },
                                    message: 'should be object',
                                  },
                                ];
                                return false;
                              }
                              var errs__3 = errors,
                                prevValid3 = false,
                                valid3 = false,
                                passingSchemas3 = null;
                              var errs_4 = errors;
                              if (
                                data2 &&
                                typeof data2 === 'object' &&
                                !Array.isArray(data2)
                              ) {
                                if (true) {
                                  var errs__4 = errors;
                                  var valid5 = true;
                                  var data3 = data2.type;
                                  if (data3 === undefined) {
                                    valid5 = false;
                                    var err = {
                                      keyword: 'required',
                                      dataPath:
                                        (dataPath || '') +
                                        '.jsonTemplate.maxDistance',
                                      schemaPath:
                                        '#/definitions/numberProperty/oneOf/0/required',
                                      params: {
                                        missingProperty: 'type',
                                      },
                                      message:
                                        "should have required property 'type'",
                                    };
                                    if (vErrors === null) vErrors = [err];
                                    else vErrors.push(err);
                                    errors++;
                                  } else {
                                    var errs_5 = errors;
                                    if (typeof data3 === 'string') {
                                      if (!pattern3.test(data3)) {
                                        var err = {
                                          keyword: 'pattern',
                                          dataPath:
                                            (dataPath || '') +
                                            '.jsonTemplate.maxDistance.type',
                                          schemaPath:
                                            '#/definitions/numberProperty/oneOf/0/properties/type/pattern',
                                          params: {
                                            pattern: 'number',
                                          },
                                          message:
                                            'should match pattern "number"',
                                        };
                                        if (vErrors === null) vErrors = [err];
                                        else vErrors.push(err);
                                        errors++;
                                      } else {
                                      }
                                    } else {
                                      var err = {
                                        keyword: 'type',
                                        dataPath:
                                          (dataPath || '') +
                                          '.jsonTemplate.maxDistance.type',
                                        schemaPath:
                                          '#/definitions/numberProperty/oneOf/0/properties/type/type',
                                        params: {
                                          type: 'string',
                                        },
                                        message: 'should be string',
                                      };
                                      if (vErrors === null) vErrors = [err];
                                      else vErrors.push(err);
                                      errors++;
                                    }
                                    if (errors === errs_5) {
                                    }
                                    var valid5 = errors === errs_5;
                                  }
                                  if (valid5) {
                                    if (data2.value === undefined) {
                                      valid5 = false;
                                      var err = {
                                        keyword: 'required',
                                        dataPath:
                                          (dataPath || '') +
                                          '.jsonTemplate.maxDistance',
                                        schemaPath:
                                          '#/definitions/numberProperty/oneOf/0/required',
                                        params: {
                                          missingProperty: 'value',
                                        },
                                        message:
                                          "should have required property 'value'",
                                      };
                                      if (vErrors === null) vErrors = [err];
                                      else vErrors.push(err);
                                      errors++;
                                    } else {
                                      var errs_5 = errors;
                                      if (typeof data2.value !== 'number') {
                                        var err = {
                                          keyword: 'type',
                                          dataPath:
                                            (dataPath || '') +
                                            '.jsonTemplate.maxDistance.value',
                                          schemaPath:
                                            '#/definitions/numberProperty/oneOf/0/properties/value/type',
                                          params: {
                                            type: 'number',
                                          },
                                          message: 'should be number',
                                        };
                                        if (vErrors === null) vErrors = [err];
                                        else vErrors.push(err);
                                        errors++;
                                      }
                                      var valid5 = errors === errs_5;
                                    }
                                    if (valid5) {
                                    }
                                  }
                                  if (errs__4 == errors) {
                                  }
                                }
                              }
                              if (errors === errs_4) {
                              }
                              var valid4 = errors === errs_4;
                              if (valid4) {
                                valid3 = prevValid3 = true;
                                passingSchemas3 = 0;
                              }
                              var errs_4 = errors;
                              if (
                                data2 &&
                                typeof data2 === 'object' &&
                                !Array.isArray(data2)
                              ) {
                                if (true) {
                                  var errs__4 = errors;
                                  var valid5 = true;
                                  var data3 = data2.type;
                                  if (data3 === undefined) {
                                    valid5 = false;
                                    var err = {
                                      keyword: 'required',
                                      dataPath:
                                        (dataPath || '') +
                                        '.jsonTemplate.maxDistance',
                                      schemaPath:
                                        '#/definitions/numberProperty/oneOf/1/required',
                                      params: {
                                        missingProperty: 'type',
                                      },
                                      message:
                                        "should have required property 'type'",
                                    };
                                    if (vErrors === null) vErrors = [err];
                                    else vErrors.push(err);
                                    errors++;
                                  } else {
                                    var errs_5 = errors;
                                    if (typeof data3 === 'string') {
                                      if (!pattern3.test(data3)) {
                                        var err = {
                                          keyword: 'pattern',
                                          dataPath:
                                            (dataPath || '') +
                                            '.jsonTemplate.maxDistance.type',
                                          schemaPath:
                                            '#/definitions/numberProperty/oneOf/1/properties/type/pattern',
                                          params: {
                                            pattern: 'number',
                                          },
                                          message:
                                            'should match pattern "number"',
                                        };
                                        if (vErrors === null) vErrors = [err];
                                        else vErrors.push(err);
                                        errors++;
                                      } else {
                                      }
                                    } else {
                                      var err = {
                                        keyword: 'type',
                                        dataPath:
                                          (dataPath || '') +
                                          '.jsonTemplate.maxDistance.type',
                                        schemaPath:
                                          '#/definitions/numberProperty/oneOf/1/properties/type/type',
                                        params: {
                                          type: 'string',
                                        },
                                        message: 'should be string',
                                      };
                                      if (vErrors === null) vErrors = [err];
                                      else vErrors.push(err);
                                      errors++;
                                    }
                                    if (errors === errs_5) {
                                    }
                                    var valid5 = errors === errs_5;
                                  }
                                  if (valid5) {
                                    var data3 = data2.value;
                                    if (data3 === undefined) {
                                      valid5 = false;
                                      var err = {
                                        keyword: 'required',
                                        dataPath:
                                          (dataPath || '') +
                                          '.jsonTemplate.maxDistance',
                                        schemaPath:
                                          '#/definitions/numberProperty/oneOf/1/required',
                                        params: {
                                          missingProperty: 'value',
                                        },
                                        message:
                                          "should have required property 'value'",
                                      };
                                      if (vErrors === null) vErrors = [err];
                                      else vErrors.push(err);
                                      errors++;
                                    } else {
                                      var errs_5 = errors;
                                      if (typeof data3 === 'string') {
                                        if (!pattern4.test(data3)) {
                                          var err = {
                                            keyword: 'pattern',
                                            dataPath:
                                              (dataPath || '') +
                                              '.jsonTemplate.maxDistance.value',
                                            schemaPath:
                                              '#/definitions/numberProperty/oneOf/1/properties/value/pattern',
                                            params: {
                                              pattern: '^#\\{.+\\}$',
                                            },
                                            message:
                                              'should match pattern "^#\\{.+\\}$"',
                                          };
                                          if (vErrors === null) vErrors = [err];
                                          else vErrors.push(err);
                                          errors++;
                                        } else {
                                        }
                                      } else {
                                        var err = {
                                          keyword: 'type',
                                          dataPath:
                                            (dataPath || '') +
                                            '.jsonTemplate.maxDistance.value',
                                          schemaPath:
                                            '#/definitions/numberProperty/oneOf/1/properties/value/type',
                                          params: {
                                            type: 'string',
                                          },
                                          message: 'should be string',
                                        };
                                        if (vErrors === null) vErrors = [err];
                                        else vErrors.push(err);
                                        errors++;
                                      }
                                      if (errors === errs_5) {
                                      }
                                      var valid5 = errors === errs_5;
                                    }
                                    if (valid5) {
                                      if (data2.fallback === undefined) {
                                        valid5 = false;
                                        var err = {
                                          keyword: 'required',
                                          dataPath:
                                            (dataPath || '') +
                                            '.jsonTemplate.maxDistance',
                                          schemaPath:
                                            '#/definitions/numberProperty/oneOf/1/required',
                                          params: {
                                            missingProperty: 'fallback',
                                          },
                                          message:
                                            "should have required property 'fallback'",
                                        };
                                        if (vErrors === null) vErrors = [err];
                                        else vErrors.push(err);
                                        errors++;
                                      } else {
                                        var errs_5 = errors;
                                        if (
                                          typeof data2.fallback !== 'number'
                                        ) {
                                          var err = {
                                            keyword: 'type',
                                            dataPath:
                                              (dataPath || '') +
                                              '.jsonTemplate.maxDistance.fallback',
                                            schemaPath:
                                              '#/definitions/numberProperty/oneOf/1/properties/fallback/type',
                                            params: {
                                              type: 'number',
                                            },
                                            message: 'should be number',
                                          };
                                          if (vErrors === null) vErrors = [err];
                                          else vErrors.push(err);
                                          errors++;
                                        }
                                        var valid5 = errors === errs_5;
                                      }
                                      if (valid5) {
                                      }
                                    }
                                  }
                                  if (errs__4 == errors) {
                                  }
                                }
                              }
                              if (errors === errs_4) {
                              }
                              var valid4 = errors === errs_4;
                              if (valid4 && prevValid3) {
                                valid3 = false;
                                passingSchemas3 = [passingSchemas3, 1];
                              } else {
                                if (valid4) {
                                  valid3 = prevValid3 = true;
                                  passingSchemas3 = 1;
                                }
                              }
                              if (!valid3) {
                                var err = {
                                  keyword: 'oneOf',
                                  dataPath:
                                    (dataPath || '') +
                                    '.jsonTemplate.maxDistance',
                                  schemaPath:
                                    '#/definitions/numberProperty/oneOf',
                                  params: {
                                    passingSchemas: passingSchemas3,
                                  },
                                  message:
                                    'should match exactly one schema in oneOf',
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                                validate.errors = vErrors;
                                return false;
                              } else {
                                errors = errs__3;
                                if (vErrors !== null) {
                                  if (errs__3) vErrors.length = errs__3;
                                  else vErrors = null;
                                }
                              }
                              if (errors === errs_3) {
                              }
                              var valid3 = errors === errs_3;
                              if (valid3) {
                              }
                              if (errors === errs_2) {
                              }
                              var valid2 = errors === errs_2;
                            }
                            if (valid2) {
                              var data2 = data1.relevantDate;
                              if (data2 === undefined) {
                                valid2 = true;
                              } else {
                                var errs_2 = errors;
                                var errs_3 = errors;
                                if (
                                  !data2 ||
                                  typeof data2 !== 'object' ||
                                  Array.isArray(data2)
                                ) {
                                  validate.errors = [
                                    {
                                      keyword: 'type',
                                      dataPath:
                                        (dataPath || '') +
                                        '.jsonTemplate.relevantDate',
                                      schemaPath:
                                        '#/definitions/dateProperty/type',
                                      params: {
                                        type: 'object',
                                      },
                                      message: 'should be object',
                                    },
                                  ];
                                  return false;
                                }
                                var errs__3 = errors,
                                  prevValid3 = false,
                                  valid3 = false,
                                  passingSchemas3 = null;
                                var errs_4 = errors;
                                if (
                                  data2 &&
                                  typeof data2 === 'object' &&
                                  !Array.isArray(data2)
                                ) {
                                  if (true) {
                                    var errs__4 = errors;
                                    var valid5 = true;
                                    var data3 = data2.type;
                                    if (data3 === undefined) {
                                      valid5 = false;
                                      var err = {
                                        keyword: 'required',
                                        dataPath:
                                          (dataPath || '') +
                                          '.jsonTemplate.relevantDate',
                                        schemaPath:
                                          '#/definitions/dateProperty/oneOf/0/required',
                                        params: {
                                          missingProperty: 'type',
                                        },
                                        message:
                                          "should have required property 'type'",
                                      };
                                      if (vErrors === null) vErrors = [err];
                                      else vErrors.push(err);
                                      errors++;
                                    } else {
                                      var errs_5 = errors;
                                      if (typeof data3 === 'string') {
                                        if (!pattern0.test(data3)) {
                                          var err = {
                                            keyword: 'pattern',
                                            dataPath:
                                              (dataPath || '') +
                                              '.jsonTemplate.relevantDate.type',
                                            schemaPath:
                                              '#/definitions/dateProperty/oneOf/0/properties/type/pattern',
                                            params: {
                                              pattern: 'string',
                                            },
                                            message:
                                              'should match pattern "string"',
                                          };
                                          if (vErrors === null) vErrors = [err];
                                          else vErrors.push(err);
                                          errors++;
                                        } else {
                                        }
                                      } else {
                                        var err = {
                                          keyword: 'type',
                                          dataPath:
                                            (dataPath || '') +
                                            '.jsonTemplate.relevantDate.type',
                                          schemaPath:
                                            '#/definitions/dateProperty/oneOf/0/properties/type/type',
                                          params: {
                                            type: 'string',
                                          },
                                          message: 'should be string',
                                        };
                                        if (vErrors === null) vErrors = [err];
                                        else vErrors.push(err);
                                        errors++;
                                      }
                                      if (errors === errs_5) {
                                      }
                                      var valid5 = errors === errs_5;
                                    }
                                    if (valid5) {
                                      var data3 = data2.value;
                                      if (data3 === undefined) {
                                        valid5 = false;
                                        var err = {
                                          keyword: 'required',
                                          dataPath:
                                            (dataPath || '') +
                                            '.jsonTemplate.relevantDate',
                                          schemaPath:
                                            '#/definitions/dateProperty/oneOf/0/required',
                                          params: {
                                            missingProperty: 'value',
                                          },
                                          message:
                                            "should have required property 'value'",
                                        };
                                        if (vErrors === null) vErrors = [err];
                                        else vErrors.push(err);
                                        errors++;
                                      } else {
                                        var errs_5 = errors;
                                        if (typeof data3 === 'string') {
                                          if (!pattern7.test(data3)) {
                                            var err = {
                                              keyword: 'pattern',
                                              dataPath:
                                                (dataPath || '') +
                                                '.jsonTemplate.relevantDate.value',
                                              schemaPath:
                                                '#/definitions/dateProperty/oneOf/0/properties/value/pattern',
                                              params: {
                                                pattern:
                                                  '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
                                              },
                                              message:
                                                'should match pattern "^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$"',
                                            };
                                            if (vErrors === null)
                                              vErrors = [err];
                                            else vErrors.push(err);
                                            errors++;
                                          } else {
                                          }
                                        } else {
                                          var err = {
                                            keyword: 'type',
                                            dataPath:
                                              (dataPath || '') +
                                              '.jsonTemplate.relevantDate.value',
                                            schemaPath:
                                              '#/definitions/dateProperty/oneOf/0/properties/value/type',
                                            params: {
                                              type: 'string',
                                            },
                                            message: 'should be string',
                                          };
                                          if (vErrors === null) vErrors = [err];
                                          else vErrors.push(err);
                                          errors++;
                                        }
                                        if (errors === errs_5) {
                                        }
                                        var valid5 = errors === errs_5;
                                      }
                                      if (valid5) {
                                      }
                                    }
                                    if (errs__4 == errors) {
                                    }
                                  }
                                }
                                if (errors === errs_4) {
                                }
                                var valid4 = errors === errs_4;
                                if (valid4) {
                                  valid3 = prevValid3 = true;
                                  passingSchemas3 = 0;
                                }
                                var errs_4 = errors;
                                if (
                                  data2 &&
                                  typeof data2 === 'object' &&
                                  !Array.isArray(data2)
                                ) {
                                  if (true) {
                                    var errs__4 = errors;
                                    var valid5 = true;
                                    var data3 = data2.type;
                                    if (data3 === undefined) {
                                      valid5 = false;
                                      var err = {
                                        keyword: 'required',
                                        dataPath:
                                          (dataPath || '') +
                                          '.jsonTemplate.relevantDate',
                                        schemaPath:
                                          '#/definitions/dateProperty/oneOf/1/required',
                                        params: {
                                          missingProperty: 'type',
                                        },
                                        message:
                                          "should have required property 'type'",
                                      };
                                      if (vErrors === null) vErrors = [err];
                                      else vErrors.push(err);
                                      errors++;
                                    } else {
                                      var errs_5 = errors;
                                      if (typeof data3 === 'string') {
                                        if (!pattern0.test(data3)) {
                                          var err = {
                                            keyword: 'pattern',
                                            dataPath:
                                              (dataPath || '') +
                                              '.jsonTemplate.relevantDate.type',
                                            schemaPath:
                                              '#/definitions/dateProperty/oneOf/1/properties/type/pattern',
                                            params: {
                                              pattern: 'string',
                                            },
                                            message:
                                              'should match pattern "string"',
                                          };
                                          if (vErrors === null) vErrors = [err];
                                          else vErrors.push(err);
                                          errors++;
                                        } else {
                                        }
                                      } else {
                                        var err = {
                                          keyword: 'type',
                                          dataPath:
                                            (dataPath || '') +
                                            '.jsonTemplate.relevantDate.type',
                                          schemaPath:
                                            '#/definitions/dateProperty/oneOf/1/properties/type/type',
                                          params: {
                                            type: 'string',
                                          },
                                          message: 'should be string',
                                        };
                                        if (vErrors === null) vErrors = [err];
                                        else vErrors.push(err);
                                        errors++;
                                      }
                                      if (errors === errs_5) {
                                      }
                                      var valid5 = errors === errs_5;
                                    }
                                    if (valid5) {
                                      var data3 = data2.value;
                                      if (data3 === undefined) {
                                        valid5 = false;
                                        var err = {
                                          keyword: 'required',
                                          dataPath:
                                            (dataPath || '') +
                                            '.jsonTemplate.relevantDate',
                                          schemaPath:
                                            '#/definitions/dateProperty/oneOf/1/required',
                                          params: {
                                            missingProperty: 'value',
                                          },
                                          message:
                                            "should have required property 'value'",
                                        };
                                        if (vErrors === null) vErrors = [err];
                                        else vErrors.push(err);
                                        errors++;
                                      } else {
                                        var errs_5 = errors;
                                        if (typeof data3 === 'string') {
                                          if (!pattern4.test(data3)) {
                                            var err = {
                                              keyword: 'pattern',
                                              dataPath:
                                                (dataPath || '') +
                                                '.jsonTemplate.relevantDate.value',
                                              schemaPath:
                                                '#/definitions/dateProperty/oneOf/1/properties/value/pattern',
                                              params: {
                                                pattern: '^#\\{.+\\}$',
                                              },
                                              message:
                                                'should match pattern "^#\\{.+\\}$"',
                                            };
                                            if (vErrors === null)
                                              vErrors = [err];
                                            else vErrors.push(err);
                                            errors++;
                                          } else {
                                          }
                                        } else {
                                          var err = {
                                            keyword: 'type',
                                            dataPath:
                                              (dataPath || '') +
                                              '.jsonTemplate.relevantDate.value',
                                            schemaPath:
                                              '#/definitions/dateProperty/oneOf/1/properties/value/type',
                                            params: {
                                              type: 'string',
                                            },
                                            message: 'should be string',
                                          };
                                          if (vErrors === null) vErrors = [err];
                                          else vErrors.push(err);
                                          errors++;
                                        }
                                        if (errors === errs_5) {
                                        }
                                        var valid5 = errors === errs_5;
                                      }
                                      if (valid5) {
                                        var data3 = data2.fallback;
                                        if (data3 === undefined) {
                                          valid5 = false;
                                          var err = {
                                            keyword: 'required',
                                            dataPath:
                                              (dataPath || '') +
                                              '.jsonTemplate.relevantDate',
                                            schemaPath:
                                              '#/definitions/dateProperty/oneOf/1/required',
                                            params: {
                                              missingProperty: 'fallback',
                                            },
                                            message:
                                              "should have required property 'fallback'",
                                          };
                                          if (vErrors === null) vErrors = [err];
                                          else vErrors.push(err);
                                          errors++;
                                        } else {
                                          var errs_5 = errors;
                                          if (typeof data3 === 'string') {
                                            if (!pattern7.test(data3)) {
                                              var err = {
                                                keyword: 'pattern',
                                                dataPath:
                                                  (dataPath || '') +
                                                  '.jsonTemplate.relevantDate.fallback',
                                                schemaPath:
                                                  '#/definitions/dateProperty/oneOf/1/properties/fallback/pattern',
                                                params: {
                                                  pattern:
                                                    '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
                                                },
                                                message:
                                                  'should match pattern "^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$"',
                                              };
                                              if (vErrors === null)
                                                vErrors = [err];
                                              else vErrors.push(err);
                                              errors++;
                                            } else {
                                            }
                                          } else {
                                            var err = {
                                              keyword: 'type',
                                              dataPath:
                                                (dataPath || '') +
                                                '.jsonTemplate.relevantDate.fallback',
                                              schemaPath:
                                                '#/definitions/dateProperty/oneOf/1/properties/fallback/type',
                                              params: {
                                                type: 'string',
                                              },
                                              message: 'should be string',
                                            };
                                            if (vErrors === null)
                                              vErrors = [err];
                                            else vErrors.push(err);
                                            errors++;
                                          }
                                          if (errors === errs_5) {
                                          }
                                          var valid5 = errors === errs_5;
                                        }
                                        if (valid5) {
                                        }
                                      }
                                    }
                                    if (errs__4 == errors) {
                                    }
                                  }
                                }
                                if (errors === errs_4) {
                                }
                                var valid4 = errors === errs_4;
                                if (valid4 && prevValid3) {
                                  valid3 = false;
                                  passingSchemas3 = [passingSchemas3, 1];
                                } else {
                                  if (valid4) {
                                    valid3 = prevValid3 = true;
                                    passingSchemas3 = 1;
                                  }
                                }
                                if (!valid3) {
                                  var err = {
                                    keyword: 'oneOf',
                                    dataPath:
                                      (dataPath || '') +
                                      '.jsonTemplate.relevantDate',
                                    schemaPath:
                                      '#/definitions/dateProperty/oneOf',
                                    params: {
                                      passingSchemas: passingSchemas3,
                                    },
                                    message:
                                      'should match exactly one schema in oneOf',
                                  };
                                  if (vErrors === null) vErrors = [err];
                                  else vErrors.push(err);
                                  errors++;
                                  validate.errors = vErrors;
                                  return false;
                                } else {
                                  errors = errs__3;
                                  if (vErrors !== null) {
                                    if (errs__3) vErrors.length = errs__3;
                                    else vErrors = null;
                                  }
                                }
                                if (errors === errs_3) {
                                }
                                var valid3 = errors === errs_3;
                                if (valid3) {
                                }
                                if (errors === errs_2) {
                                }
                                var valid2 = errors === errs_2;
                              }
                              if (valid2) {
                                var data2 = data1.expirationDate;
                                if (data2 === undefined) {
                                  valid2 = true;
                                } else {
                                  var errs_2 = errors;
                                  var errs_3 = errors;
                                  if (
                                    !data2 ||
                                    typeof data2 !== 'object' ||
                                    Array.isArray(data2)
                                  ) {
                                    validate.errors = [
                                      {
                                        keyword: 'type',
                                        dataPath:
                                          (dataPath || '') +
                                          '.jsonTemplate.expirationDate',
                                        schemaPath:
                                          '#/definitions/dateProperty/type',
                                        params: {
                                          type: 'object',
                                        },
                                        message: 'should be object',
                                      },
                                    ];
                                    return false;
                                  }
                                  var errs__3 = errors,
                                    prevValid3 = false,
                                    valid3 = false,
                                    passingSchemas3 = null;
                                  var errs_4 = errors;
                                  if (
                                    data2 &&
                                    typeof data2 === 'object' &&
                                    !Array.isArray(data2)
                                  ) {
                                    if (true) {
                                      var errs__4 = errors;
                                      var valid5 = true;
                                      var data3 = data2.type;
                                      if (data3 === undefined) {
                                        valid5 = false;
                                        var err = {
                                          keyword: 'required',
                                          dataPath:
                                            (dataPath || '') +
                                            '.jsonTemplate.expirationDate',
                                          schemaPath:
                                            '#/definitions/dateProperty/oneOf/0/required',
                                          params: {
                                            missingProperty: 'type',
                                          },
                                          message:
                                            "should have required property 'type'",
                                        };
                                        if (vErrors === null) vErrors = [err];
                                        else vErrors.push(err);
                                        errors++;
                                      } else {
                                        var errs_5 = errors;
                                        if (typeof data3 === 'string') {
                                          if (!pattern0.test(data3)) {
                                            var err = {
                                              keyword: 'pattern',
                                              dataPath:
                                                (dataPath || '') +
                                                '.jsonTemplate.expirationDate.type',
                                              schemaPath:
                                                '#/definitions/dateProperty/oneOf/0/properties/type/pattern',
                                              params: {
                                                pattern: 'string',
                                              },
                                              message:
                                                'should match pattern "string"',
                                            };
                                            if (vErrors === null)
                                              vErrors = [err];
                                            else vErrors.push(err);
                                            errors++;
                                          } else {
                                          }
                                        } else {
                                          var err = {
                                            keyword: 'type',
                                            dataPath:
                                              (dataPath || '') +
                                              '.jsonTemplate.expirationDate.type',
                                            schemaPath:
                                              '#/definitions/dateProperty/oneOf/0/properties/type/type',
                                            params: {
                                              type: 'string',
                                            },
                                            message: 'should be string',
                                          };
                                          if (vErrors === null) vErrors = [err];
                                          else vErrors.push(err);
                                          errors++;
                                        }
                                        if (errors === errs_5) {
                                        }
                                        var valid5 = errors === errs_5;
                                      }
                                      if (valid5) {
                                        var data3 = data2.value;
                                        if (data3 === undefined) {
                                          valid5 = false;
                                          var err = {
                                            keyword: 'required',
                                            dataPath:
                                              (dataPath || '') +
                                              '.jsonTemplate.expirationDate',
                                            schemaPath:
                                              '#/definitions/dateProperty/oneOf/0/required',
                                            params: {
                                              missingProperty: 'value',
                                            },
                                            message:
                                              "should have required property 'value'",
                                          };
                                          if (vErrors === null) vErrors = [err];
                                          else vErrors.push(err);
                                          errors++;
                                        } else {
                                          var errs_5 = errors;
                                          if (typeof data3 === 'string') {
                                            if (!pattern7.test(data3)) {
                                              var err = {
                                                keyword: 'pattern',
                                                dataPath:
                                                  (dataPath || '') +
                                                  '.jsonTemplate.expirationDate.value',
                                                schemaPath:
                                                  '#/definitions/dateProperty/oneOf/0/properties/value/pattern',
                                                params: {
                                                  pattern:
                                                    '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
                                                },
                                                message:
                                                  'should match pattern "^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$"',
                                              };
                                              if (vErrors === null)
                                                vErrors = [err];
                                              else vErrors.push(err);
                                              errors++;
                                            } else {
                                            }
                                          } else {
                                            var err = {
                                              keyword: 'type',
                                              dataPath:
                                                (dataPath || '') +
                                                '.jsonTemplate.expirationDate.value',
                                              schemaPath:
                                                '#/definitions/dateProperty/oneOf/0/properties/value/type',
                                              params: {
                                                type: 'string',
                                              },
                                              message: 'should be string',
                                            };
                                            if (vErrors === null)
                                              vErrors = [err];
                                            else vErrors.push(err);
                                            errors++;
                                          }
                                          if (errors === errs_5) {
                                          }
                                          var valid5 = errors === errs_5;
                                        }
                                        if (valid5) {
                                        }
                                      }
                                      if (errs__4 == errors) {
                                      }
                                    }
                                  }
                                  if (errors === errs_4) {
                                  }
                                  var valid4 = errors === errs_4;
                                  if (valid4) {
                                    valid3 = prevValid3 = true;
                                    passingSchemas3 = 0;
                                  }
                                  var errs_4 = errors;
                                  if (
                                    data2 &&
                                    typeof data2 === 'object' &&
                                    !Array.isArray(data2)
                                  ) {
                                    if (true) {
                                      var errs__4 = errors;
                                      var valid5 = true;
                                      var data3 = data2.type;
                                      if (data3 === undefined) {
                                        valid5 = false;
                                        var err = {
                                          keyword: 'required',
                                          dataPath:
                                            (dataPath || '') +
                                            '.jsonTemplate.expirationDate',
                                          schemaPath:
                                            '#/definitions/dateProperty/oneOf/1/required',
                                          params: {
                                            missingProperty: 'type',
                                          },
                                          message:
                                            "should have required property 'type'",
                                        };
                                        if (vErrors === null) vErrors = [err];
                                        else vErrors.push(err);
                                        errors++;
                                      } else {
                                        var errs_5 = errors;
                                        if (typeof data3 === 'string') {
                                          if (!pattern0.test(data3)) {
                                            var err = {
                                              keyword: 'pattern',
                                              dataPath:
                                                (dataPath || '') +
                                                '.jsonTemplate.expirationDate.type',
                                              schemaPath:
                                                '#/definitions/dateProperty/oneOf/1/properties/type/pattern',
                                              params: {
                                                pattern: 'string',
                                              },
                                              message:
                                                'should match pattern "string"',
                                            };
                                            if (vErrors === null)
                                              vErrors = [err];
                                            else vErrors.push(err);
                                            errors++;
                                          } else {
                                          }
                                        } else {
                                          var err = {
                                            keyword: 'type',
                                            dataPath:
                                              (dataPath || '') +
                                              '.jsonTemplate.expirationDate.type',
                                            schemaPath:
                                              '#/definitions/dateProperty/oneOf/1/properties/type/type',
                                            params: {
                                              type: 'string',
                                            },
                                            message: 'should be string',
                                          };
                                          if (vErrors === null) vErrors = [err];
                                          else vErrors.push(err);
                                          errors++;
                                        }
                                        if (errors === errs_5) {
                                        }
                                        var valid5 = errors === errs_5;
                                      }
                                      if (valid5) {
                                        var data3 = data2.value;
                                        if (data3 === undefined) {
                                          valid5 = false;
                                          var err = {
                                            keyword: 'required',
                                            dataPath:
                                              (dataPath || '') +
                                              '.jsonTemplate.expirationDate',
                                            schemaPath:
                                              '#/definitions/dateProperty/oneOf/1/required',
                                            params: {
                                              missingProperty: 'value',
                                            },
                                            message:
                                              "should have required property 'value'",
                                          };
                                          if (vErrors === null) vErrors = [err];
                                          else vErrors.push(err);
                                          errors++;
                                        } else {
                                          var errs_5 = errors;
                                          if (typeof data3 === 'string') {
                                            if (!pattern4.test(data3)) {
                                              var err = {
                                                keyword: 'pattern',
                                                dataPath:
                                                  (dataPath || '') +
                                                  '.jsonTemplate.expirationDate.value',
                                                schemaPath:
                                                  '#/definitions/dateProperty/oneOf/1/properties/value/pattern',
                                                params: {
                                                  pattern: '^#\\{.+\\}$',
                                                },
                                                message:
                                                  'should match pattern "^#\\{.+\\}$"',
                                              };
                                              if (vErrors === null)
                                                vErrors = [err];
                                              else vErrors.push(err);
                                              errors++;
                                            } else {
                                            }
                                          } else {
                                            var err = {
                                              keyword: 'type',
                                              dataPath:
                                                (dataPath || '') +
                                                '.jsonTemplate.expirationDate.value',
                                              schemaPath:
                                                '#/definitions/dateProperty/oneOf/1/properties/value/type',
                                              params: {
                                                type: 'string',
                                              },
                                              message: 'should be string',
                                            };
                                            if (vErrors === null)
                                              vErrors = [err];
                                            else vErrors.push(err);
                                            errors++;
                                          }
                                          if (errors === errs_5) {
                                          }
                                          var valid5 = errors === errs_5;
                                        }
                                        if (valid5) {
                                          var data3 = data2.fallback;
                                          if (data3 === undefined) {
                                            valid5 = false;
                                            var err = {
                                              keyword: 'required',
                                              dataPath:
                                                (dataPath || '') +
                                                '.jsonTemplate.expirationDate',
                                              schemaPath:
                                                '#/definitions/dateProperty/oneOf/1/required',
                                              params: {
                                                missingProperty: 'fallback',
                                              },
                                              message:
                                                "should have required property 'fallback'",
                                            };
                                            if (vErrors === null)
                                              vErrors = [err];
                                            else vErrors.push(err);
                                            errors++;
                                          } else {
                                            var errs_5 = errors;
                                            if (typeof data3 === 'string') {
                                              if (!pattern7.test(data3)) {
                                                var err = {
                                                  keyword: 'pattern',
                                                  dataPath:
                                                    (dataPath || '') +
                                                    '.jsonTemplate.expirationDate.fallback',
                                                  schemaPath:
                                                    '#/definitions/dateProperty/oneOf/1/properties/fallback/pattern',
                                                  params: {
                                                    pattern:
                                                      '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
                                                  },
                                                  message:
                                                    'should match pattern "^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$"',
                                                };
                                                if (vErrors === null)
                                                  vErrors = [err];
                                                else vErrors.push(err);
                                                errors++;
                                              } else {
                                              }
                                            } else {
                                              var err = {
                                                keyword: 'type',
                                                dataPath:
                                                  (dataPath || '') +
                                                  '.jsonTemplate.expirationDate.fallback',
                                                schemaPath:
                                                  '#/definitions/dateProperty/oneOf/1/properties/fallback/type',
                                                params: {
                                                  type: 'string',
                                                },
                                                message: 'should be string',
                                              };
                                              if (vErrors === null)
                                                vErrors = [err];
                                              else vErrors.push(err);
                                              errors++;
                                            }
                                            if (errors === errs_5) {
                                            }
                                            var valid5 = errors === errs_5;
                                          }
                                          if (valid5) {
                                          }
                                        }
                                      }
                                      if (errs__4 == errors) {
                                      }
                                    }
                                  }
                                  if (errors === errs_4) {
                                  }
                                  var valid4 = errors === errs_4;
                                  if (valid4 && prevValid3) {
                                    valid3 = false;
                                    passingSchemas3 = [passingSchemas3, 1];
                                  } else {
                                    if (valid4) {
                                      valid3 = prevValid3 = true;
                                      passingSchemas3 = 1;
                                    }
                                  }
                                  if (!valid3) {
                                    var err = {
                                      keyword: 'oneOf',
                                      dataPath:
                                        (dataPath || '') +
                                        '.jsonTemplate.expirationDate',
                                      schemaPath:
                                        '#/definitions/dateProperty/oneOf',
                                      params: {
                                        passingSchemas: passingSchemas3,
                                      },
                                      message:
                                        'should match exactly one schema in oneOf',
                                    };
                                    if (vErrors === null) vErrors = [err];
                                    else vErrors.push(err);
                                    errors++;
                                    validate.errors = vErrors;
                                    return false;
                                  } else {
                                    errors = errs__3;
                                    if (vErrors !== null) {
                                      if (errs__3) vErrors.length = errs__3;
                                      else vErrors = null;
                                    }
                                  }
                                  if (errors === errs_3) {
                                  }
                                  var valid3 = errors === errs_3;
                                  if (valid3) {
                                  }
                                  if (errors === errs_2) {
                                  }
                                  var valid2 = errors === errs_2;
                                }
                                if (valid2) {
                                  var data2 = data1.backgroundColor;
                                  if (data2 === undefined) {
                                    valid2 = true;
                                  } else {
                                    var errs_2 = errors;
                                    var errs_3 = errors;
                                    if (
                                      !data2 ||
                                      typeof data2 !== 'object' ||
                                      Array.isArray(data2)
                                    ) {
                                      validate.errors = [
                                        {
                                          keyword: 'type',
                                          dataPath:
                                            (dataPath || '') +
                                            '.jsonTemplate.backgroundColor',
                                          schemaPath:
                                            '#/definitions/colorProperty/type',
                                          params: {
                                            type: 'object',
                                          },
                                          message: 'should be object',
                                        },
                                      ];
                                      return false;
                                    }
                                    var errs__3 = errors,
                                      prevValid3 = false,
                                      valid3 = false,
                                      passingSchemas3 = null;
                                    var errs_4 = errors;
                                    if (
                                      data2 &&
                                      typeof data2 === 'object' &&
                                      !Array.isArray(data2)
                                    ) {
                                      if (true) {
                                        var errs__4 = errors;
                                        var valid5 = true;
                                        var data3 = data2.type;
                                        if (data3 === undefined) {
                                          valid5 = false;
                                          var err = {
                                            keyword: 'required',
                                            dataPath:
                                              (dataPath || '') +
                                              '.jsonTemplate.backgroundColor',
                                            schemaPath:
                                              '#/definitions/colorProperty/oneOf/0/required',
                                            params: {
                                              missingProperty: 'type',
                                            },
                                            message:
                                              "should have required property 'type'",
                                          };
                                          if (vErrors === null) vErrors = [err];
                                          else vErrors.push(err);
                                          errors++;
                                        } else {
                                          var errs_5 = errors;
                                          if (typeof data3 === 'string') {
                                            if (!pattern0.test(data3)) {
                                              var err = {
                                                keyword: 'pattern',
                                                dataPath:
                                                  (dataPath || '') +
                                                  '.jsonTemplate.backgroundColor.type',
                                                schemaPath:
                                                  '#/definitions/colorProperty/oneOf/0/properties/type/pattern',
                                                params: {
                                                  pattern: 'string',
                                                },
                                                message:
                                                  'should match pattern "string"',
                                              };
                                              if (vErrors === null)
                                                vErrors = [err];
                                              else vErrors.push(err);
                                              errors++;
                                            } else {
                                            }
                                          } else {
                                            var err = {
                                              keyword: 'type',
                                              dataPath:
                                                (dataPath || '') +
                                                '.jsonTemplate.backgroundColor.type',
                                              schemaPath:
                                                '#/definitions/colorProperty/oneOf/0/properties/type/type',
                                              params: {
                                                type: 'string',
                                              },
                                              message: 'should be string',
                                            };
                                            if (vErrors === null)
                                              vErrors = [err];
                                            else vErrors.push(err);
                                            errors++;
                                          }
                                          if (errors === errs_5) {
                                          }
                                          var valid5 = errors === errs_5;
                                        }
                                        if (valid5) {
                                          var data3 = data2.value;
                                          if (data3 === undefined) {
                                            valid5 = false;
                                            var err = {
                                              keyword: 'required',
                                              dataPath:
                                                (dataPath || '') +
                                                '.jsonTemplate.backgroundColor',
                                              schemaPath:
                                                '#/definitions/colorProperty/oneOf/0/required',
                                              params: {
                                                missingProperty: 'value',
                                              },
                                              message:
                                                "should have required property 'value'",
                                            };
                                            if (vErrors === null)
                                              vErrors = [err];
                                            else vErrors.push(err);
                                            errors++;
                                          } else {
                                            var errs_5 = errors;
                                            if (typeof data3 === 'string') {
                                              if (!pattern6.test(data3)) {
                                                var err = {
                                                  keyword: 'pattern',
                                                  dataPath:
                                                    (dataPath || '') +
                                                    '.jsonTemplate.backgroundColor.value',
                                                  schemaPath:
                                                    '#/definitions/colorProperty/oneOf/0/properties/value/pattern',
                                                  params: {
                                                    pattern:
                                                      '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
                                                  },
                                                  message:
                                                    'should match pattern "^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$"',
                                                };
                                                if (vErrors === null)
                                                  vErrors = [err];
                                                else vErrors.push(err);
                                                errors++;
                                              } else {
                                              }
                                            } else {
                                              var err = {
                                                keyword: 'type',
                                                dataPath:
                                                  (dataPath || '') +
                                                  '.jsonTemplate.backgroundColor.value',
                                                schemaPath:
                                                  '#/definitions/colorProperty/oneOf/0/properties/value/type',
                                                params: {
                                                  type: 'string',
                                                },
                                                message: 'should be string',
                                              };
                                              if (vErrors === null)
                                                vErrors = [err];
                                              else vErrors.push(err);
                                              errors++;
                                            }
                                            if (errors === errs_5) {
                                            }
                                            var valid5 = errors === errs_5;
                                          }
                                          if (valid5) {
                                          }
                                        }
                                        if (errs__4 == errors) {
                                        }
                                      }
                                    }
                                    if (errors === errs_4) {
                                    }
                                    var valid4 = errors === errs_4;
                                    if (valid4) {
                                      valid3 = prevValid3 = true;
                                      passingSchemas3 = 0;
                                    }
                                    var errs_4 = errors;
                                    if (
                                      data2 &&
                                      typeof data2 === 'object' &&
                                      !Array.isArray(data2)
                                    ) {
                                      if (true) {
                                        var errs__4 = errors;
                                        var valid5 = true;
                                        var data3 = data2.type;
                                        if (data3 === undefined) {
                                          valid5 = false;
                                          var err = {
                                            keyword: 'required',
                                            dataPath:
                                              (dataPath || '') +
                                              '.jsonTemplate.backgroundColor',
                                            schemaPath:
                                              '#/definitions/colorProperty/oneOf/1/required',
                                            params: {
                                              missingProperty: 'type',
                                            },
                                            message:
                                              "should have required property 'type'",
                                          };
                                          if (vErrors === null) vErrors = [err];
                                          else vErrors.push(err);
                                          errors++;
                                        } else {
                                          var errs_5 = errors;
                                          if (typeof data3 === 'string') {
                                            if (!pattern0.test(data3)) {
                                              var err = {
                                                keyword: 'pattern',
                                                dataPath:
                                                  (dataPath || '') +
                                                  '.jsonTemplate.backgroundColor.type',
                                                schemaPath:
                                                  '#/definitions/colorProperty/oneOf/1/properties/type/pattern',
                                                params: {
                                                  pattern: 'string',
                                                },
                                                message:
                                                  'should match pattern "string"',
                                              };
                                              if (vErrors === null)
                                                vErrors = [err];
                                              else vErrors.push(err);
                                              errors++;
                                            } else {
                                            }
                                          } else {
                                            var err = {
                                              keyword: 'type',
                                              dataPath:
                                                (dataPath || '') +
                                                '.jsonTemplate.backgroundColor.type',
                                              schemaPath:
                                                '#/definitions/colorProperty/oneOf/1/properties/type/type',
                                              params: {
                                                type: 'string',
                                              },
                                              message: 'should be string',
                                            };
                                            if (vErrors === null)
                                              vErrors = [err];
                                            else vErrors.push(err);
                                            errors++;
                                          }
                                          if (errors === errs_5) {
                                          }
                                          var valid5 = errors === errs_5;
                                        }
                                        if (valid5) {
                                          var data3 = data2.value;
                                          if (data3 === undefined) {
                                            valid5 = false;
                                            var err = {
                                              keyword: 'required',
                                              dataPath:
                                                (dataPath || '') +
                                                '.jsonTemplate.backgroundColor',
                                              schemaPath:
                                                '#/definitions/colorProperty/oneOf/1/required',
                                              params: {
                                                missingProperty: 'value',
                                              },
                                              message:
                                                "should have required property 'value'",
                                            };
                                            if (vErrors === null)
                                              vErrors = [err];
                                            else vErrors.push(err);
                                            errors++;
                                          } else {
                                            var errs_5 = errors;
                                            if (typeof data3 === 'string') {
                                              if (!pattern4.test(data3)) {
                                                var err = {
                                                  keyword: 'pattern',
                                                  dataPath:
                                                    (dataPath || '') +
                                                    '.jsonTemplate.backgroundColor.value',
                                                  schemaPath:
                                                    '#/definitions/colorProperty/oneOf/1/properties/value/pattern',
                                                  params: {
                                                    pattern: '^#\\{.+\\}$',
                                                  },
                                                  message:
                                                    'should match pattern "^#\\{.+\\}$"',
                                                };
                                                if (vErrors === null)
                                                  vErrors = [err];
                                                else vErrors.push(err);
                                                errors++;
                                              } else {
                                              }
                                            } else {
                                              var err = {
                                                keyword: 'type',
                                                dataPath:
                                                  (dataPath || '') +
                                                  '.jsonTemplate.backgroundColor.value',
                                                schemaPath:
                                                  '#/definitions/colorProperty/oneOf/1/properties/value/type',
                                                params: {
                                                  type: 'string',
                                                },
                                                message: 'should be string',
                                              };
                                              if (vErrors === null)
                                                vErrors = [err];
                                              else vErrors.push(err);
                                              errors++;
                                            }
                                            if (errors === errs_5) {
                                            }
                                            var valid5 = errors === errs_5;
                                          }
                                          if (valid5) {
                                            var data3 = data2.fallback;
                                            if (data3 === undefined) {
                                              valid5 = false;
                                              var err = {
                                                keyword: 'required',
                                                dataPath:
                                                  (dataPath || '') +
                                                  '.jsonTemplate.backgroundColor',
                                                schemaPath:
                                                  '#/definitions/colorProperty/oneOf/1/required',
                                                params: {
                                                  missingProperty: 'fallback',
                                                },
                                                message:
                                                  "should have required property 'fallback'",
                                              };
                                              if (vErrors === null)
                                                vErrors = [err];
                                              else vErrors.push(err);
                                              errors++;
                                            } else {
                                              var errs_5 = errors;
                                              if (typeof data3 === 'string') {
                                                if (!pattern6.test(data3)) {
                                                  var err = {
                                                    keyword: 'pattern',
                                                    dataPath:
                                                      (dataPath || '') +
                                                      '.jsonTemplate.backgroundColor.fallback',
                                                    schemaPath:
                                                      '#/definitions/colorProperty/oneOf/1/properties/fallback/pattern',
                                                    params: {
                                                      pattern:
                                                        '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
                                                    },
                                                    message:
                                                      'should match pattern "^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$"',
                                                  };
                                                  if (vErrors === null)
                                                    vErrors = [err];
                                                  else vErrors.push(err);
                                                  errors++;
                                                } else {
                                                }
                                              } else {
                                                var err = {
                                                  keyword: 'type',
                                                  dataPath:
                                                    (dataPath || '') +
                                                    '.jsonTemplate.backgroundColor.fallback',
                                                  schemaPath:
                                                    '#/definitions/colorProperty/oneOf/1/properties/fallback/type',
                                                  params: {
                                                    type: 'string',
                                                  },
                                                  message: 'should be string',
                                                };
                                                if (vErrors === null)
                                                  vErrors = [err];
                                                else vErrors.push(err);
                                                errors++;
                                              }
                                              if (errors === errs_5) {
                                              }
                                              var valid5 = errors === errs_5;
                                            }
                                            if (valid5) {
                                            }
                                          }
                                        }
                                        if (errs__4 == errors) {
                                        }
                                      }
                                    }
                                    if (errors === errs_4) {
                                    }
                                    var valid4 = errors === errs_4;
                                    if (valid4 && prevValid3) {
                                      valid3 = false;
                                      passingSchemas3 = [passingSchemas3, 1];
                                    } else {
                                      if (valid4) {
                                        valid3 = prevValid3 = true;
                                        passingSchemas3 = 1;
                                      }
                                    }
                                    if (!valid3) {
                                      var err = {
                                        keyword: 'oneOf',
                                        dataPath:
                                          (dataPath || '') +
                                          '.jsonTemplate.backgroundColor',
                                        schemaPath:
                                          '#/definitions/colorProperty/oneOf',
                                        params: {
                                          passingSchemas: passingSchemas3,
                                        },
                                        message:
                                          'should match exactly one schema in oneOf',
                                      };
                                      if (vErrors === null) vErrors = [err];
                                      else vErrors.push(err);
                                      errors++;
                                      validate.errors = vErrors;
                                      return false;
                                    } else {
                                      errors = errs__3;
                                      if (vErrors !== null) {
                                        if (errs__3) vErrors.length = errs__3;
                                        else vErrors = null;
                                      }
                                    }
                                    if (errors === errs_3) {
                                    }
                                    var valid3 = errors === errs_3;
                                    if (valid3) {
                                    }
                                    if (errors === errs_2) {
                                    }
                                    var valid2 = errors === errs_2;
                                  }
                                  if (valid2) {
                                    var data2 = data1.foregroundColor;
                                    if (data2 === undefined) {
                                      valid2 = true;
                                    } else {
                                      var errs_2 = errors;
                                      var errs_3 = errors;
                                      if (
                                        !data2 ||
                                        typeof data2 !== 'object' ||
                                        Array.isArray(data2)
                                      ) {
                                        validate.errors = [
                                          {
                                            keyword: 'type',
                                            dataPath:
                                              (dataPath || '') +
                                              '.jsonTemplate.foregroundColor',
                                            schemaPath:
                                              '#/definitions/colorProperty/type',
                                            params: {
                                              type: 'object',
                                            },
                                            message: 'should be object',
                                          },
                                        ];
                                        return false;
                                      }
                                      var errs__3 = errors,
                                        prevValid3 = false,
                                        valid3 = false,
                                        passingSchemas3 = null;
                                      var errs_4 = errors;
                                      if (
                                        data2 &&
                                        typeof data2 === 'object' &&
                                        !Array.isArray(data2)
                                      ) {
                                        if (true) {
                                          var errs__4 = errors;
                                          var valid5 = true;
                                          var data3 = data2.type;
                                          if (data3 === undefined) {
                                            valid5 = false;
                                            var err = {
                                              keyword: 'required',
                                              dataPath:
                                                (dataPath || '') +
                                                '.jsonTemplate.foregroundColor',
                                              schemaPath:
                                                '#/definitions/colorProperty/oneOf/0/required',
                                              params: {
                                                missingProperty: 'type',
                                              },
                                              message:
                                                "should have required property 'type'",
                                            };
                                            if (vErrors === null)
                                              vErrors = [err];
                                            else vErrors.push(err);
                                            errors++;
                                          } else {
                                            var errs_5 = errors;
                                            if (typeof data3 === 'string') {
                                              if (!pattern0.test(data3)) {
                                                var err = {
                                                  keyword: 'pattern',
                                                  dataPath:
                                                    (dataPath || '') +
                                                    '.jsonTemplate.foregroundColor.type',
                                                  schemaPath:
                                                    '#/definitions/colorProperty/oneOf/0/properties/type/pattern',
                                                  params: {
                                                    pattern: 'string',
                                                  },
                                                  message:
                                                    'should match pattern "string"',
                                                };
                                                if (vErrors === null)
                                                  vErrors = [err];
                                                else vErrors.push(err);
                                                errors++;
                                              } else {
                                              }
                                            } else {
                                              var err = {
                                                keyword: 'type',
                                                dataPath:
                                                  (dataPath || '') +
                                                  '.jsonTemplate.foregroundColor.type',
                                                schemaPath:
                                                  '#/definitions/colorProperty/oneOf/0/properties/type/type',
                                                params: {
                                                  type: 'string',
                                                },
                                                message: 'should be string',
                                              };
                                              if (vErrors === null)
                                                vErrors = [err];
                                              else vErrors.push(err);
                                              errors++;
                                            }
                                            if (errors === errs_5) {
                                            }
                                            var valid5 = errors === errs_5;
                                          }
                                          if (valid5) {
                                            var data3 = data2.value;
                                            if (data3 === undefined) {
                                              valid5 = false;
                                              var err = {
                                                keyword: 'required',
                                                dataPath:
                                                  (dataPath || '') +
                                                  '.jsonTemplate.foregroundColor',
                                                schemaPath:
                                                  '#/definitions/colorProperty/oneOf/0/required',
                                                params: {
                                                  missingProperty: 'value',
                                                },
                                                message:
                                                  "should have required property 'value'",
                                              };
                                              if (vErrors === null)
                                                vErrors = [err];
                                              else vErrors.push(err);
                                              errors++;
                                            } else {
                                              var errs_5 = errors;
                                              if (typeof data3 === 'string') {
                                                if (!pattern6.test(data3)) {
                                                  var err = {
                                                    keyword: 'pattern',
                                                    dataPath:
                                                      (dataPath || '') +
                                                      '.jsonTemplate.foregroundColor.value',
                                                    schemaPath:
                                                      '#/definitions/colorProperty/oneOf/0/properties/value/pattern',
                                                    params: {
                                                      pattern:
                                                        '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
                                                    },
                                                    message:
                                                      'should match pattern "^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$"',
                                                  };
                                                  if (vErrors === null)
                                                    vErrors = [err];
                                                  else vErrors.push(err);
                                                  errors++;
                                                } else {
                                                }
                                              } else {
                                                var err = {
                                                  keyword: 'type',
                                                  dataPath:
                                                    (dataPath || '') +
                                                    '.jsonTemplate.foregroundColor.value',
                                                  schemaPath:
                                                    '#/definitions/colorProperty/oneOf/0/properties/value/type',
                                                  params: {
                                                    type: 'string',
                                                  },
                                                  message: 'should be string',
                                                };
                                                if (vErrors === null)
                                                  vErrors = [err];
                                                else vErrors.push(err);
                                                errors++;
                                              }
                                              if (errors === errs_5) {
                                              }
                                              var valid5 = errors === errs_5;
                                            }
                                            if (valid5) {
                                            }
                                          }
                                          if (errs__4 == errors) {
                                          }
                                        }
                                      }
                                      if (errors === errs_4) {
                                      }
                                      var valid4 = errors === errs_4;
                                      if (valid4) {
                                        valid3 = prevValid3 = true;
                                        passingSchemas3 = 0;
                                      }
                                      var errs_4 = errors;
                                      if (
                                        data2 &&
                                        typeof data2 === 'object' &&
                                        !Array.isArray(data2)
                                      ) {
                                        if (true) {
                                          var errs__4 = errors;
                                          var valid5 = true;
                                          var data3 = data2.type;
                                          if (data3 === undefined) {
                                            valid5 = false;
                                            var err = {
                                              keyword: 'required',
                                              dataPath:
                                                (dataPath || '') +
                                                '.jsonTemplate.foregroundColor',
                                              schemaPath:
                                                '#/definitions/colorProperty/oneOf/1/required',
                                              params: {
                                                missingProperty: 'type',
                                              },
                                              message:
                                                "should have required property 'type'",
                                            };
                                            if (vErrors === null)
                                              vErrors = [err];
                                            else vErrors.push(err);
                                            errors++;
                                          } else {
                                            var errs_5 = errors;
                                            if (typeof data3 === 'string') {
                                              if (!pattern0.test(data3)) {
                                                var err = {
                                                  keyword: 'pattern',
                                                  dataPath:
                                                    (dataPath || '') +
                                                    '.jsonTemplate.foregroundColor.type',
                                                  schemaPath:
                                                    '#/definitions/colorProperty/oneOf/1/properties/type/pattern',
                                                  params: {
                                                    pattern: 'string',
                                                  },
                                                  message:
                                                    'should match pattern "string"',
                                                };
                                                if (vErrors === null)
                                                  vErrors = [err];
                                                else vErrors.push(err);
                                                errors++;
                                              } else {
                                              }
                                            } else {
                                              var err = {
                                                keyword: 'type',
                                                dataPath:
                                                  (dataPath || '') +
                                                  '.jsonTemplate.foregroundColor.type',
                                                schemaPath:
                                                  '#/definitions/colorProperty/oneOf/1/properties/type/type',
                                                params: {
                                                  type: 'string',
                                                },
                                                message: 'should be string',
                                              };
                                              if (vErrors === null)
                                                vErrors = [err];
                                              else vErrors.push(err);
                                              errors++;
                                            }
                                            if (errors === errs_5) {
                                            }
                                            var valid5 = errors === errs_5;
                                          }
                                          if (valid5) {
                                            var data3 = data2.value;
                                            if (data3 === undefined) {
                                              valid5 = false;
                                              var err = {
                                                keyword: 'required',
                                                dataPath:
                                                  (dataPath || '') +
                                                  '.jsonTemplate.foregroundColor',
                                                schemaPath:
                                                  '#/definitions/colorProperty/oneOf/1/required',
                                                params: {
                                                  missingProperty: 'value',
                                                },
                                                message:
                                                  "should have required property 'value'",
                                              };
                                              if (vErrors === null)
                                                vErrors = [err];
                                              else vErrors.push(err);
                                              errors++;
                                            } else {
                                              var errs_5 = errors;
                                              if (typeof data3 === 'string') {
                                                if (!pattern4.test(data3)) {
                                                  var err = {
                                                    keyword: 'pattern',
                                                    dataPath:
                                                      (dataPath || '') +
                                                      '.jsonTemplate.foregroundColor.value',
                                                    schemaPath:
                                                      '#/definitions/colorProperty/oneOf/1/properties/value/pattern',
                                                    params: {
                                                      pattern: '^#\\{.+\\}$',
                                                    },
                                                    message:
                                                      'should match pattern "^#\\{.+\\}$"',
                                                  };
                                                  if (vErrors === null)
                                                    vErrors = [err];
                                                  else vErrors.push(err);
                                                  errors++;
                                                } else {
                                                }
                                              } else {
                                                var err = {
                                                  keyword: 'type',
                                                  dataPath:
                                                    (dataPath || '') +
                                                    '.jsonTemplate.foregroundColor.value',
                                                  schemaPath:
                                                    '#/definitions/colorProperty/oneOf/1/properties/value/type',
                                                  params: {
                                                    type: 'string',
                                                  },
                                                  message: 'should be string',
                                                };
                                                if (vErrors === null)
                                                  vErrors = [err];
                                                else vErrors.push(err);
                                                errors++;
                                              }
                                              if (errors === errs_5) {
                                              }
                                              var valid5 = errors === errs_5;
                                            }
                                            if (valid5) {
                                              var data3 = data2.fallback;
                                              if (data3 === undefined) {
                                                valid5 = false;
                                                var err = {
                                                  keyword: 'required',
                                                  dataPath:
                                                    (dataPath || '') +
                                                    '.jsonTemplate.foregroundColor',
                                                  schemaPath:
                                                    '#/definitions/colorProperty/oneOf/1/required',
                                                  params: {
                                                    missingProperty: 'fallback',
                                                  },
                                                  message:
                                                    "should have required property 'fallback'",
                                                };
                                                if (vErrors === null)
                                                  vErrors = [err];
                                                else vErrors.push(err);
                                                errors++;
                                              } else {
                                                var errs_5 = errors;
                                                if (typeof data3 === 'string') {
                                                  if (!pattern6.test(data3)) {
                                                    var err = {
                                                      keyword: 'pattern',
                                                      dataPath:
                                                        (dataPath || '') +
                                                        '.jsonTemplate.foregroundColor.fallback',
                                                      schemaPath:
                                                        '#/definitions/colorProperty/oneOf/1/properties/fallback/pattern',
                                                      params: {
                                                        pattern:
                                                          '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
                                                      },
                                                      message:
                                                        'should match pattern "^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$"',
                                                    };
                                                    if (vErrors === null)
                                                      vErrors = [err];
                                                    else vErrors.push(err);
                                                    errors++;
                                                  } else {
                                                  }
                                                } else {
                                                  var err = {
                                                    keyword: 'type',
                                                    dataPath:
                                                      (dataPath || '') +
                                                      '.jsonTemplate.foregroundColor.fallback',
                                                    schemaPath:
                                                      '#/definitions/colorProperty/oneOf/1/properties/fallback/type',
                                                    params: {
                                                      type: 'string',
                                                    },
                                                    message: 'should be string',
                                                  };
                                                  if (vErrors === null)
                                                    vErrors = [err];
                                                  else vErrors.push(err);
                                                  errors++;
                                                }
                                                if (errors === errs_5) {
                                                }
                                                var valid5 = errors === errs_5;
                                              }
                                              if (valid5) {
                                              }
                                            }
                                          }
                                          if (errs__4 == errors) {
                                          }
                                        }
                                      }
                                      if (errors === errs_4) {
                                      }
                                      var valid4 = errors === errs_4;
                                      if (valid4 && prevValid3) {
                                        valid3 = false;
                                        passingSchemas3 = [passingSchemas3, 1];
                                      } else {
                                        if (valid4) {
                                          valid3 = prevValid3 = true;
                                          passingSchemas3 = 1;
                                        }
                                      }
                                      if (!valid3) {
                                        var err = {
                                          keyword: 'oneOf',
                                          dataPath:
                                            (dataPath || '') +
                                            '.jsonTemplate.foregroundColor',
                                          schemaPath:
                                            '#/definitions/colorProperty/oneOf',
                                          params: {
                                            passingSchemas: passingSchemas3,
                                          },
                                          message:
                                            'should match exactly one schema in oneOf',
                                        };
                                        if (vErrors === null) vErrors = [err];
                                        else vErrors.push(err);
                                        errors++;
                                        validate.errors = vErrors;
                                        return false;
                                      } else {
                                        errors = errs__3;
                                        if (vErrors !== null) {
                                          if (errs__3) vErrors.length = errs__3;
                                          else vErrors = null;
                                        }
                                      }
                                      if (errors === errs_3) {
                                      }
                                      var valid3 = errors === errs_3;
                                      if (valid3) {
                                      }
                                      if (errors === errs_2) {
                                      }
                                      var valid2 = errors === errs_2;
                                    }
                                    if (valid2) {
                                      var data2 = data1.sharingProhibited;
                                      if (data2 === undefined) {
                                        valid2 = true;
                                      } else {
                                        var errs_2 = errors;
                                        var errs_3 = errors;
                                        if (
                                          !data2 ||
                                          typeof data2 !== 'object' ||
                                          Array.isArray(data2)
                                        ) {
                                          validate.errors = [
                                            {
                                              keyword: 'type',
                                              dataPath:
                                                (dataPath || '') +
                                                '.jsonTemplate.sharingProhibited',
                                              schemaPath:
                                                '#/definitions/booleanProperty/type',
                                              params: {
                                                type: 'object',
                                              },
                                              message: 'should be object',
                                            },
                                          ];
                                          return false;
                                        }
                                        var errs__3 = errors,
                                          prevValid3 = false,
                                          valid3 = false,
                                          passingSchemas3 = null;
                                        var errs_4 = errors;
                                        if (
                                          data2 &&
                                          typeof data2 === 'object' &&
                                          !Array.isArray(data2)
                                        ) {
                                          if (true) {
                                            var errs__4 = errors;
                                            var valid5 = true;
                                            var data3 = data2.type;
                                            if (data3 === undefined) {
                                              valid5 = false;
                                              var err = {
                                                keyword: 'required',
                                                dataPath:
                                                  (dataPath || '') +
                                                  '.jsonTemplate.sharingProhibited',
                                                schemaPath:
                                                  '#/definitions/booleanProperty/oneOf/0/required',
                                                params: {
                                                  missingProperty: 'type',
                                                },
                                                message:
                                                  "should have required property 'type'",
                                              };
                                              if (vErrors === null)
                                                vErrors = [err];
                                              else vErrors.push(err);
                                              errors++;
                                            } else {
                                              var errs_5 = errors;
                                              if (typeof data3 === 'string') {
                                                if (!pattern8.test(data3)) {
                                                  var err = {
                                                    keyword: 'pattern',
                                                    dataPath:
                                                      (dataPath || '') +
                                                      '.jsonTemplate.sharingProhibited.type',
                                                    schemaPath:
                                                      '#/definitions/booleanProperty/oneOf/0/properties/type/pattern',
                                                    params: {
                                                      pattern: 'boolean',
                                                    },
                                                    message:
                                                      'should match pattern "boolean"',
                                                  };
                                                  if (vErrors === null)
                                                    vErrors = [err];
                                                  else vErrors.push(err);
                                                  errors++;
                                                } else {
                                                }
                                              } else {
                                                var err = {
                                                  keyword: 'type',
                                                  dataPath:
                                                    (dataPath || '') +
                                                    '.jsonTemplate.sharingProhibited.type',
                                                  schemaPath:
                                                    '#/definitions/booleanProperty/oneOf/0/properties/type/type',
                                                  params: {
                                                    type: 'string',
                                                  },
                                                  message: 'should be string',
                                                };
                                                if (vErrors === null)
                                                  vErrors = [err];
                                                else vErrors.push(err);
                                                errors++;
                                              }
                                              if (errors === errs_5) {
                                              }
                                              var valid5 = errors === errs_5;
                                            }
                                            if (valid5) {
                                              if (data2.value === undefined) {
                                                valid5 = false;
                                                var err = {
                                                  keyword: 'required',
                                                  dataPath:
                                                    (dataPath || '') +
                                                    '.jsonTemplate.sharingProhibited',
                                                  schemaPath:
                                                    '#/definitions/booleanProperty/oneOf/0/required',
                                                  params: {
                                                    missingProperty: 'value',
                                                  },
                                                  message:
                                                    "should have required property 'value'",
                                                };
                                                if (vErrors === null)
                                                  vErrors = [err];
                                                else vErrors.push(err);
                                                errors++;
                                              } else {
                                                var errs_5 = errors;
                                                if (
                                                  typeof data2.value !==
                                                  'boolean'
                                                ) {
                                                  var err = {
                                                    keyword: 'type',
                                                    dataPath:
                                                      (dataPath || '') +
                                                      '.jsonTemplate.sharingProhibited.value',
                                                    schemaPath:
                                                      '#/definitions/booleanProperty/oneOf/0/properties/value/type',
                                                    params: {
                                                      type: 'boolean',
                                                    },
                                                    message:
                                                      'should be boolean',
                                                  };
                                                  if (vErrors === null)
                                                    vErrors = [err];
                                                  else vErrors.push(err);
                                                  errors++;
                                                }
                                                var valid5 = errors === errs_5;
                                              }
                                              if (valid5) {
                                              }
                                            }
                                            if (errs__4 == errors) {
                                            }
                                          }
                                        }
                                        if (errors === errs_4) {
                                        }
                                        var valid4 = errors === errs_4;
                                        if (valid4) {
                                          valid3 = prevValid3 = true;
                                          passingSchemas3 = 0;
                                        }
                                        var errs_4 = errors;
                                        if (
                                          data2 &&
                                          typeof data2 === 'object' &&
                                          !Array.isArray(data2)
                                        ) {
                                          if (true) {
                                            var errs__4 = errors;
                                            var valid5 = true;
                                            var data3 = data2.type;
                                            if (data3 === undefined) {
                                              valid5 = false;
                                              var err = {
                                                keyword: 'required',
                                                dataPath:
                                                  (dataPath || '') +
                                                  '.jsonTemplate.sharingProhibited',
                                                schemaPath:
                                                  '#/definitions/booleanProperty/oneOf/1/required',
                                                params: {
                                                  missingProperty: 'type',
                                                },
                                                message:
                                                  "should have required property 'type'",
                                              };
                                              if (vErrors === null)
                                                vErrors = [err];
                                              else vErrors.push(err);
                                              errors++;
                                            } else {
                                              var errs_5 = errors;
                                              if (typeof data3 === 'string') {
                                                if (!pattern8.test(data3)) {
                                                  var err = {
                                                    keyword: 'pattern',
                                                    dataPath:
                                                      (dataPath || '') +
                                                      '.jsonTemplate.sharingProhibited.type',
                                                    schemaPath:
                                                      '#/definitions/booleanProperty/oneOf/1/properties/type/pattern',
                                                    params: {
                                                      pattern: 'boolean',
                                                    },
                                                    message:
                                                      'should match pattern "boolean"',
                                                  };
                                                  if (vErrors === null)
                                                    vErrors = [err];
                                                  else vErrors.push(err);
                                                  errors++;
                                                } else {
                                                }
                                              } else {
                                                var err = {
                                                  keyword: 'type',
                                                  dataPath:
                                                    (dataPath || '') +
                                                    '.jsonTemplate.sharingProhibited.type',
                                                  schemaPath:
                                                    '#/definitions/booleanProperty/oneOf/1/properties/type/type',
                                                  params: {
                                                    type: 'string',
                                                  },
                                                  message: 'should be string',
                                                };
                                                if (vErrors === null)
                                                  vErrors = [err];
                                                else vErrors.push(err);
                                                errors++;
                                              }
                                              if (errors === errs_5) {
                                              }
                                              var valid5 = errors === errs_5;
                                            }
                                            if (valid5) {
                                              var data3 = data2.value;
                                              if (data3 === undefined) {
                                                valid5 = false;
                                                var err = {
                                                  keyword: 'required',
                                                  dataPath:
                                                    (dataPath || '') +
                                                    '.jsonTemplate.sharingProhibited',
                                                  schemaPath:
                                                    '#/definitions/booleanProperty/oneOf/1/required',
                                                  params: {
                                                    missingProperty: 'value',
                                                  },
                                                  message:
                                                    "should have required property 'value'",
                                                };
                                                if (vErrors === null)
                                                  vErrors = [err];
                                                else vErrors.push(err);
                                                errors++;
                                              } else {
                                                var errs_5 = errors;
                                                if (typeof data3 === 'string') {
                                                  if (!pattern4.test(data3)) {
                                                    var err = {
                                                      keyword: 'pattern',
                                                      dataPath:
                                                        (dataPath || '') +
                                                        '.jsonTemplate.sharingProhibited.value',
                                                      schemaPath:
                                                        '#/definitions/booleanProperty/oneOf/1/properties/value/pattern',
                                                      params: {
                                                        pattern: '^#\\{.+\\}$',
                                                      },
                                                      message:
                                                        'should match pattern "^#\\{.+\\}$"',
                                                    };
                                                    if (vErrors === null)
                                                      vErrors = [err];
                                                    else vErrors.push(err);
                                                    errors++;
                                                  } else {
                                                  }
                                                } else {
                                                  var err = {
                                                    keyword: 'type',
                                                    dataPath:
                                                      (dataPath || '') +
                                                      '.jsonTemplate.sharingProhibited.value',
                                                    schemaPath:
                                                      '#/definitions/booleanProperty/oneOf/1/properties/value/type',
                                                    params: {
                                                      type: 'string',
                                                    },
                                                    message: 'should be string',
                                                  };
                                                  if (vErrors === null)
                                                    vErrors = [err];
                                                  else vErrors.push(err);
                                                  errors++;
                                                }
                                                if (errors === errs_5) {
                                                }
                                                var valid5 = errors === errs_5;
                                              }
                                              if (valid5) {
                                                if (
                                                  data2.fallback === undefined
                                                ) {
                                                  valid5 = false;
                                                  var err = {
                                                    keyword: 'required',
                                                    dataPath:
                                                      (dataPath || '') +
                                                      '.jsonTemplate.sharingProhibited',
                                                    schemaPath:
                                                      '#/definitions/booleanProperty/oneOf/1/required',
                                                    params: {
                                                      missingProperty:
                                                        'fallback',
                                                    },
                                                    message:
                                                      "should have required property 'fallback'",
                                                  };
                                                  if (vErrors === null)
                                                    vErrors = [err];
                                                  else vErrors.push(err);
                                                  errors++;
                                                } else {
                                                  var errs_5 = errors;
                                                  if (
                                                    typeof data2.fallback !==
                                                    'boolean'
                                                  ) {
                                                    var err = {
                                                      keyword: 'type',
                                                      dataPath:
                                                        (dataPath || '') +
                                                        '.jsonTemplate.sharingProhibited.fallback',
                                                      schemaPath:
                                                        '#/definitions/booleanProperty/oneOf/1/properties/fallback/type',
                                                      params: {
                                                        type: 'boolean',
                                                      },
                                                      message:
                                                        'should be boolean',
                                                    };
                                                    if (vErrors === null)
                                                      vErrors = [err];
                                                    else vErrors.push(err);
                                                    errors++;
                                                  }
                                                  var valid5 =
                                                    errors === errs_5;
                                                }
                                                if (valid5) {
                                                }
                                              }
                                            }
                                            if (errs__4 == errors) {
                                            }
                                          }
                                        }
                                        if (errors === errs_4) {
                                        }
                                        var valid4 = errors === errs_4;
                                        if (valid4 && prevValid3) {
                                          valid3 = false;
                                          passingSchemas3 = [
                                            passingSchemas3,
                                            1,
                                          ];
                                        } else {
                                          if (valid4) {
                                            valid3 = prevValid3 = true;
                                            passingSchemas3 = 1;
                                          }
                                        }
                                        if (!valid3) {
                                          var err = {
                                            keyword: 'oneOf',
                                            dataPath:
                                              (dataPath || '') +
                                              '.jsonTemplate.sharingProhibited',
                                            schemaPath:
                                              '#/definitions/booleanProperty/oneOf',
                                            params: {
                                              passingSchemas: passingSchemas3,
                                            },
                                            message:
                                              'should match exactly one schema in oneOf',
                                          };
                                          if (vErrors === null) vErrors = [err];
                                          else vErrors.push(err);
                                          errors++;
                                          validate.errors = vErrors;
                                          return false;
                                        } else {
                                          errors = errs__3;
                                          if (vErrors !== null) {
                                            if (errs__3)
                                              vErrors.length = errs__3;
                                            else vErrors = null;
                                          }
                                        }
                                        if (errors === errs_3) {
                                        }
                                        var valid3 = errors === errs_3;
                                        if (valid3) {
                                        }
                                        if (errors === errs_2) {
                                        }
                                        var valid2 = errors === errs_2;
                                      }
                                      if (valid2) {
                                        if (
                                          data1.organizationName === undefined
                                        ) {
                                          valid2 = false;
                                          validate.errors = [
                                            {
                                              keyword: 'required',
                                              dataPath:
                                                (dataPath || '') +
                                                '.jsonTemplate',
                                              schemaPath:
                                                '#/properties/jsonTemplate/required',
                                              params: {
                                                missingProperty:
                                                  'organizationName',
                                              },
                                              message:
                                                "should have required property 'organizationName'",
                                            },
                                          ];
                                          return false;
                                        } else {
                                          var errs_2 = errors;
                                          if (
                                            !refVal[10](
                                              data1.organizationName,
                                              (dataPath || '') +
                                                '.jsonTemplate.organizationName',
                                              data1,
                                              'organizationName',
                                              rootData,
                                            )
                                          ) {
                                            if (vErrors === null)
                                              vErrors = refVal[10].errors;
                                            else
                                              vErrors = vErrors.concat(
                                                refVal[10].errors,
                                              );
                                            errors = vErrors.length;
                                          } else {
                                          }
                                          if (errors === errs_2) {
                                          }
                                          var valid2 = errors === errs_2;
                                        }
                                        if (valid2) {
                                          if (
                                            data1.groupingIdentifier ===
                                            undefined
                                          ) {
                                            valid2 = true;
                                          } else {
                                            var errs_2 = errors;
                                            if (
                                              !refVal[3](
                                                data1.groupingIdentifier,
                                                (dataPath || '') +
                                                  '.jsonTemplate.groupingIdentifier',
                                                data1,
                                                'groupingIdentifier',
                                                rootData,
                                              )
                                            ) {
                                              if (vErrors === null)
                                                vErrors = refVal[3].errors;
                                              else
                                                vErrors = vErrors.concat(
                                                  refVal[3].errors,
                                                );
                                              errors = vErrors.length;
                                            } else {
                                            }
                                            if (errors === errs_2) {
                                            }
                                            var valid2 = errors === errs_2;
                                          }
                                          if (valid2) {
                                            var data2 =
                                              data1.associatedStoreIdentifiers;
                                            if (data2 === undefined) {
                                              valid2 = true;
                                            } else {
                                              var errs_2 = errors;
                                              if (
                                                data2 &&
                                                typeof data2 === 'object' &&
                                                !Array.isArray(data2)
                                              ) {
                                                var errs__2 = errors;
                                                var valid3 = true;
                                                if (data2.type === undefined) {
                                                  valid3 = true;
                                                } else {
                                                  var errs_3 = errors;
                                                  if (
                                                    typeof data2.type !==
                                                    'string'
                                                  ) {
                                                    validate.errors = [
                                                      {
                                                        keyword: 'type',
                                                        dataPath:
                                                          (dataPath || '') +
                                                          '.jsonTemplate.associatedStoreIdentifiers.type',
                                                        schemaPath:
                                                          '#/properties/jsonTemplate/properties/associatedStoreIdentifiers/properties/type/type',
                                                        params: {
                                                          type: 'string',
                                                        },
                                                        message:
                                                          'should be string',
                                                      },
                                                    ];
                                                    return false;
                                                  }
                                                  var valid3 =
                                                    errors === errs_3;
                                                }
                                                if (valid3) {
                                                  var data3 = data2.items;
                                                  if (data3 === undefined) {
                                                    valid3 = true;
                                                  } else {
                                                    var errs_3 = errors;
                                                    if (Array.isArray(data3)) {
                                                      var errs__3 = errors;
                                                      var valid3;
                                                      for (
                                                        var i3 = 0;
                                                        i3 < data3.length;
                                                        i3++
                                                      ) {
                                                        var data4 = data3[i3];
                                                        var errs_4 = errors;
                                                        if (
                                                          data4 &&
                                                          typeof data4 ===
                                                            'object' &&
                                                          !Array.isArray(data4)
                                                        ) {
                                                          var errs__4 = errors;
                                                          var valid5 = true;
                                                          if (
                                                            data4.type ===
                                                            undefined
                                                          ) {
                                                            valid5 = true;
                                                          } else {
                                                            var errs_5 = errors;
                                                            if (
                                                              typeof data4.type !==
                                                              'string'
                                                            ) {
                                                              validate.errors = [
                                                                {
                                                                  keyword:
                                                                    'type',
                                                                  dataPath:
                                                                    (dataPath ||
                                                                      '') +
                                                                    '.jsonTemplate.associatedStoreIdentifiers.items[' +
                                                                    i3 +
                                                                    '].type',
                                                                  schemaPath:
                                                                    '#/properties/jsonTemplate/properties/associatedStoreIdentifiers/properties/items/items/properties/type/type',
                                                                  params: {
                                                                    type:
                                                                      'string',
                                                                  },
                                                                  message:
                                                                    'should be string',
                                                                },
                                                              ];
                                                              return false;
                                                            }
                                                            var valid5 =
                                                              errors === errs_5;
                                                          }
                                                          if (valid5) {
                                                            var data5 =
                                                              data4.value;
                                                            if (
                                                              data5 ===
                                                              undefined
                                                            ) {
                                                              valid5 = true;
                                                            } else {
                                                              var errs_5 = errors;
                                                              if (
                                                                typeof data5 !==
                                                                  'number' ||
                                                                data5 % 1 ||
                                                                data5 !== data5
                                                              ) {
                                                                validate.errors = [
                                                                  {
                                                                    keyword:
                                                                      'type',
                                                                    dataPath:
                                                                      (dataPath ||
                                                                        '') +
                                                                      '.jsonTemplate.associatedStoreIdentifiers.items[' +
                                                                      i3 +
                                                                      '].value',
                                                                    schemaPath:
                                                                      '#/properties/jsonTemplate/properties/associatedStoreIdentifiers/properties/items/items/properties/value/type',
                                                                    params: {
                                                                      type:
                                                                        'integer',
                                                                    },
                                                                    message:
                                                                      'should be integer',
                                                                  },
                                                                ];
                                                                return false;
                                                              }
                                                              var valid5 =
                                                                errors ===
                                                                errs_5;
                                                            }
                                                            if (valid5) {
                                                            }
                                                          }
                                                          if (
                                                            errs__4 == errors
                                                          ) {
                                                          }
                                                        } else {
                                                          validate.errors = [
                                                            {
                                                              keyword: 'type',
                                                              dataPath:
                                                                (dataPath ||
                                                                  '') +
                                                                '.jsonTemplate.associatedStoreIdentifiers.items[' +
                                                                i3 +
                                                                ']',
                                                              schemaPath:
                                                                '#/properties/jsonTemplate/properties/associatedStoreIdentifiers/properties/items/items/type',
                                                              params: {
                                                                type: 'object',
                                                              },
                                                              message:
                                                                'should be object',
                                                            },
                                                          ];
                                                          return false;
                                                        }
                                                        if (errors === errs_4) {
                                                        }
                                                        var valid4 =
                                                          errors === errs_4;
                                                        if (!valid4) break;
                                                      }
                                                      if (errs__3 == errors) {
                                                      }
                                                    } else {
                                                      validate.errors = [
                                                        {
                                                          keyword: 'type',
                                                          dataPath:
                                                            (dataPath || '') +
                                                            '.jsonTemplate.associatedStoreIdentifiers.items',
                                                          schemaPath:
                                                            '#/properties/jsonTemplate/properties/associatedStoreIdentifiers/properties/items/type',
                                                          params: {
                                                            type: 'array',
                                                          },
                                                          message:
                                                            'should be array',
                                                        },
                                                      ];
                                                      return false;
                                                    }
                                                    if (errors === errs_3) {
                                                    }
                                                    var valid3 =
                                                      errors === errs_3;
                                                  }
                                                  if (valid3) {
                                                  }
                                                }
                                                if (errs__2 == errors) {
                                                }
                                              } else {
                                                validate.errors = [
                                                  {
                                                    keyword: 'type',
                                                    dataPath:
                                                      (dataPath || '') +
                                                      '.jsonTemplate.associatedStoreIdentifiers',
                                                    schemaPath:
                                                      '#/properties/jsonTemplate/properties/associatedStoreIdentifiers/type',
                                                    params: {
                                                      type: 'object',
                                                    },
                                                    message: 'should be object',
                                                  },
                                                ];
                                                return false;
                                              }
                                              if (errors === errs_2) {
                                              }
                                              var valid2 = errors === errs_2;
                                            }
                                            if (valid2) {
                                              if (
                                                data1.boardingPass === undefined
                                              ) {
                                                valid2 = true;
                                              } else {
                                                var errs_2 = errors;
                                                if (
                                                  !refVal14(
                                                    data1.boardingPass,
                                                    (dataPath || '') +
                                                      '.jsonTemplate.boardingPass',
                                                    data1,
                                                    'boardingPass',
                                                    rootData,
                                                  )
                                                ) {
                                                  if (vErrors === null)
                                                    vErrors = refVal14.errors;
                                                  else
                                                    vErrors = vErrors.concat(
                                                      refVal14.errors,
                                                    );
                                                  errors = vErrors.length;
                                                } else {
                                                }
                                                if (errors === errs_2) {
                                                }
                                                var valid2 = errors === errs_2;
                                              }
                                              if (valid2) {
                                                if (
                                                  data1.coupon === undefined
                                                ) {
                                                  valid2 = true;
                                                } else {
                                                  var errs_2 = errors;
                                                  if (
                                                    !refVal26(
                                                      data1.coupon,
                                                      (dataPath || '') +
                                                        '.jsonTemplate.coupon',
                                                      data1,
                                                      'coupon',
                                                      rootData,
                                                    )
                                                  ) {
                                                    if (vErrors === null)
                                                      vErrors = refVal26.errors;
                                                    else
                                                      vErrors = vErrors.concat(
                                                        refVal26.errors,
                                                      );
                                                    errors = vErrors.length;
                                                  } else {
                                                  }
                                                  if (errors === errs_2) {
                                                  }
                                                  var valid2 =
                                                    errors === errs_2;
                                                }
                                                if (valid2) {
                                                  if (
                                                    data1.eventTicket ===
                                                    undefined
                                                  ) {
                                                    valid2 = true;
                                                  } else {
                                                    var errs_2 = errors;
                                                    if (
                                                      !refVal[26](
                                                        data1.eventTicket,
                                                        (dataPath || '') +
                                                          '.jsonTemplate.eventTicket',
                                                        data1,
                                                        'eventTicket',
                                                        rootData,
                                                      )
                                                    ) {
                                                      if (vErrors === null)
                                                        vErrors =
                                                          refVal[26].errors;
                                                      else
                                                        vErrors = vErrors.concat(
                                                          refVal[26].errors,
                                                        );
                                                      errors = vErrors.length;
                                                    } else {
                                                    }
                                                    if (errors === errs_2) {
                                                    }
                                                    var valid2 =
                                                      errors === errs_2;
                                                  }
                                                  if (valid2) {
                                                    if (
                                                      data1.generic ===
                                                      undefined
                                                    ) {
                                                      valid2 = true;
                                                    } else {
                                                      var errs_2 = errors;
                                                      if (
                                                        !refVal[26](
                                                          data1.generic,
                                                          (dataPath || '') +
                                                            '.jsonTemplate.generic',
                                                          data1,
                                                          'generic',
                                                          rootData,
                                                        )
                                                      ) {
                                                        if (vErrors === null)
                                                          vErrors =
                                                            refVal[26].errors;
                                                        else
                                                          vErrors = vErrors.concat(
                                                            refVal[26].errors,
                                                          );
                                                        errors = vErrors.length;
                                                      } else {
                                                      }
                                                      if (errors === errs_2) {
                                                      }
                                                      var valid2 =
                                                        errors === errs_2;
                                                    }
                                                    if (valid2) {
                                                      if (
                                                        data1.storeCard ===
                                                        undefined
                                                      ) {
                                                        valid2 = true;
                                                      } else {
                                                        var errs_2 = errors;
                                                        if (
                                                          !refVal[26](
                                                            data1.storeCard,
                                                            (dataPath || '') +
                                                              '.jsonTemplate.storeCard',
                                                            data1,
                                                            'storeCard',
                                                            rootData,
                                                          )
                                                        ) {
                                                          if (vErrors === null)
                                                            vErrors =
                                                              refVal[26].errors;
                                                          else
                                                            vErrors = vErrors.concat(
                                                              refVal[26].errors,
                                                            );
                                                          errors =
                                                            vErrors.length;
                                                        } else {
                                                        }
                                                        if (errors === errs_2) {
                                                        }
                                                        var valid2 =
                                                          errors === errs_2;
                                                      }
                                                      if (valid2) {
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                if (errs__1 == errors) {
                }
              }
            } else {
              validate.errors = [
                {
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.jsonTemplate',
                  schemaPath: '#/properties/jsonTemplate/type',
                  params: {
                    type: 'object',
                  },
                  message: 'should be object',
                },
              ];
              return false;
            }
            if (errors === errs_1) {
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            var data1 = data.dynamicDataJsonSchema;
            if (data1 === undefined) {
              valid1 = true;
            } else {
              var errs_1 = errors;
              if (data1 && typeof data1 === 'object' && !Array.isArray(data1)) {
                var errs__1 = errors;
                var valid2 = true;
                if (errs__1 == errors) {
                }
              } else {
                validate.errors = [
                  {
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.dynamicDataJsonSchema',
                    schemaPath: '#/properties/dynamicDataJsonSchema/type',
                    params: {
                      type: 'object',
                    },
                    message: 'should be object',
                  },
                ];
                return false;
              }
              if (errors === errs_1) {
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              if (data.projectId === undefined) {
                valid1 = true;
              } else {
                var errs_1 = errors;
                if (typeof data.projectId !== 'string') {
                  validate.errors = [
                    {
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.projectId',
                      schemaPath: '#/properties/projectId/type',
                      params: {
                        type: 'string',
                      },
                      message: 'should be string',
                    },
                  ];
                  return false;
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                if (data.certificateId === undefined) {
                  valid1 = true;
                } else {
                  var errs_1 = errors;
                  if (typeof data.certificateId !== 'string') {
                    validate.errors = [
                      {
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.certificateId',
                        schemaPath: '#/properties/certificateId/type',
                        params: {
                          type: 'string',
                        },
                        message: 'should be string',
                      },
                    ];
                    return false;
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  var data1 = data.images;
                  if (data1 === undefined) {
                    valid1 = true;
                  } else {
                    var errs_1 = errors;
                    if (
                      data1 &&
                      typeof data1 === 'object' &&
                      !Array.isArray(data1)
                    ) {
                      var errs__1 = errors;
                      var valid2 = true;
                      var data2 = data1.background;
                      if (data2 === undefined) {
                        valid2 = true;
                      } else {
                        var errs_2 = errors;
                        var errs_3 = errors;
                        var errs__3 = errors,
                          prevValid3 = false,
                          valid3 = false,
                          passingSchemas3 = null;
                        var errs_4 = errors;
                        if (typeof data2 !== 'string') {
                          var err = {
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.images.background',
                            schemaPath: '#/definitions/image/oneOf/0/type',
                            params: {
                              type: 'string',
                            },
                            message: 'should be string',
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                        }
                        var valid4 = errors === errs_4;
                        if (valid4) {
                          valid3 = prevValid3 = true;
                          passingSchemas3 = 0;
                        }
                        var errs_4 = errors;
                        if (
                          !data2 ||
                          typeof data2 !== 'object' ||
                          Array.isArray(data2)
                        ) {
                          var err = {
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.images.background',
                            schemaPath: '#/definitions/image/oneOf/1/type',
                            params: {
                              type: 'object',
                            },
                            message: 'should be object',
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                        }
                        var valid4 = errors === errs_4;
                        if (valid4 && prevValid3) {
                          valid3 = false;
                          passingSchemas3 = [passingSchemas3, 1];
                        } else {
                          if (valid4) {
                            valid3 = prevValid3 = true;
                            passingSchemas3 = 1;
                          }
                        }
                        if (!valid3) {
                          var err = {
                            keyword: 'oneOf',
                            dataPath: (dataPath || '') + '.images.background',
                            schemaPath: '#/definitions/image/oneOf',
                            params: {
                              passingSchemas: passingSchemas3,
                            },
                            message: 'should match exactly one schema in oneOf',
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                          validate.errors = vErrors;
                          return false;
                        } else {
                          errors = errs__3;
                          if (vErrors !== null) {
                            if (errs__3) vErrors.length = errs__3;
                            else vErrors = null;
                          }
                        }
                        if (errors === errs_3) {
                        }
                        var valid3 = errors === errs_3;
                        if (valid3) {
                        }
                        if (errors === errs_2) {
                        }
                        var valid2 = errors === errs_2;
                      }
                      if (valid2) {
                        var data2 = data1.logo;
                        if (data2 === undefined) {
                          valid2 = true;
                        } else {
                          var errs_2 = errors;
                          var errs_3 = errors;
                          var errs__3 = errors,
                            prevValid3 = false,
                            valid3 = false,
                            passingSchemas3 = null;
                          var errs_4 = errors;
                          if (typeof data2 !== 'string') {
                            var err = {
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.images.logo',
                              schemaPath: '#/definitions/image/oneOf/0/type',
                              params: {
                                type: 'string',
                              },
                              message: 'should be string',
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          }
                          var valid4 = errors === errs_4;
                          if (valid4) {
                            valid3 = prevValid3 = true;
                            passingSchemas3 = 0;
                          }
                          var errs_4 = errors;
                          if (
                            !data2 ||
                            typeof data2 !== 'object' ||
                            Array.isArray(data2)
                          ) {
                            var err = {
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.images.logo',
                              schemaPath: '#/definitions/image/oneOf/1/type',
                              params: {
                                type: 'object',
                              },
                              message: 'should be object',
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          }
                          var valid4 = errors === errs_4;
                          if (valid4 && prevValid3) {
                            valid3 = false;
                            passingSchemas3 = [passingSchemas3, 1];
                          } else {
                            if (valid4) {
                              valid3 = prevValid3 = true;
                              passingSchemas3 = 1;
                            }
                          }
                          if (!valid3) {
                            var err = {
                              keyword: 'oneOf',
                              dataPath: (dataPath || '') + '.images.logo',
                              schemaPath: '#/definitions/image/oneOf',
                              params: {
                                passingSchemas: passingSchemas3,
                              },
                              message:
                                'should match exactly one schema in oneOf',
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                            validate.errors = vErrors;
                            return false;
                          } else {
                            errors = errs__3;
                            if (vErrors !== null) {
                              if (errs__3) vErrors.length = errs__3;
                              else vErrors = null;
                            }
                          }
                          if (errors === errs_3) {
                          }
                          var valid3 = errors === errs_3;
                          if (valid3) {
                          }
                          if (errors === errs_2) {
                          }
                          var valid2 = errors === errs_2;
                        }
                        if (valid2) {
                          var data2 = data1.icon;
                          if (data2 === undefined) {
                            valid2 = true;
                          } else {
                            var errs_2 = errors;
                            var errs_3 = errors;
                            var errs__3 = errors,
                              prevValid3 = false,
                              valid3 = false,
                              passingSchemas3 = null;
                            var errs_4 = errors;
                            if (typeof data2 !== 'string') {
                              var err = {
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.images.icon',
                                schemaPath: '#/definitions/image/oneOf/0/type',
                                params: {
                                  type: 'string',
                                },
                                message: 'should be string',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            }
                            var valid4 = errors === errs_4;
                            if (valid4) {
                              valid3 = prevValid3 = true;
                              passingSchemas3 = 0;
                            }
                            var errs_4 = errors;
                            if (
                              !data2 ||
                              typeof data2 !== 'object' ||
                              Array.isArray(data2)
                            ) {
                              var err = {
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.images.icon',
                                schemaPath: '#/definitions/image/oneOf/1/type',
                                params: {
                                  type: 'object',
                                },
                                message: 'should be object',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            }
                            var valid4 = errors === errs_4;
                            if (valid4 && prevValid3) {
                              valid3 = false;
                              passingSchemas3 = [passingSchemas3, 1];
                            } else {
                              if (valid4) {
                                valid3 = prevValid3 = true;
                                passingSchemas3 = 1;
                              }
                            }
                            if (!valid3) {
                              var err = {
                                keyword: 'oneOf',
                                dataPath: (dataPath || '') + '.images.icon',
                                schemaPath: '#/definitions/image/oneOf',
                                params: {
                                  passingSchemas: passingSchemas3,
                                },
                                message:
                                  'should match exactly one schema in oneOf',
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                              validate.errors = vErrors;
                              return false;
                            } else {
                              errors = errs__3;
                              if (vErrors !== null) {
                                if (errs__3) vErrors.length = errs__3;
                                else vErrors = null;
                              }
                            }
                            if (errors === errs_3) {
                            }
                            var valid3 = errors === errs_3;
                            if (valid3) {
                            }
                            if (errors === errs_2) {
                            }
                            var valid2 = errors === errs_2;
                          }
                          if (valid2) {
                            var data2 = data1.strip;
                            if (data2 === undefined) {
                              valid2 = true;
                            } else {
                              var errs_2 = errors;
                              var errs_3 = errors;
                              var errs__3 = errors,
                                prevValid3 = false,
                                valid3 = false,
                                passingSchemas3 = null;
                              var errs_4 = errors;
                              if (typeof data2 !== 'string') {
                                var err = {
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.images.strip',
                                  schemaPath:
                                    '#/definitions/image/oneOf/0/type',
                                  params: {
                                    type: 'string',
                                  },
                                  message: 'should be string',
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                              }
                              var valid4 = errors === errs_4;
                              if (valid4) {
                                valid3 = prevValid3 = true;
                                passingSchemas3 = 0;
                              }
                              var errs_4 = errors;
                              if (
                                !data2 ||
                                typeof data2 !== 'object' ||
                                Array.isArray(data2)
                              ) {
                                var err = {
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.images.strip',
                                  schemaPath:
                                    '#/definitions/image/oneOf/1/type',
                                  params: {
                                    type: 'object',
                                  },
                                  message: 'should be object',
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                              }
                              var valid4 = errors === errs_4;
                              if (valid4 && prevValid3) {
                                valid3 = false;
                                passingSchemas3 = [passingSchemas3, 1];
                              } else {
                                if (valid4) {
                                  valid3 = prevValid3 = true;
                                  passingSchemas3 = 1;
                                }
                              }
                              if (!valid3) {
                                var err = {
                                  keyword: 'oneOf',
                                  dataPath: (dataPath || '') + '.images.strip',
                                  schemaPath: '#/definitions/image/oneOf',
                                  params: {
                                    passingSchemas: passingSchemas3,
                                  },
                                  message:
                                    'should match exactly one schema in oneOf',
                                };
                                if (vErrors === null) vErrors = [err];
                                else vErrors.push(err);
                                errors++;
                                validate.errors = vErrors;
                                return false;
                              } else {
                                errors = errs__3;
                                if (vErrors !== null) {
                                  if (errs__3) vErrors.length = errs__3;
                                  else vErrors = null;
                                }
                              }
                              if (errors === errs_3) {
                              }
                              var valid3 = errors === errs_3;
                              if (valid3) {
                              }
                              if (errors === errs_2) {
                              }
                              var valid2 = errors === errs_2;
                            }
                            if (valid2) {
                              var data2 = data1.thumbnail;
                              if (data2 === undefined) {
                                valid2 = true;
                              } else {
                                var errs_2 = errors;
                                var errs_3 = errors;
                                var errs__3 = errors,
                                  prevValid3 = false,
                                  valid3 = false,
                                  passingSchemas3 = null;
                                var errs_4 = errors;
                                if (typeof data2 !== 'string') {
                                  var err = {
                                    keyword: 'type',
                                    dataPath:
                                      (dataPath || '') + '.images.thumbnail',
                                    schemaPath:
                                      '#/definitions/image/oneOf/0/type',
                                    params: {
                                      type: 'string',
                                    },
                                    message: 'should be string',
                                  };
                                  if (vErrors === null) vErrors = [err];
                                  else vErrors.push(err);
                                  errors++;
                                }
                                var valid4 = errors === errs_4;
                                if (valid4) {
                                  valid3 = prevValid3 = true;
                                  passingSchemas3 = 0;
                                }
                                var errs_4 = errors;
                                if (
                                  !data2 ||
                                  typeof data2 !== 'object' ||
                                  Array.isArray(data2)
                                ) {
                                  var err = {
                                    keyword: 'type',
                                    dataPath:
                                      (dataPath || '') + '.images.thumbnail',
                                    schemaPath:
                                      '#/definitions/image/oneOf/1/type',
                                    params: {
                                      type: 'object',
                                    },
                                    message: 'should be object',
                                  };
                                  if (vErrors === null) vErrors = [err];
                                  else vErrors.push(err);
                                  errors++;
                                }
                                var valid4 = errors === errs_4;
                                if (valid4 && prevValid3) {
                                  valid3 = false;
                                  passingSchemas3 = [passingSchemas3, 1];
                                } else {
                                  if (valid4) {
                                    valid3 = prevValid3 = true;
                                    passingSchemas3 = 1;
                                  }
                                }
                                if (!valid3) {
                                  var err = {
                                    keyword: 'oneOf',
                                    dataPath:
                                      (dataPath || '') + '.images.thumbnail',
                                    schemaPath: '#/definitions/image/oneOf',
                                    params: {
                                      passingSchemas: passingSchemas3,
                                    },
                                    message:
                                      'should match exactly one schema in oneOf',
                                  };
                                  if (vErrors === null) vErrors = [err];
                                  else vErrors.push(err);
                                  errors++;
                                  validate.errors = vErrors;
                                  return false;
                                } else {
                                  errors = errs__3;
                                  if (vErrors !== null) {
                                    if (errs__3) vErrors.length = errs__3;
                                    else vErrors = null;
                                  }
                                }
                                if (errors === errs_3) {
                                }
                                var valid3 = errors === errs_3;
                                if (valid3) {
                                }
                                if (errors === errs_2) {
                                }
                                var valid2 = errors === errs_2;
                              }
                              if (valid2) {
                                var data2 = data1.footer;
                                if (data2 === undefined) {
                                  valid2 = true;
                                } else {
                                  var errs_2 = errors;
                                  var errs_3 = errors;
                                  var errs__3 = errors,
                                    prevValid3 = false,
                                    valid3 = false,
                                    passingSchemas3 = null;
                                  var errs_4 = errors;
                                  if (typeof data2 !== 'string') {
                                    var err = {
                                      keyword: 'type',
                                      dataPath:
                                        (dataPath || '') + '.images.footer',
                                      schemaPath:
                                        '#/definitions/image/oneOf/0/type',
                                      params: {
                                        type: 'string',
                                      },
                                      message: 'should be string',
                                    };
                                    if (vErrors === null) vErrors = [err];
                                    else vErrors.push(err);
                                    errors++;
                                  }
                                  var valid4 = errors === errs_4;
                                  if (valid4) {
                                    valid3 = prevValid3 = true;
                                    passingSchemas3 = 0;
                                  }
                                  var errs_4 = errors;
                                  if (
                                    !data2 ||
                                    typeof data2 !== 'object' ||
                                    Array.isArray(data2)
                                  ) {
                                    var err = {
                                      keyword: 'type',
                                      dataPath:
                                        (dataPath || '') + '.images.footer',
                                      schemaPath:
                                        '#/definitions/image/oneOf/1/type',
                                      params: {
                                        type: 'object',
                                      },
                                      message: 'should be object',
                                    };
                                    if (vErrors === null) vErrors = [err];
                                    else vErrors.push(err);
                                    errors++;
                                  }
                                  var valid4 = errors === errs_4;
                                  if (valid4 && prevValid3) {
                                    valid3 = false;
                                    passingSchemas3 = [passingSchemas3, 1];
                                  } else {
                                    if (valid4) {
                                      valid3 = prevValid3 = true;
                                      passingSchemas3 = 1;
                                    }
                                  }
                                  if (!valid3) {
                                    var err = {
                                      keyword: 'oneOf',
                                      dataPath:
                                        (dataPath || '') + '.images.footer',
                                      schemaPath: '#/definitions/image/oneOf',
                                      params: {
                                        passingSchemas: passingSchemas3,
                                      },
                                      message:
                                        'should match exactly one schema in oneOf',
                                    };
                                    if (vErrors === null) vErrors = [err];
                                    else vErrors.push(err);
                                    errors++;
                                    validate.errors = vErrors;
                                    return false;
                                  } else {
                                    errors = errs__3;
                                    if (vErrors !== null) {
                                      if (errs__3) vErrors.length = errs__3;
                                      else vErrors = null;
                                    }
                                  }
                                  if (errors === errs_3) {
                                  }
                                  var valid3 = errors === errs_3;
                                  if (valid3) {
                                  }
                                  if (errors === errs_2) {
                                  }
                                  var valid2 = errors === errs_2;
                                }
                                if (valid2) {
                                }
                              }
                            }
                          }
                        }
                      }
                      if (errs__1 == errors) {
                      }
                    } else {
                      validate.errors = [
                        {
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.images',
                          schemaPath: '#/properties/images/type',
                          params: {
                            type: 'object',
                          },
                          message: 'should be object',
                        },
                      ];
                      return false;
                    }
                    if (errors === errs_1) {
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    var data1 = data.languages;
                    if (data1 === undefined) {
                      valid1 = true;
                    } else {
                      var errs_1 = errors;
                      if (Array.isArray(data1)) {
                        if (data1.length < 1) {
                          validate.errors = [
                            {
                              keyword: 'minItems',
                              dataPath: (dataPath || '') + '.languages',
                              schemaPath: '#/properties/languages/minItems',
                              params: {
                                limit: 1,
                              },
                              message: 'should NOT have fewer than 1 items',
                            },
                          ];
                          return false;
                        } else {
                          var errs__1 = errors;
                          var valid1;
                          for (var i1 = 0; i1 < data1.length; i1++) {
                            var errs_2 = errors;
                            if (typeof data1[i1] !== 'string') {
                              validate.errors = [
                                {
                                  keyword: 'type',
                                  dataPath:
                                    (dataPath || '') + '.languages[' + i1 + ']',
                                  schemaPath:
                                    '#/properties/languages/items/type',
                                  params: {
                                    type: 'string',
                                  },
                                  message: 'should be string',
                                },
                              ];
                              return false;
                            }
                            var valid2 = errors === errs_2;
                            if (!valid2) break;
                          }
                          if (errs__1 == errors) {
                            var i = data1.length,
                              valid1 = true,
                              j;
                            if (i > 1) {
                              var itemIndices = {},
                                item;
                              for (; i--; ) {
                                var item = data1[i];
                                if (typeof item !== 'string') continue;
                                if (typeof itemIndices[item] == 'number') {
                                  valid1 = false;
                                  j = itemIndices[item];
                                  break;
                                }
                                itemIndices[item] = i;
                              }
                            }
                            if (!valid1) {
                              validate.errors = [
                                {
                                  keyword: 'uniqueItems',
                                  dataPath: (dataPath || '') + '.languages',
                                  schemaPath:
                                    '#/properties/languages/uniqueItems',
                                  params: {
                                    i: i,
                                    j: j,
                                  },
                                  message:
                                    'should NOT have duplicate items (items ## ' +
                                    j +
                                    ' and ' +
                                    i +
                                    ' are identical)',
                                },
                              ];
                              return false;
                            } else {
                            }
                          }
                        }
                      } else {
                        validate.errors = [
                          {
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.languages',
                            schemaPath: '#/properties/languages/type',
                            params: {
                              type: 'array',
                            },
                            message: 'should be array',
                          },
                        ];
                        return false;
                      }
                      if (errors === errs_1) {
                      }
                      var valid1 = errors === errs_1;
                    }
                    if (valid1) {
                      if (data.distributable === undefined) {
                        valid1 = true;
                      } else {
                        var errs_1 = errors;
                        if (typeof data.distributable !== 'boolean') {
                          validate.errors = [
                            {
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.distributable',
                              schemaPath: '#/properties/distributable/type',
                              params: {
                                type: 'boolean',
                              },
                              message: 'should be boolean',
                            },
                          ];
                          return false;
                        }
                        var valid1 = errors === errs_1;
                      }
                      if (valid1) {
                        if (data.createdAt === undefined) {
                          valid1 = true;
                        } else {
                          var errs_1 = errors;
                          if (typeof data.createdAt !== 'string') {
                            validate.errors = [
                              {
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.createdAt',
                                schemaPath: '#/properties/createdAt/type',
                                params: {
                                  type: 'string',
                                },
                                message: 'should be string',
                              },
                            ];
                            return false;
                          }
                          var valid1 = errors === errs_1;
                        }
                        if (valid1) {
                          if (data.updatedAt === undefined) {
                            valid1 = true;
                          } else {
                            var errs_1 = errors;
                            if (typeof data.updatedAt !== 'string') {
                              validate.errors = [
                                {
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.updatedAt',
                                  schemaPath: '#/properties/updatedAt/type',
                                  params: {
                                    type: 'string',
                                  },
                                  message: 'should be string',
                                },
                              ];
                              return false;
                            }
                            var valid1 = errors === errs_1;
                          }
                          if (valid1) {
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (errs__0 == errors) {
      }
    } else {
      validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: {
            type: 'object',
          },
          message: 'should be object',
        },
      ];
      return false;
    }
    if (errors === 0) {
    }
    validate.errors = vErrors;
    return errors === 0;
  };
})();
validate.schema = {
  $id: 'http://yourpass.cz/schemas/template.json',
  type: 'object',
  definitions: {
    arrayType: {
      type: 'string',
      enum: ['array'],
    },
    objectType: {
      type: 'string',
      enum: ['object'],
    },
    numberProperty: {
      type: 'object',
      oneOf: [
        {
          properties: {
            type: {
              type: 'string',
              pattern: 'number',
            },
            value: {
              type: 'number',
            },
          },
          required: ['type', 'value'],
        },
        {
          properties: {
            type: {
              type: 'string',
              pattern: 'number',
            },
            value: {
              type: 'string',
              pattern: '^#\\{.+\\}$',
            },
            fallback: {
              type: 'number',
            },
          },
          required: ['type', 'value', 'fallback'],
        },
      ],
    },
    integerProperty: {
      type: 'object',
      oneOf: [
        {
          properties: {
            type: {
              type: 'string',
              pattern: 'number',
            },
            value: {
              type: 'integer',
            },
          },
          required: ['type', 'value'],
        },
        {
          properties: {
            type: {
              type: 'string',
              pattern: 'number',
            },
            value: {
              type: 'string',
              pattern: '^#\\{.+\\}$',
            },
            fallback: {
              type: 'integer',
            },
          },
          required: ['type', 'value', 'fallback'],
        },
      ],
    },
    colorProperty: {
      type: 'object',
      oneOf: [
        {
          properties: {
            type: {
              type: 'string',
              pattern: 'string',
            },
            value: {
              type: 'string',
              pattern:
                '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
            },
          },
          required: ['type', 'value'],
        },
        {
          properties: {
            type: {
              type: 'string',
              pattern: 'string',
            },
            value: {
              type: 'string',
              pattern: '^#\\{.+\\}$',
            },
            fallback: {
              type: 'string',
              pattern:
                '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$',
            },
          },
          required: ['type', 'value', 'fallback'],
        },
      ],
    },
    dateProperty: {
      type: 'object',
      oneOf: [
        {
          properties: {
            type: {
              type: 'string',
              pattern: 'string',
            },
            value: {
              type: 'string',
              pattern:
                '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
            },
          },
          required: ['type', 'value'],
        },
        {
          properties: {
            type: {
              type: 'string',
              pattern: 'string',
            },
            value: {
              type: 'string',
              pattern: '^#\\{.+\\}$',
            },
            fallback: {
              type: 'string',
              pattern:
                '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$',
            },
          },
          required: ['type', 'value', 'fallback'],
        },
      ],
    },
    booleanProperty: {
      type: 'object',
      oneOf: [
        {
          properties: {
            type: {
              type: 'string',
              pattern: 'boolean',
            },
            value: {
              type: 'boolean',
            },
          },
          required: ['type', 'value'],
        },
        {
          properties: {
            type: {
              type: 'string',
              pattern: 'boolean',
            },
            value: {
              type: 'string',
              pattern: '^#\\{.+\\}$',
            },
            fallback: {
              type: 'boolean',
            },
          },
          required: ['type', 'value', 'fallback'],
        },
      ],
    },
    stringWithPlaceholder: {
      properties: {
        type: {
          type: 'string',
          pattern: 'string',
        },
        value: {
          type: 'string',
          pattern:
            '#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^}]{4,}\\}',
        },
        fallback: {
          type: 'string',
        },
      },
      required: ['type', 'value', 'fallback'],
    },
    stringWithoutPlaceholder: {
      properties: {
        type: {
          type: 'string',
          pattern: 'string',
        },
        value: {
          type: 'string',
          pattern:
            '^((?!(#\\{[^pt][^\\}]*\\}|#\\{[^\\}][^i][^\\}]*\\}|#\\{[^\\}]{2}[^d]\\}|#\\{[^\\}]{4,}\\}))[\\s\\S])*$',
        },
      },
      required: ['type', 'value'],
    },
    stringProperty: {
      type: 'object',
      oneOf: [
        {
          $ref: '#/definitions/stringWithPlaceholder',
        },
        {
          $ref: '#/definitions/stringWithoutPlaceholder',
        },
      ],
    },
    localizedStringProperty: {
      type: 'object',
      oneOf: [
        {
          $ref: '#/definitions/stringWithPlaceholder',
        },
        {
          $ref: '#/definitions/stringWithoutPlaceholder',
        },
        {
          properties: {
            type: {
              type: 'string',
              pattern: 'string',
            },
            value: {
              type: 'object',
              patternProperties: {
                '[a-z]{2}': {
                  type: 'string',
                },
              },
              additionalProperties: false,
            },
          },
          required: ['type', 'value'],
        },
      ],
    },
    textAlignmentProperty: {
      type: 'object',
      properties: {
        type: {
          type: 'string',
          pattern: 'string',
        },
        value: {
          type: 'string',
          enum: [
            'PKTextAlignmentLeft',
            'PKTextAlignmentCenter',
            'PKTextAlignmentRight',
            'PKTextAlignmentNatural',
          ],
        },
      },
      required: ['type', 'value'],
    },
    dateStyleProperty: {
      type: 'object',
      properties: {
        type: {
          type: 'string',
          pattern: 'string',
        },
        value: {
          type: 'string',
          enum: [
            'PKDateStyleNone',
            'PKDateStyleShort',
            'PKDateStyleMedium',
            'PKDateStyleLong',
            'PKDateStyleFull',
          ],
        },
      },
      required: ['type', 'value'],
    },
    numberStyleProperty: {
      type: 'object',
      properties: {
        type: {
          type: 'string',
          pattern: 'string',
        },
        value: {
          type: 'string',
          enum: [
            'PKNumberStyleDecimal',
            'PKNumberStylePercent',
            'PKNumberStyleScientific',
            'PKNumberStyleSpellOut',
          ],
        },
      },
      required: ['type', 'value'],
    },
    beacon: {
      type: 'object',
      properties: {
        properties: {
          type: 'object',
          properties: {
            proximityUUID: {
              $ref: '#/definitions/stringProperty',
            },
            major: {
              $ref: '#/definitions/integerProperty',
            },
            minor: {
              $ref: '#/definitions/integerProperty',
            },
            relevantText: {
              $ref: '#/definitions/stringProperty',
            },
          },
          required: ['proximityUUID'],
        },
      },
    },
    barcode: {
      type: 'object',
      properties: {
        properties: {
          type: 'object',
          properties: {
            altText: {
              $ref: '#/definitions/stringProperty',
            },
            format: {
              $ref: '#/definitions/stringProperty',
            },
            message: {
              $ref: '#/definitions/stringProperty',
            },
            messageEncoding: {
              $ref: '#/definitions/stringProperty',
            },
          },
          required: ['messageEncoding', 'format', 'message'],
        },
      },
    },
    location: {
      type: 'object',
      properties: {
        properties: {
          type: 'object',
          properties: {
            latitude: {
              $ref: '#/definitions/numberProperty',
            },
            longitude: {
              $ref: '#/definitions/numberProperty',
            },
            altitude: {
              $ref: '#/definitions/numberProperty',
            },
            relevantText: {
              $ref: '#/definitions/stringProperty',
            },
          },
          required: ['latitude', 'longitude'],
        },
      },
    },
    dataDetectorTypes: {
      type: 'object',
      properties: {
        type: {
          $ref: '#/definitions/arrayType',
        },
        items: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              type: {
                type: 'string',
                pattern: 'string',
              },
              value: {
                type: 'string',
                enum: [
                  'PKDataDetectorTypePhoneNumber',
                  'PKDataDetectorTypeLink',
                  'PKDataDetectorTypeAddress',
                  'PKDataDetectorTypeCalendarEvent',
                ],
              },
            },
            required: ['type', 'value'],
          },
        },
      },
      required: ['type', 'items'],
    },
    dateFieldType: {
      type: 'object',
      properties: {
        changeMessage: {
          $ref: '#/definitions/localizedStringProperty',
        },
        key: {
          $ref: '#/definitions/stringProperty',
        },
        label: {
          $ref: '#/definitions/localizedStringProperty',
        },
        textAlignment: {
          $ref: '#/definitions/textAlignmentProperty',
        },
        value: {
          $ref: '#/definitions/dateProperty',
        },
        dateStyle: {
          $ref: '#/definitions/dateStyleProperty',
        },
        ignoresTimeZone: {
          $ref: '#/definitions/booleanProperty',
        },
        isRelative: {
          $ref: '#/definitions/booleanProperty',
        },
        timeStyle: {
          $ref: '#/definitions/dateStyleProperty',
        },
        dataDetectorTypes: {
          $ref: '#/definitions/dataDetectorTypes',
        },
      },
      additionalProperties: false,
      required: ['key', 'value', 'dateStyle', 'timeStyle'],
    },
    numberFieldType: {
      type: 'object',
      properties: {
        changeMessage: {
          $ref: '#/definitions/localizedStringProperty',
        },
        key: {
          $ref: '#/definitions/stringProperty',
        },
        label: {
          $ref: '#/definitions/localizedStringProperty',
        },
        textAlignment: {
          $ref: '#/definitions/textAlignmentProperty',
        },
        value: {
          $ref: '#/definitions/numberProperty',
        },
        numberStyle: {
          $ref: '#/definitions/numberStyleProperty',
        },
        dataDetectorTypes: {
          $ref: '#/definitions/dataDetectorTypes',
        },
      },
      additionalProperties: false,
      required: ['key', 'value', 'numberStyle'],
    },
    currencyFieldType: {
      type: 'object',
      properties: {
        changeMessage: {
          $ref: '#/definitions/localizedStringProperty',
        },
        key: {
          $ref: '#/definitions/stringProperty',
        },
        label: {
          $ref: '#/definitions/localizedStringProperty',
        },
        textAlignment: {
          $ref: '#/definitions/textAlignmentProperty',
        },
        value: {
          $ref: '#/definitions/numberProperty',
        },
        dataDetectorTypes: {
          $ref: '#/definitions/dataDetectorTypes',
        },
        currencyCode: {
          $ref: '#/definitions/stringProperty',
        },
      },
      required: ['key', 'value', 'currencyCode'],
    },
    stringFieldType: {
      type: 'object',
      properties: {
        changeMessage: {
          $ref: '#/definitions/localizedStringProperty',
        },
        attributedValue: {
          anyOf: [
            {
              $ref: '#/definitions/localizedStringProperty',
            },
            {
              $ref: '#/definitions/stringProperty',
            },
          ],
        },
        key: {
          $ref: '#/definitions/stringProperty',
        },
        label: {
          $ref: '#/definitions/localizedStringProperty',
        },
        textAlignment: {
          $ref: '#/definitions/textAlignmentProperty',
        },
        value: {
          anyOf: [
            {
              $ref: '#/definitions/localizedStringProperty',
            },
            {
              $ref: '#/definitions/stringProperty',
            },
          ],
        },
        dataDetectorTypes: {
          $ref: '#/definitions/dataDetectorTypes',
        },
      },
      additionalProperties: false,
      required: ['key', 'value'],
    },
    field: {
      type: 'object',
      properties: {
        type: {
          $ref: '#/definitions/objectType',
        },
        properties: {
          oneOf: [
            {
              $ref: '#/definitions/dateFieldType',
            },
            {
              $ref: '#/definitions/numberFieldType',
            },
            {
              $ref: '#/definitions/currencyFieldType',
            },
            {
              $ref: '#/definitions/stringFieldType',
            },
          ],
        },
      },
    },
    fields: {
      type: 'object',
      properties: {
        type: {
          $ref: '#/definitions/arrayType',
        },
        items: {
          type: 'array',
          items: {
            $ref: '#/definitions/field',
          },
        },
      },
      required: ['type', 'items'],
    },
    image: {
      oneOf: [
        {
          type: 'string',
        },
        {
          type: 'object',
        },
      ],
    },
    passStyleBoardingPass: {
      type: 'object',
      properties: {
        type: {
          $ref: '#/definitions/objectType',
        },
        properties: {
          type: 'object',
          properties: {
            auxiliaryFields: {
              $ref: '#/definitions/fields',
            },
            backFields: {
              $ref: '#/definitions/fields',
            },
            headerFields: {
              $ref: '#/definitions/fields',
            },
            primaryFields: {
              $ref: '#/definitions/fields',
            },
            secondaryFields: {
              $ref: '#/definitions/fields',
            },
            transitType: {
              $ref: '#/definitions/stringProperty',
            },
          },
          required: ['transitType'],
        },
      },
    },
    passStyle: {
      type: 'object',
      properties: {
        type: {
          $ref: '#/definitions/objectType',
        },
        properties: {
          type: 'object',
          properties: {
            auxiliaryFields: {
              $ref: '#/definitions/fields',
            },
            backFields: {
              $ref: '#/definitions/fields',
            },
            headerFields: {
              $ref: '#/definitions/fields',
            },
            primaryFields: {
              $ref: '#/definitions/fields',
            },
            secondaryFields: {
              $ref: '#/definitions/fields',
            },
          },
        },
      },
      required: ['type', 'properties'],
    },
  },
  properties: {
    id: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    jsonTemplate: {
      type: 'object',
      properties: {
        beacons: {
          type: 'object',
          properties: {
            type: {
              $ref: '#/definitions/arrayType',
            },
            items: {
              type: 'array',
              items: {
                $ref: '#/definitions/beacon',
              },
            },
          },
        },
        barcodes: {
          type: 'object',
          properties: {
            type: {
              $ref: '#/definitions/arrayType',
            },
            items: {
              type: 'array',
              items: {
                $ref: '#/definitions/barcode',
              },
            },
          },
        },
        locations: {
          type: 'object',
          properties: {
            type: {
              $ref: '#/definitions/arrayType',
            },
            items: {
              type: 'array',
              items: {
                $ref: '#/definitions/location',
              },
            },
          },
        },
        logoText: {
          $ref: '#/definitions/localizedStringProperty',
        },
        labelColor: {
          $ref: '#/definitions/colorProperty',
        },
        description: {
          $ref: '#/definitions/localizedStringProperty',
        },
        maxDistance: {
          $ref: '#/definitions/numberProperty',
        },
        relevantDate: {
          $ref: '#/definitions/dateProperty',
        },
        expirationDate: {
          $ref: '#/definitions/dateProperty',
        },
        backgroundColor: {
          $ref: '#/definitions/colorProperty',
        },
        foregroundColor: {
          $ref: '#/definitions/colorProperty',
        },
        sharingProhibited: {
          $ref: '#/definitions/booleanProperty',
        },
        organizationName: {
          $ref: '#/definitions/localizedStringProperty',
        },
        groupingIdentifier: {
          $ref: '#/definitions/stringProperty',
        },
        associatedStoreIdentifiers: {
          type: 'object',
          properties: {
            type: {
              type: 'string',
            },
            items: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  type: {
                    type: 'string',
                  },
                  value: {
                    type: 'integer',
                  },
                },
              },
            },
          },
        },
        boardingPass: {
          $ref: '#/definitions/passStyleBoardingPass',
        },
        coupon: {
          $ref: '#/definitions/passStyle',
        },
        eventTicket: {
          $ref: '#/definitions/passStyle',
        },
        generic: {
          $ref: '#/definitions/passStyle',
        },
        storeCard: {
          $ref: '#/definitions/passStyle',
        },
      },
      required: ['description', 'organizationName'],
    },
    dynamicDataJsonSchema: {
      type: 'object',
      properties: {},
    },
    projectId: {
      type: 'string',
    },
    certificateId: {
      type: 'string',
    },
    images: {
      type: 'object',
      properties: {
        background: {
          $ref: '#/definitions/image',
        },
        logo: {
          $ref: '#/definitions/image',
        },
        icon: {
          $ref: '#/definitions/image',
        },
        strip: {
          $ref: '#/definitions/image',
        },
        thumbnail: {
          $ref: '#/definitions/image',
        },
        footer: {
          $ref: '#/definitions/image',
        },
      },
    },
    languages: {
      type: 'array',
      items: {
        type: 'string',
      },
      minItems: 1,
      uniqueItems: true,
    },
    distributable: {
      type: 'boolean',
    },
    createdAt: {
      type: 'string',
    },
    updatedAt: {
      type: 'string',
    },
  },
};
validate.errors = null;
module.exports = validate;
